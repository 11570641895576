import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Userpilot } from 'userpilot';
import { CONFIG_TOKEN } from '../config/config.token';
import { GlobalConfig } from '../config/global-config.interface';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserpilotService {
  constructor(
    @Inject(CONFIG_TOKEN) private readonly config: GlobalConfig,
    private readonly authService: AuthService,
    private readonly router: Router
  ) {
    if (!this.config.userpilotToken) {
      return;
    }

    Userpilot.initialize(this.config.userpilotToken);

    this.authService.getUser$().subscribe((user) => {
      Userpilot.identify(user.id, {
        name: user.name,
        email: user.email,
        company: {
          id: user.accountName,
          name: user.accountName,
        },
      });
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => (Userpilot as any).reload());
  }
}
