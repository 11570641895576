import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouteSegment } from '../../../../enums/route-segment.enum';
import { NavBrand } from '../../nav-brand.interface';

@Component({
  selector: 'app-bar',
  templateUrl: './app-bar.component.html',
  styleUrls: ['./app-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBarComponent {
  @Input() public logo: NavBrand;
  @Input() public showNewQuoteButton = true;
  @Input() public newQuoteLink: RouteSegment[];
  @Input() public onOpenSideNavClick: () => void;
  @Input() public thirdPartyFlag: boolean;
}
