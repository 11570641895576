import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@ngneat/reactive-forms';
import isNil from 'lodash/isNil';
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, finalize, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { toggleAnimation } from '../../../../animations/toggle-open-close.animation';
import { poundsInKilogram } from '../../../../constants/global.constants';
import { LengthUnit } from '../../../../enums/length-unit.enum';
import { PackageGroupPackageContentType } from '../../../../enums/package-group-package-content-type.enum';
import { WeightUnit } from '../../../../enums/weight-unit.enum';
import { ChargeableWeightDialogResult } from '../../../../interfaces/chargeable-weight-dialog.vm';
import { ChargeableWeightDialogPackageVM } from '../../../../interfaces/package.vm';
import { ValidatorHelperService } from '../../../../services/validator-helper.service';
import { greaterThanValidator } from '../../../../validators/greater-than.validator';
import { integerValidator } from '../../../../validators/integer.validator';
import { numberValidator } from '../../../../validators/number.validator';
import { CommonChargeableWeightService } from '../../../chargeable-weight-dialog/services/common-chargeable-weight.service';
import { LoadingIndicatorService } from '../../../loading-indicator/services/loading-indicator.service';
import { PackageClosedContentPackageVM } from '../../../package-closed-content-card/package.vm';
import { PACKAGE_GROUP_PACKAGE_CONTENT_TYPE } from '../../tokens/package-group-package-content-type.token';

const CLOSED_PACKAGE_HEIGHT = 102;

@Component({
  selector: 'app-package-groups',
  templateUrl: './package-groups.component.html',
  styleUrls: ['./package-groups.component.scss'],
  animations: [
    toggleAnimation(CLOSED_PACKAGE_HEIGHT),
    trigger('toggleContentOpacity', [transition(':enter', [style({ opacity: 0 }), animate('100ms', style({ opacity: 1 }))])]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CommonChargeableWeightService],
})
export class PackageGroupsComponent implements OnChanges, OnInit, OnDestroy {
  public PackageContentType = PackageGroupPackageContentType;
  private readonly destroy$ = new Subject<void>();
  private readonly MAX_LENGTH_VALUE = 999;
  private readonly MAX_PACKAGE_COUNT = 999;
  private readonly ROUND_NUMBERS_VALUE = 100;
  private readonly MIN_WEIGHT_KG = 1;
  private readonly MIN_WEIGHT_LBS = 2.2;
  public quoteDefaultDetail: any;

  @Input() public packages: ChargeableWeightDialogPackageVM[] = [];
  @Input() public openPackageId = 0;

  @Output() public validityChange = new EventEmitter<boolean>();
  @Output() public valueChange = new EventEmitter<ChargeableWeightDialogResult>();

  public readonly WeightUnit = WeightUnit;
  public readonly LengthUnit = LengthUnit;
  public readonly packages$: Observable<
    {
      formGroup: FormGroup;
      weightErrors$: Observable<string>;
      lengthErrors$: Observable<string>;
      breadthErrors$: Observable<string>;
      heightErrors$: Observable<string>;
      packageCountErrors$: Observable<string>;
      totalWeightErrors$: Observable<string>;
    }[]
  >;

  public control = this.formBuilder.group<ChargeableWeightDialogResult>({
    packages: this.formBuilder.array<ChargeableWeightDialogPackageVM>([]),
  });

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly validatorHelperService: ValidatorHelperService,
    private readonly cdr: ChangeDetectorRef,
    @Inject(PACKAGE_GROUP_PACKAGE_CONTENT_TYPE) public packageContentType: PackageGroupPackageContentType,
    private readonly commonChargeableWeightService: CommonChargeableWeightService,
    private readonly loadingIndicator: LoadingIndicatorService
  ) {
    // This is here to cache error streams.
    this.packages$ = this.control.value$.pipe(
      distinctUntilChanged((previous, current) => previous.packages.length === current.packages.length),
      map(({ packages }) =>
        packages.map((_, index) => ({
          formGroup: this.getPackageControl(index),
          weightErrors$: this.validatorHelperService.getError$(this.getWeightControl(index)),
          lengthErrors$: this.validatorHelperService.getError$(this.getLengthControl(index)),
          breadthErrors$: this.validatorHelperService.getError$(this.getBreadthControl(index)),
          heightErrors$: this.validatorHelperService.getError$(this.getHeightControl(index)),
          packageCountErrors$: this.validatorHelperService.getError$(this.getPackageCountControl(index)),
          totalWeightErrors$: this.validatorHelperService.getError$(this.getTotalWeightControl(index)),
        }))
      )
    );

    this.control.status$.pipe(takeUntil(this.destroy$)).subscribe(() => this.validityChange.next(this.control.valid));
    this.control.value$.pipe(takeUntil(this.destroy$)).subscribe((value) => this.valueChange.next(value));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.packages) {
      while (this.packagesControl.controls.length > 0) {
        this.packagesControl.removeAt(0);
      }

      if (!isNil(this.packages) && this.packages.length > 0) {
        this.getQuoteDefaultData();

        this.packages.forEach(() => this.addPackage());
        this.packagesControl.setValue(
          this.packages.map((item) => ({
            ...item,
            hasDangerousGoods: isNil(item.hasDangerousGoods) ? false : item.hasDangerousGoods,
          }))
        );
      }
    }
  }

  public ngOnInit(): void {
    if (this.packagesControl.controls.length === 0) {
      this.getQuoteDefaultData();
    }
  }

  public get packagesControl(): FormArray<ChargeableWeightDialogPackageVM> {
    return this.control.get('packages') as FormArray<ChargeableWeightDialogPackageVM>;
  }

  public getPackageControl(index: number): FormGroup<ChargeableWeightDialogPackageVM> {
    return this.packagesControl.controls[index] as FormGroup<ChargeableWeightDialogPackageVM>;
  }

  public getClosedPackageData(index: number): PackageClosedContentPackageVM {
    return {
      // eslint-disable-next-line id-blacklist
      number: index,
      ...this.getPackageControl(index).value,
    };
  }

  public getWeightControl(index: number): FormControl {
    return this.getPackageControl(index).get('weight') as FormControl;
  }

  public getLengthControl(index: number): FormControl {
    return this.getPackageControl(index).get('length') as FormControl;
  }

  public getWeightUnitControl(index: number): FormControl {
    return this.getPackageControl(index).get('weightUnit') as FormControl;
  }

  public getLengthUnitControl(index: number): FormControl {
    return this.getPackageControl(index).get('lengthUnit') as FormControl;
  }

  public getBreadthControl(index: number): FormControl {
    return this.getPackageControl(index).get('breadth') as FormControl;
  }

  public getTotalWeightControl(index: number): FormControl {
    return this.getPackageControl(index).get('totalWeight') as FormControl;
  }

  public getHeightControl(index: number): FormControl {
    return this.getPackageControl(index).get('height') as FormControl;
  }

  public getPackageCountControl(index: number): FormControl {
    return this.getPackageControl(index).get('packageCount') as FormControl;
  }

  private roundNumber(value: number): number {
    return Math.round(value * this.ROUND_NUMBERS_VALUE) / this.ROUND_NUMBERS_VALUE;
  }

  private calculateTotalWeight(changes: number[], control: FormGroup): void {
    const [weight, length, breadth, height, packageCount, weightUnit, lengthUnit] = changes;

    // Actual weight = number of packages x weight of a package
    const actualWeight = weightUnit === WeightUnit.Kg ? packageCount * weight : (packageCount * weight) / poundsInKilogram;

    const VOLUMETRIC_WEIGHT_RATIO = 5000;
    // Volumetric weight = length x breadth x height x number of packages / 5000
    // default calculated in Kg-s -> need to exchange it at the end if the weight unit is Lbs

    // eslint-disable-next-line prefer-const
    let volumetricWeight =
      lengthUnit === LengthUnit.Cm
        ? ((length * breadth * height) / VOLUMETRIC_WEIGHT_RATIO) * packageCount
        : ((length * breadth * height) / 305.12) * packageCount;

    control.controls.totalWeight.setValue(this.roundNumber(Math.max(actualWeight, volumetricWeight)));
  }

  public addPackage(): void {
    const packageControl = this.formBuilder.group<ChargeableWeightDialogPackageVM>({
      // eslint-disable-next-line unicorn/no-useless-undefined
      id: this.formBuilder.control(undefined),
      weight: this.formBuilder.control(undefined, [
        Validators.required,
        greaterThanValidator(this.MIN_WEIGHT_KG),
        numberValidator({ maxFractionalDigits: 2 }),
      ]),
      length: this.formBuilder.control(undefined, [
        Validators.required,
        greaterThanValidator(0),
        Validators.max(this.MAX_LENGTH_VALUE),
        numberValidator({ maxFractionalDigits: 2 }),
      ]),
      weightUnit: this.formBuilder.control(this.quoteDefaultDetail?.weightUnit, [Validators.required]),
      lengthUnit: this.formBuilder.control(this.quoteDefaultDetail?.dimensionUnit, [Validators.required]),
      breadth: this.formBuilder.control(undefined, [
        Validators.required,
        greaterThanValidator(0),
        Validators.max(this.MAX_LENGTH_VALUE),
        numberValidator({ maxFractionalDigits: 2 }),
      ]),
      height: this.formBuilder.control(undefined, [
        Validators.required,
        greaterThanValidator(0),
        Validators.max(this.MAX_LENGTH_VALUE),
        numberValidator({ maxFractionalDigits: 2 }),
      ]),
      packageCount: this.formBuilder.control(undefined, [
        Validators.required,
        greaterThanValidator(0),
        Validators.max(this.MAX_PACKAGE_COUNT),
        integerValidator,
      ]),
      hasBatteries: this.formBuilder.control(false),
      hasDangerousGoods: this.formBuilder.control(false),
      totalWeight: this.formBuilder.control(0, [Validators.required]),
    });
    this.packagesControl.push(packageControl);

    combineLatest([
      packageControl.controls.weight.valueChanges.pipe(startWith(0)),
      packageControl.controls.length.valueChanges.pipe(startWith(0)),
      packageControl.controls.breadth.valueChanges.pipe(startWith(0)),
      packageControl.controls.height.valueChanges.pipe(startWith(0)),
      packageControl.controls.packageCount.valueChanges.pipe(startWith(0)),
      packageControl.controls.weightUnit.valueChanges,
      packageControl.controls.lengthUnit.valueChanges,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((changes) => {
        this.calculateTotalWeight(changes, packageControl);
      });

    packageControl.controls.weightUnit.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((unit) => {
      packageControl.controls.weight.clearValidators();
      packageControl.controls.weight.addValidators([
        Validators.required,
        greaterThanValidator(unit === WeightUnit.Lbs ? this.MIN_WEIGHT_LBS : this.MIN_WEIGHT_KG, true),
        numberValidator({ maxFractionalDigits: 2 }),
      ]);
      packageControl.controls.weight.updateValueAndValidity();
    });

    this.openPackageId = this.packagesControl.controls.length - 1;
  }

  public isPackageOpen(index: number): boolean {
    return index === this.openPackageId;
  }

  public onClosedCardClick(index: number): void {
    this.openPackageId = index;
  }

  public onDeleteClick(index: number): void {
    this.packagesControl.removeAt(index);

    if (this.isPackageOpen(index)) {
      this.openPackageId = this.packagesControl.controls.length - 1;
    } else if (index < this.openPackageId) {
      this.openPackageId -= 1;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async getQuoteDefaultData() {
    of({})
      .pipe(
        tap(() => this.loadingIndicator.open()),
        switchMap(() => forkJoin([this.commonChargeableWeightService.getQuoteDefaults$()])),
        finalize(() => this.loadingIndicator.dispose())
      )
      .subscribe(([quoteDefaults]) => {
        this.quoteDefaultDetail = quoteDefaults;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.quoteDefaultDetail && this.packagesControl.controls.length === 0 ? this.addPackage() : null;
        this.cdr.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
