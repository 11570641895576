import { Injectable } from '@angular/core';
import { minTaxCalculationModalTime } from '@global/constants/global.constants';
import { LineItem } from '@global/modules/common-quote/interfaces/line-item.interface';
import { LineItemsData } from '@global/modules/common-quote/interfaces/line-items-data.interface';
import { BaseLineItemsDataService } from '@global/modules/line-items-config/base-classes/base-line-items-data.service';
import { mapAddPartsPayload } from '@modules/quote/helpers/map-add-parts-payload.helper';
import { mapUpdatePartsPayload } from '@modules/quote/helpers/map-update-parts-payload.helper';
import { mapLineItemDetails } from '@shared/helpers/map-line-item-details.helper';
import { RePollService } from '@shared/services/re-poll.service';
import { AddPartsRequestParts, QuoteDataService } from '@tecex-api/data';
import { AddPartsResponse } from '@tecex-api/data/model/addPartsResponse';
import { forkJoin, Observable, timer } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LineItemsDataService extends BaseLineItemsDataService {
  constructor(protected readonly quoteDataService: QuoteDataService, private readonly rePollService: RePollService) {
    super(quoteDataService);
  }

  public saveNewLineItems$({ shipmentOrderIds, lineItems, user }: LineItemsData): Observable<AddPartsResponse> {
    const parts = shipmentOrderIds.reduce<AddPartsRequestParts[]>(
      (result, shipmentOrderId) => [...result, ...mapAddPartsPayload(shipmentOrderId, lineItems)],
      []
    );

    //return this.quoteDataService.addParts({ AccountID: user.accountId, Accesstoken: user.accessToken, Parts: parts });
    return this.rePollService
      .addPartsRetry$({
        accountId: user.accountId,
        accesstoken: user.accessToken,
        parts,
      })
      .pipe(
        map((response) => {
          if (
            response?.Response &&
            (response?.Response.ReMessage === 'Critical_Shipment_Error' || response?.Response.ReMessage === 'Locked_Shipment_Failure')
          ) {
            throw new Error('Failed to add Line Items');
          }
          return response;
        })
      );
  }

  public editLineItems$({ shipmentOrderIds, lineItems, user }: LineItemsData): Observable<LineItem[]> {
    const parts = mapUpdatePartsPayload(lineItems);
    return forkJoin([
      ...shipmentOrderIds.map((shipmentOrderId) =>
        this.rePollService.updatePartsRetry$({
          accountId: user.accountId,
          accesstoken: user.accessToken,
          shipmentOrderId,
          parts,
        })
      ),
      timer(minTaxCalculationModalTime),
    ]).pipe(mapTo(lineItems));
  }

  public getLineItems$(SOID: string, accessToken: string): Observable<LineItem[]> {
    return this._getLineItems$(SOID, accessToken).pipe(map(({ Part }) => Part.map((item) => mapLineItemDetails(item))));
  }
}
