import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@ngneat/reactive-forms';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import { FileExtensionService } from '../../services/file-extension.service';
import { FileHelper } from './file.helper';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileInputComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileInputComponent implements ControlValueAccessor, OnChanges {
  @Input() public multiple = false;
  @Input() public accept: string | string[];
  @Input() public disabled = false;
  @Input() public descriptionEnabled = true;
  @ViewChild('fileForm', { static: true }) private readonly fileForm: ElementRef<HTMLFormElement>;
  @ViewChild('fileInput', { static: true }) private readonly fileInput: ElementRef<HTMLInputElement>;

  /**
   * Represents file or files or data URL or data URLs
   */
  public value?: File | File[];
  public isDraggingOver = false;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChangeFn = (_?: File | File[]): void => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouchedFn = (): void => {};

  constructor(private readonly cdr: ChangeDetectorRef, private readonly fileExtensionService: FileExtensionService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.multiple) {
      this.value = this.stabilizeValue(this.value);
      this.onChange();
    }
  }

  private stabilizeValue(files: File | File[] | string | string[]): File | File[] | undefined {
    if (files instanceof File) {
      return this.stabilizeFile(files);
    } else if (isEmpty(files)) {
      return undefined;
    } else if (isArray(files)) {
      return this.stabilizeFileArray(files);
    } else if (isString(files)) {
      return this.stabilizeFilesString(files);
    }
    return undefined;
  }

  private stabilizeFile(file: File): File | File[] {
    return this.multiple ? [file] : file;
  }

  private stabilizeFileArray(files: (string | File)[]): File | File[] {
    return this.multiple ? (files as (string | File)[]).map((file: string | File) => this.adaptFile(file)) : this.adaptFile(files[0]);
  }

  private stabilizeFilesString(file: string): File | File[] {
    return this.multiple ? [this.adaptFile(file)] : this.adaptFile(file);
  }

  private adaptFile(file: string | File): File {
    return isString(file) ? FileHelper.createFileFromDataURL(file) : file;
  }

  public triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  public hasValue(): boolean {
    return !isEmpty(this.value) || this.value instanceof File;
  }

  public getFiles(): File[] {
    return (this.multiple ? this.value : [this.value]) as File[];
  }

  public getFileName(file: File): string {
    return isEmpty(file.name) ? 'Unnamed file' : file.name;
  }

  public handleFileInputChange(event: Event): void {
    const fileInput: HTMLInputElement = event.target as HTMLInputElement;
    this.handleFiles(fileInput.files);
    this.fileForm.nativeElement.reset();
  }

  public handleFiles(files: FileList | null): void {
    if (isEmpty(files)) {
      this.value = undefined;
      return;
    }
    const filteredFiles = this.fileExtensionService.handleDisallowedExtensions([...files]);
    this.value = this.stabilizeValue(filteredFiles);

    this.onChange();
    this.onTouched();
  }

  public writeValue(files?: File | File[] | string | string[]): void {
    this.value = this.stabilizeValue(files);
    this.cdr.markForCheck();
  }

  public registerOnChange(fn: (_?: File | File[]) => void): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouchedFn = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onTouched(): void {
    this.onTouchedFn();
  }

  public onChange(): void {
    this.onChangeFn(this.value);
  }

  public dragEnter(): void {
    this.isDraggingOver = true;
  }

  public dragLeave(): void {
    this.isDraggingOver = false;
  }

  public dragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  public drop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDraggingOver = false;
    if (isNil(event.dataTransfer)) {
      return;
    }
    this.handleFiles(event.dataTransfer.files);
  }
}
