import { Injectable, Injector } from '@angular/core';
import { IntentionWithGoods } from '@tecex-api/data';
import last from 'lodash/last';
import { BehaviorSubject, EMPTY, of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { assertNever } from '../../../../helpers/assert-never.helper';
import { ErrorNotificationService } from '../../../../services/error-notification.service';
import { UpdateQuoteService } from '../../../common-quote/services/update-quote.service';
import { DialogRef } from '../../../dialog/dialog-ref';
import { DialogService } from '../../../dialog/dialog.service';
import { DialogShowMode } from '../../../dialog/enums/dialog-show-mode.enum';
import { LoadingIndicatorService } from '../../../loading-indicator/services/loading-indicator.service';
import { BeneficialOwnerDialogPayload } from './beneficial-owner-dialog-payload.interface';
import { BeneficialOwnerDialogComponent } from './beneficial-owner-dialog.component';
import { BeneficialOwnerStep } from './beneficial-owner-step.enum';
import { BuyerService } from './buyer.service';
import { mapBeneficialOwnerResult } from './map-beneficial-owner-result.helper';

@Injectable()
export class BeneficialOwnerDialogService {
  constructor(
    private readonly injector: Injector,
    private readonly dialogService: DialogService,
    private readonly updateQuoteService: UpdateQuoteService,
    private readonly loadingIndicatorService: LoadingIndicatorService,
    private readonly errorNotificationService: ErrorNotificationService,
    private readonly buyerService: BuyerService
  ) {}

  public newBuyerId = '';

  public open(
    payload: Pick<
      BeneficialOwnerDialogPayload,
      | 'title'
      | 'description'
      | 'defaultTeamMember'
      | 'shipmentOrder'
      | 'showShipmentOrderBox'
      | 'isInactive'
      | 'countries'
      | 'shipmentOrderCompanyName'
      | 'onMsgClickHandler'
    >
  ): DialogRef<boolean> {
    const steps$ = new BehaviorSubject<BeneficialOwnerStep[]>([BeneficialOwnerStep.IntetionWithGoods]);

    const dialogRef: DialogRef<boolean> = this.dialogService.open<BeneficialOwnerDialogPayload, boolean>(
      BeneficialOwnerDialogComponent,
      {
        ...payload,
        step$: steps$.pipe(map((steps) => last(steps))),
        hasPreviousStep$: steps$.pipe(map((steps) => steps.length > 1)),
        hasCancelStep$: steps$.pipe(map((steps) => last(steps$.value) === 0)),
        hasSelectBuyer$: steps$.pipe(map((steps) => last(steps$.value) === 5 || last(steps$.value) === 1)),
        handleSelectBuyer: () => steps$.next([...steps$.value, BeneficialOwnerStep.CompanySearch]),
        handleBack: () => steps$.next(steps$.value.slice(0, -1)),
        // eslint-disable-next-line sonarjs/cognitive-complexity
        handleNext$: (result) => {
          const currentStep = last(steps$.value);
          switch (currentStep) {
            case BeneficialOwnerStep.IntetionWithGoods:
              // eslint-disable-next-line sonarjs/no-nested-switch
              switch (result.intentionWithGoods) {
                case IntentionWithGoods.TO_SELL_GOODS:
                  if (payload.shipmentOrder.buyerAccount) {
                    if (result.companyDetails.country) {
                      steps$.next([...steps$.value, BeneficialOwnerStep.CompanyConfirm]);
                      break;
                    } else {
                      steps$.next([...steps$.value, BeneficialOwnerStep.CompanyDetails]);
                      break;
                    }
                  } else {
                    steps$.next([...steps$.value, BeneficialOwnerStep.CompanySearch]);
                    break;
                  }
                case IntentionWithGoods.RETAIN_OWNERSHIP_OF_GOODS:
                  return this.loadingIndicatorService
                    .withFullscreen$(
                      this.updateQuoteService.updateQuote$(payload.shipmentOrder.id, mapBeneficialOwnerResult(result)).pipe(
                        catchError((error) => {
                          this.errorNotificationService.notifyAboutError(error, 'ERROR.FAILED_TO_SAVE_BENEFICIAL_OWNER_DETAILS');
                          return EMPTY;
                        })
                      )
                    )
                    .pipe(tap(() => dialogRef.close(true)));
                case IntentionWithGoods.OTHER:
                  steps$.next([...steps$.value, BeneficialOwnerStep.OtherDetails]);
                  break;
              }
              break;
            case BeneficialOwnerStep.CompanyDetails:
              return this.buyerService.updateBuyer$(result.companyDetails, result.vatNumber).pipe(
                map(() => {
                  if (this.countryInEU(result.companyDetails.country)) {
                    steps$.next([...steps$.value, BeneficialOwnerStep.VatNumber]);
                  } else {
                    steps$.next([...steps$.value, BeneficialOwnerStep.Ownership]);
                  }
                })
              );
            case BeneficialOwnerStep.CompanySearch:
              if (result.companyDetails.id) {
                this.newBuyerId = '';
                if (this.countryInEU(result.companyDetails.country)) {
                  steps$.next([...steps$.value, BeneficialOwnerStep.CompanyConfirm]);
                  break;
                } else {
                  steps$.next([...steps$.value, BeneficialOwnerStep.CompanyDetails]);
                  break;
                }
              } else {
                steps$.next([...steps$.value, BeneficialOwnerStep.NewCompany]);
                break;
              }
            case BeneficialOwnerStep.NewCompany:
              if (this.newBuyerId) {
                result.companyDetails.id = this.newBuyerId;
                return this.buyerService.updateBuyer$(result.companyDetails, result.vatNumber).pipe(
                  map(() => {
                    if (this.countryInEU(result.companyDetails.country)) {
                      steps$.next([...steps$.value, BeneficialOwnerStep.VatNumber]);
                    } else {
                      steps$.next([...steps$.value, BeneficialOwnerStep.Ownership]);
                    }
                  })
                );
              } else {
                return this.buyerService.createBuyer$(result.companyDetails).pipe(
                  tap((resp) => {
                    this.newBuyerId = resp.Id;
                    if (this.countryInEU(result.companyDetails.country)) {
                      steps$.next([...steps$.value, BeneficialOwnerStep.VatNumber]);
                    } else {
                      steps$.next([...steps$.value, BeneficialOwnerStep.Ownership]);
                    }
                  })
                );
              }
            case BeneficialOwnerStep.VatNumber:
              result.ownership = null;
              if (result.vatNumber) {
                if (this.newBuyerId) {
                  result.companyDetails.id = this.newBuyerId;
                  this.loadingIndicatorService.open();
                  return this.buyerService.updateBuyer$(result.companyDetails, result.vatNumber).pipe(
                    switchMap(() =>
                      this.updateQuoteService.updateQuote$(payload.shipmentOrder.id, mapBeneficialOwnerResult(result)).pipe(
                        finalize(() => {
                          this.loadingIndicatorService.dispose();
                          dialogRef.close(true);
                        })
                      )
                    ),
                    catchError((error) => {
                      this.errorNotificationService.notifyAboutError(error, 'ERROR.FAILED_TO_SAVE_QUOTE_BASICS');
                      return EMPTY;
                    })
                  );
                } else {
                  this.loadingIndicatorService.open();
                  return this.buyerService.updateBuyer$(result.companyDetails, result.vatNumber).pipe(
                    switchMap(() =>
                      this.updateQuoteService
                        .updateQuote$(payload.shipmentOrder.id, mapBeneficialOwnerResult(result))
                        .pipe(finalize(() => this.loadingIndicatorService.dispose()))
                    ),
                    tap(() => dialogRef.close(true)),
                    catchError((error) => {
                      this.errorNotificationService.notifyAboutError(error, 'ERROR.FAILED_TO_SAVE_QUOTE_BASICS');
                      return EMPTY;
                    })
                  );
                }
              } else {
                result.vatNumber = ' - ';
                if (this.newBuyerId) {
                  result.companyDetails.id = this.newBuyerId;
                  return this.loadingIndicatorService
                    .withFullscreen$(
                      this.updateQuoteService.updateQuote$(payload.shipmentOrder.id, mapBeneficialOwnerResult(result)).pipe(
                        catchError((error) => {
                          this.errorNotificationService.notifyAboutError(error, 'ERROR.FAILED_TO_SAVE_BENEFICIAL_OWNER_DETAILS');
                          return EMPTY;
                        })
                      )
                    )
                    .pipe(tap(() => dialogRef.close(true)));
                } else {
                  return this.loadingIndicatorService
                    .withFullscreen$(
                      this.updateQuoteService.updateQuote$(payload.shipmentOrder.id, mapBeneficialOwnerResult(result)).pipe(
                        catchError((error) => {
                          this.errorNotificationService.notifyAboutError(error, 'ERROR.FAILED_TO_SAVE_BENEFICIAL_OWNER_DETAILS');
                          return EMPTY;
                        })
                      )
                    )
                    .pipe(tap(() => dialogRef.close(true)));
                }
              }
            case BeneficialOwnerStep.CompanyConfirm:
              if (this.countryInEU(result.companyDetails.country)) {
                return this.loadingIndicatorService
                  .withFullscreen$(
                    this.updateQuoteService.updateQuote$(payload.shipmentOrder.id, mapBeneficialOwnerResult(result)).pipe(
                      catchError((error) => {
                        this.errorNotificationService.notifyAboutError(error, 'ERROR.FAILED_TO_SAVE_BENEFICIAL_OWNER_DETAILS');
                        return EMPTY;
                      })
                    )
                  )
                  .pipe(tap(() => dialogRef.close(true)));
              } else {
                steps$.next([...steps$.value, BeneficialOwnerStep.Ownership]);
                break;
              }
            case BeneficialOwnerStep.OtherDetails:
            case BeneficialOwnerStep.Ownership:
              if (this.newBuyerId) {
                result.companyDetails.id = this.newBuyerId;
                return this.loadingIndicatorService
                  .withFullscreen$(
                    this.updateQuoteService.updateQuote$(payload.shipmentOrder.id, mapBeneficialOwnerResult(result)).pipe(
                      catchError((error) => {
                        this.errorNotificationService.notifyAboutError(error, 'ERROR.FAILED_TO_SAVE_BENEFICIAL_OWNER_DETAILS');
                        return EMPTY;
                      })
                    )
                  )
                  .pipe(tap(() => dialogRef.close(true)));
              } else {
                return this.loadingIndicatorService
                  .withFullscreen$(
                    this.updateQuoteService.updateQuote$(payload.shipmentOrder.id, mapBeneficialOwnerResult(result)).pipe(
                      catchError((error) => {
                        this.errorNotificationService.notifyAboutError(error, 'ERROR.FAILED_TO_SAVE_BENEFICIAL_OWNER_DETAILS');
                        return EMPTY;
                      })
                    )
                  )
                  .pipe(tap(() => dialogRef.close(true)));
              }
            default:
              return assertNever(currentStep);
          }

          return of();
        },
      },
      {
        showMode: DialogShowMode.Side,
        closeButton: false,
        closeOnNavigation: true,
        width: '800px',
        injector: this.injector,
      }
    );

    return dialogRef;
  }

  public countryInEU(country: string): boolean {
    return this.EUCountries.includes(country) ? true : false;
  }

  public readonly EUCountries = [
    'Poland',
    'Hungary',
    'Estonia',
    'Germany',
    'Sweden',
    'France',
    'Belgium',
    'Luxembourg',
    'Denmark',
    'Lithuania',
    'Latvia',
    'Cyprus',
    'Malta',
    'Netherlands',
    'Greece',
    'Austria',
    'Slovakia',
    'Portugal',
    'Spain',
    'Romania',
    'Slovenia',
    'Ireland',
    'Czech Republic',
    'Croatia',
    'Bulgaria',
    'Italy',
    'Finland',
  ];
}
