import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { BuyerRetainsOwnership, IntentionWithGoods } from '@tecex-api/data';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { assertNever } from '../../../../helpers/assert-never.helper';
import { InputDataVM } from '../../../../interfaces/input-data.vm';
import { DIALOG_DATA } from '../../../dialog/dialog.tokens';
import { DialogData } from '../../../dialog/interfaces/dialog-data.interface';
import { BeneficialOwnerDialogPayload } from './beneficial-owner-dialog-payload.interface';
import { BeneficialOwnerStep } from './beneficial-owner-step.enum';
import { BuyerAccountVM } from './buyer-account.vm';
import { BuyerService } from './buyer.service';

@Component({
  selector: 'app-beneficial-owner-dialog',
  templateUrl: './beneficial-owner-dialog.component.html',
  styleUrls: ['./beneficial-owner-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BeneficialOwnerDialogComponent implements OnDestroy, OnInit {
  public step: BeneficialOwnerStep;

  public readonly BeneficialOwnerStep = BeneficialOwnerStep;
  public readonly IntentionWithGoods = IntentionWithGoods;
  public readonly BuyerRetainsOwnership = BuyerRetainsOwnership;
  public readonly intentionWithGoodsControl = new FormControl<IntentionWithGoods>(undefined, [Validators.required]);
  public readonly companyDetailsForm = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    country: new FormControl<InputDataVM<string, string>>(undefined, [Validators.required]),
    address: new FormControl<string>('', [Validators.required]),
    postalCode: new FormControl<string>('', [Validators.required]),
    city: new FormControl<string>('', [Validators.required]),
    id: new FormControl<string>(''),
  });
  public readonly companyNameControl = this.companyDetailsForm.controls.name as FormControl<string>;
  public readonly companyCountryControl = this.companyDetailsForm.controls.country as FormControl<InputDataVM<string, string>>;
  public readonly companyPostalCodeControl = this.companyDetailsForm.controls.postalCode as FormControl<string>;
  public readonly companyCityControl = this.companyDetailsForm.controls.city as FormControl<string>;
  public readonly companyAddressControl = this.companyDetailsForm.controls.address as FormControl<string>;
  public readonly companyIdControl = this.companyDetailsForm.controls.id as FormControl<string>;
  public readonly otherDescriptionControl = new FormControl<string>('', [Validators.required]);
  public readonly vatNumberControl = new FormControl<string>('', [Validators.required]);
  public readonly ownershipControl = new FormControl<BuyerRetainsOwnership>(undefined, [Validators.required]);
  public readonly namesOfEntities$: Observable<InputDataVM<string, string>[]> = this.buyerService.getBuyers$();
  public readonly buyersList$: Observable<BuyerAccountVM[]> = this.buyerService.getBuyersList$();
  public readonly countries = this.data.payload.countries.map(
    (country): InputDataVM<string, string> => ({ value: country.value, viewValue: country.label })
  );
  public buyerAccount: BuyerAccountVM;

  private readonly destroyed$ = new Subject<void>();
  public readonly nameOfAddressedEntityControl = new FormControl<InputDataVM<string, string>>(undefined, [Validators.required]);

  constructor(
    @Inject(DIALOG_DATA) public readonly data: DialogData<BeneficialOwnerDialogPayload>,
    private readonly buyerService: BuyerService
  ) {
    this.data.payload.step$.pipe(takeUntil(this.destroyed$)).subscribe((step) => (this.step = step));
    this.checkBuyer(this.data.payload.shipmentOrder.buyerAccount);
  }

  public ngOnInit(): void {
    this.nameOfAddressedEntityControl.valueChanges.subscribe((x) => {
      if (x.value) {
        this.checkBuyer(x.value);
      } else {
        this.companyNameControl.setValue(x.viewValue);
        this.companyAddressControl.setValue('');
        this.companyCityControl.setValue('');
        this.companyCountryControl.setValue({ value: '', viewValue: '' });
        this.companyPostalCodeControl.setValue('');
        this.companyIdControl.setValue('');
        this.vatNumberControl.setValue('');
      }
    });
  }

  @Input() public size = '18px';

  public get canProceed(): boolean {
    switch (this.step) {
      case BeneficialOwnerStep.IntetionWithGoods:
        return this.intentionWithGoodsControl.valid;
      case BeneficialOwnerStep.CompanyDetails:
        return this.companyDetailsForm.valid;
      case BeneficialOwnerStep.OtherDetails:
        return this.otherDescriptionControl.valid;
      case BeneficialOwnerStep.VatNumber:
        return true;
      case BeneficialOwnerStep.Ownership:
        return this.ownershipControl.valid;
      case BeneficialOwnerStep.CompanySearch:
        return this.nameOfAddressedEntityControl.valid;
      case BeneficialOwnerStep.CompanyConfirm:
        return true;
      case BeneficialOwnerStep.NewCompany:
        return this.companyDetailsForm.valid;
      default:
        return assertNever(this.step);
    }
  }

  public onBackClick(): void {
    if (this.step === BeneficialOwnerStep.VatNumber) this.vatNumberControl.setValue('');
    if (this.step === BeneficialOwnerStep.CompanyDetails) {
      this.companyCountryControl.setValue({ value: '', viewValue: '' });
      this.companyAddressControl.setValue('');
      this.companyCityControl.setValue('');
      this.companyPostalCodeControl.setValue('');
    }
    if (this.step === BeneficialOwnerStep.NewCompany) {
      this.companyCountryControl.setValue({ value: '', viewValue: '' });
      this.companyAddressControl.setValue('');
      this.companyCityControl.setValue('');
      this.companyPostalCodeControl.setValue('');
    }
    this.data.payload.handleBack();
  }

  public onNextClick(): void | Subscription {
    this.data.payload
      .handleNext$({
        intentionWithGoods: this.intentionWithGoodsControl.value,
        companyDetails: {
          name: this.companyNameControl.value,
          country: this.companyCountryControl.value?.value,
          address: this.companyAddressControl.value,
          postalCode: this.companyPostalCodeControl.value,
          city: this.companyCityControl.value,
          id: this.companyIdControl.value,
        },
        otherDetails: this.otherDescriptionControl.value,
        vatNumber: this.vatNumberControl.value,
        ownership: this.ownershipControl.value,
        buyerAccounts$: this.buyersList$,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe();
  }

  public checkBuyer(id: string): void {
    this.buyersList$.pipe(takeUntil(this.destroyed$)).subscribe((buyers) => {
      this.buyerAccount = buyers.find((x) => x.value === id);
      if (this.buyerAccount) {
        this.companyNameControl.setValue(this.buyerAccount.viewValue);
        this.companyAddressControl.setValue(this.buyerAccount.streetAddress);
        this.companyCityControl.setValue(this.buyerAccount.city);
        this.companyCountryControl.setValue({ value: this.buyerAccount.country, viewValue: this.buyerAccount.country });
        this.companyPostalCodeControl.setValue(this.buyerAccount.postalCode);
        this.companyIdControl.setValue(this.buyerAccount.value);
        this.vatNumberControl.setValue(this.buyerAccount.vatNumber ? this.buyerAccount.vatNumber : '');
      }
    });
  }

  public onMessageClick(): void {
    this.data.payload.onMsgClickHandler();
  }

  public showSubmit(): boolean {
    switch (
      this.step === BeneficialOwnerStep.CompanyConfirm ||
      this.step === BeneficialOwnerStep.OtherDetails ||
      this.step === BeneficialOwnerStep.VatNumber ||
      this.step === BeneficialOwnerStep.Ownership
    ) {
      case true:
        return true;
      case false:
        return false;
    }
  }

  public onCancelClick(): void {
    this.data.dialogRef.close();
  }

  public onDifferentBuyer(): void {
    this.data.payload.handleSelectBuyer();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
