import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { DatepickerBlockPayload } from '../../types/datepicker-block-payload.interface';
import { DatepickerBlockResult } from '../../types/datepicker-block-result.interface';
import { BlockComponent } from '../block/block.component';

@Component({
  selector: 'app-datepicker-block',
  templateUrl: './datepicker-block.component.html',
  styleUrls: ['./datepicker-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerBlockComponent extends BlockComponent<DatepickerBlockPayload, DatepickerBlockResult> implements OnInit {
  public form = new FormGroup<DatepickerBlockResult>({
    date: new FormControl<Date>(),
    time: new FormControl<string>(),
  });

  public get dateControl(): FormControl<Date> {
    return this.form.controls.date as FormControl<Date>;
  }

  public get timeControl(): FormControl<string> {
    return this.form.controls.time as FormControl<string>;
  }

  public ngOnInit(): void {
    this.register.emit(this.form);
  }
}
