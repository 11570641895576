import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import isNil from 'lodash/isNil';
import mixpanel from 'mixpanel-browser';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { CONFIG_TOKEN } from '../config/config.token';
import { GlobalConfig } from '../config/global-config.interface';
import { MixpanelEvent } from '../enums/mixpanel-event.enum';
import { RouteSegment } from '../enums/route-segment.enum';
import { SourceApp } from '../enums/source-app.enum';
import { SOURCE_APP } from '../tokens/source-app.token';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  constructor(
    @Inject(CONFIG_TOKEN) private readonly config: GlobalConfig,
    @Inject(SOURCE_APP) private readonly sourceApp: SourceApp,
    private readonly authService: AuthService,
    private readonly router: Router
  ) {
    if (!this.config.mixpanelToken) {
      return;
    }

    mixpanel.init(this.config.mixpanelToken, { debug: false });
    mixpanel.register({
      flavour: this.sourceApp.toUpperCase(),
      platform: 'web',
    });

    this.authService
      .getUser$()
      .pipe(
        distinctUntilChanged(),
        filter((user) => !isNil(user))
      )
      .subscribe((user) => mixpanel.identify(user.id));

    this.authService.logout$.subscribe(() => mixpanel.reset());

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        filter(
          (event) =>
            !(event as NavigationEnd).url.includes(RouteSegment.Logout) &&
            !(event as NavigationEnd).url.includes(RouteSegment.LoginCallback)
        )
      )
      .subscribe((routerEvent) => {
        const url = (routerEvent as NavigationEnd).url;
        if (url.includes('/quotes/')) {
          this.track(MixpanelEvent.ScreenView, {
            screen_name: url,
            screen_name_base: url.includes('roll-out') ? '/quotes/roll-out/' : '/quotes/shipment-order',
          });
        } else if (url.includes('quoteIdToReuse') || url.includes('rolloutIdToReuse') || url.includes('rolloutIdToAddNewShipments')) {
          const screen_name_base = url.slice(0, -19);
          this.track(MixpanelEvent.ScreenView, {
            screen_name: url,
            screen_name_base,
          });
        } else if (!url.includes('dashboard') && !url.includes('/shipments-list/')) {
          this.track(MixpanelEvent.ScreenView, { screen_name: url, screen_name_base: url });
        }
      });
  }

  public track(action: MixpanelEvent, properties?: { [key: string]: any }): void {
    if (this.config.mixpanelToken) {
      mixpanel.track(action, properties);
    }
  }

  public timeEvent(action: MixpanelEvent): void {
    mixpanel.time_event(action);
  }
}
