import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { SourceApp } from '../../../../enums/source-app.enum';
import { AuthService } from '../../../../services/auth.service';
import { SOURCE_APP } from '../../../../tokens/source-app.token';
import { DIALOG_DATA } from '../../../dialog/dialog.tokens';
import { DialogData } from '../../../dialog/interfaces/dialog-data.interface';
import { AnnouncmentType } from '../../enums/announcment-type.enum';
import { Announcment } from '../../types/announcment.type';
import { MaintenanceDialogPayload } from './maintenance-dialog-payload.interface';

@Component({
  selector: 'app-maintenance-dialog',
  templateUrl: './maintenance-dialog.component.html',
  styleUrls: ['./maintenance-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceDialogComponent {
  public readonly announcment: Announcment;
  public readonly AnnounctmentType = AnnouncmentType;

  public readonly imgPath = `assets/icons/${this.sourceApp === SourceApp.Zee ? '' : 'dark_'}logo.svg`;

  constructor(
    @Inject(DIALOG_DATA) private readonly data: DialogData<MaintenanceDialogPayload>,
    private readonly authService: AuthService,
    @Inject(SOURCE_APP) private readonly sourceApp: SourceApp
  ) {
    this.announcment = this.data.payload.announcment;
  }

  public onLogoutClick(): void {
    this.authService.logout();
  }

  public onRefreshClick(): void {
    window.location.reload();
  }
}
