import { NgModule } from '@angular/core';
import { GlobalModule } from '../../global.module';
import { LoadingIndicatorModule } from '../loading-indicator/loading-indicator.module';
import { MessageThreadModule } from '../message-thread/message-thread.module';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { CaseMessageDialogService } from './services/case-message-dialog.service';
import { TasksMessageDialogService } from './services/tasks-message-dialog.service';

@NgModule({
  declarations: [MessageDialogComponent],
  imports: [GlobalModule, MessageThreadModule, LoadingIndicatorModule],
  exports: [MessageDialogComponent],
  providers: [CaseMessageDialogService, TasksMessageDialogService],
})
export class MessageDialogModule {}
