import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { StateIndicatorCircleState } from './state.enum';

@Component({
  selector: 'app-state-indicator-circle',
  templateUrl: './state-indicator-circle.component.html',
  styleUrls: ['./state-indicator-circle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StateIndicatorCircleComponent {
  @Input() public color = StateIndicatorCircleState.Red;
  @Input() public size?: number;

  constructor(private readonly sanitizer: DomSanitizer) {}

  public get dotStyle(): SafeStyle {
    // set css '--size' variable based on input size
    return this.size ? this.sanitizer.bypassSecurityTrustStyle('--size: ' + `${this.size}px`) : undefined;
  }
}
