import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONFIG_TOKEN } from '../config/config.token';
import { GlobalConfig } from '../config/global-config.interface';
import { SourceApp } from '../enums/source-app.enum';
import { SOURCE_APP } from '../tokens/source-app.token';

@Injectable()
export class SourceAppInterceptor implements HttpInterceptor {
  constructor(@Inject(SOURCE_APP) private readonly sourceApp: SourceApp, @Inject(CONFIG_TOKEN) private readonly config: GlobalConfig) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // only intercept POST API requests
    if (!request.url.startsWith(this.config.apiBaseUrl) || request.method !== 'POST') {
      return next.handle(request);
    }
    const updatedRequest = request.clone({ body: { ...request.body, AppName: this.sourceApp } });

    return next.handle(updatedRequest);
  }
}
