import { ShippingStatus } from '@tecex-api/data';
import { TrackingState } from '../enums/tracking-state.enum';

export const mapTrackingState = (status: ShippingStatus): TrackingState | undefined => {
  switch (status) {
    case ShippingStatus.IN_TRANSIT_TO_HUB:
    case ShippingStatus.ARRIVED_AT_HUB:
    case ShippingStatus.IN_TRANSIT_TO_COUNTRY:
      return TrackingState.InTransit;
    case ShippingStatus.ARRIVED_IN_COUNTRY_AWAITING_CUSTOMS_CLEARANCE:
      return TrackingState.ArrivedInCountry;
    case ShippingStatus.CLEARED_CUSTOMS:
      return TrackingState.ClearedCustoms;
    case ShippingStatus.FINAL_DELIVERY_IN_PROGRESS:
      return TrackingState.FinalDeliveryInProgress;
    case ShippingStatus.AWAITING_POD:
    case ShippingStatus.POD_RECEIVED:
    case ShippingStatus.CUSTOMS_CLEARANCE_DOCS_RECEIVED:
      return TrackingState.Delivered;
    case ShippingStatus.CANCELLED___WITH_FEES_COSTS:
    case ShippingStatus.CANCELLED___NO_FEES_COSTS:
      return TrackingState.Cancelled;
    default:
      return TrackingState.FinalDeliveryInProgress;
  }
};
