import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';
import { AnnouncmentService } from '../services/announcment.service';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceGuard implements CanActivate {
  constructor(private readonly announcmentService: AnnouncmentService, private readonly authService: AuthService) {}

  // tslint:disable-next-line: observable-postfix
  public canActivate(): Observable<boolean> {
    const isMaintenanceSession = this.authService.isMaintenanceSession();

    return this.announcmentService.isUnderMaintenance$().pipe(
      map((isUnderMaintenance) => {
        if (!isUnderMaintenance) {
          return true;
        }

        return isMaintenanceSession;
      }),
      tap(() => {
        if (!isMaintenanceSession) {
          this.announcmentService.handleMaintenance();
        }
      })
    );
  }
}
