import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appMultiAutocompleteOption]',
})
export class MultiAutocompleteOptionDirective {
  @Input('appMultiAutocompleteOption') public value: any;
  // tslint:disable-next-line:no-input-rename
  @Input('appMultiAutocompleteOptionSearchValue') public searchValue: string;

  constructor(public readonly templateRef: TemplateRef<any>) {}
}
