import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavigationItem } from './navigation-item.interface';

@Component({
  selector: 'app-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationItemComponent {
  @Input() public menuItem: NavigationItem;
}
