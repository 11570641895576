import { GetUsersResponse } from '@tecex-api/data';
import isNil from 'lodash/isNil';
import uniqBy from 'lodash/uniqBy';
import { MyTeamPageContactVM } from '../interfaces/my-team-page-contact.vm';

export const mapUsers = (response: GetUsersResponse, currentUserId: string): MyTeamPageContactVM[] => {
  const allUsers = uniqBy([...(response.ClientUsers || []), ...(response.ACTecexPeople || [])], (item) => item.UserID).filter(
    (user) => !isNil(user.ContactId)
  );

  return allUsers.map((user) => ({
    userId: user.UserID,
    contactId: user.ContactId,
    name: user.Username,
    role: user.ContactRole,
    includeInInvoicingEmails: user.Includeininvoicingemails,
    lastLogin: user.LastLoginDate,
    picture: user.FullPhotoUrl,
    isCurrentUser: user.UserID === currentUserId,
    ncpPermission: user.NCPPermission === 'All' ? 'Quote Creation;Quote Acceptance;Task Completion;Invoice Access' : user.NCPPermission,
    superUser: user.SuperUser,
  }));
};
