import { AbstractControl, ValidatorFn } from '@ngneat/reactive-forms';
import isNil from 'lodash/isNil';

export class CustomValidators {
  public static conditionalValidator(predicate: (control: AbstractControl) => boolean, validator: ValidatorFn): ValidatorFn {
    return (control) => {
      if (isNil(control.parent)) {
        return null;
      }
      if (predicate(control as AbstractControl)) {
        return validator(control);
      }
      return null;
    };
  }
}
