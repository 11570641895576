import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { isString } from 'lodash';
import { Subject, takeUntil } from 'rxjs';
import { ThirdPartyDialogPayload, ThirdPartyDialogResult } from '../../../../interfaces/third-party-dialog.vm';
import { TeamMemberService } from '../../../../services/team-member.service';
import { DIALOG_DATA } from '../../../dialog/dialog.tokens';
import { DialogData } from '../../../dialog/interfaces/dialog-data.interface';
import { TeamMemberListType } from '../../../message-thread/enums/team-member-list-type.enum';
import { ThirdPartyCardVM } from '../third-party-card/party.vm';
import { ThirdPartyListVM } from '../third-party-list/third-party.vm';

@Component({
  selector: 'app-third-party-dialog',
  templateUrl: './third-party-dialog.component.html',
  styleUrls: ['./third-party-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThirdPartyDialogComponent implements OnInit, OnDestroy {
  public result: ThirdPartyDialogResult;
  private readonly destroyed$ = new Subject<void>();
  public categories: any = [];
  public selectedCategory: any = {};
  public searchText = '';
  @Output() public selectedPartyChange = new EventEmitter<ThirdPartyCardVM[]>();

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: DialogData<ThirdPartyDialogPayload, ThirdPartyDialogResult>,
    private readonly teamMemberService: TeamMemberService
  ) {
    this.result = {
      categories: [],
    };
  }

  public ngOnInit(): any {
    this.categories = [];
    this.teamMemberService
      .getAllThirdParties$(null, TeamMemberListType.ThirdParty)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((teamMembers) => {
        teamMembers.defaults.map((teamMember: any) => {
          const index = this.categories.map((e: { title: any }) => e.title).indexOf(teamMember.AffiliateType);
          if (teamMember.AffiliateType !== 'Other') {
            if (index === -1)
              this.categories.push({
                title: teamMember.AffiliateType,
                count: 1,
                fullPartyList: [teamMember],
                selectedPartyList: this.data.payload.selectedParties.map((e: any) => e).includes(teamMember.id) ? [teamMember] : [],
                filteredPartyList: [],
              });
            else {
              this.categories[index].count = this.categories[index].count + 1;
              this.categories[index].fullPartyList.push(teamMember);
              if (this.data.payload.selectedParties.map((e: any) => e).includes(teamMember.id))
                this.categories[index].selectedPartyList.push(teamMember);
            }
          }
        });
        this.selectedCategory = this.categories[0];
        this.categories.forEach((category: any) => {
          const fullList = this.sortCategory(category);
          category.fullPartyList = fullList;
          category.filteredPartyList = this.getFilteredPartyList(category);
        });
      });
  }

  public sortCategory(category: any = {}): any {
    return category.fullPartyList.sort((a: any, b: any) => {
      if (a.PreferredThirdParty !== b.PreferredThirdParty) {
        return a.PreferredThirdParty ? -1 : 1;
      }
      return a.Name.localeCompare(b.Name);
    });
  }

  public onSaveClick(): void {
    this.data.dialogRef.close(this.result);
  }

  public searchParties(searchText: string): void {
    this.searchText = searchText;
    this.categories.forEach((cat: any) => {
      cat.filteredPartyList = this.getFilteredPartyList(cat);
    });
  }

  public onToggleParty(selectedParty: ThirdPartyListVM, category: []): void {
    this.updateLists([selectedParty], category);
  }

  private updateLists(selectedParty: any[], category: any): void {
    category.selectedPartyList = category.fullPartyList.filter((party: any) =>
      selectedParty.some((selectParty) => party.id === selectParty.id)
    );
    category.filteredPartyList = this.getFilteredPartyList(category);
    this.selectedPartyChange.emit(category.selectedPartyList);
  }

  private getFilteredPartyList(category: any): any[] {
    const selectablePartyList =
      category.selectedPartyList && category.selectedPartyList.length > 0
        ? category.fullPartyList.filter((party: any) => !category.selectedPartyList.find((selectParty: any) => selectParty.id === party.id))
        : category.fullPartyList;

    if (this.searchText === undefined || this.searchText === '') {
      return selectablePartyList;
    }
    return selectablePartyList.filter((party: any) =>
      Object.values(party)
        .filter((value): value is string => isString(value))
        .some((value) => value.toLowerCase().includes(this.searchText.trim().toLowerCase()))
    );
  }

  public onDeleteParty(party: [], category: any = {}): void {
    this.updateLists(party, category);
  }

  public onAddPartiesClick(): void {
    this.data.dialogRef.close(this.categories);
  }

  public clickCategory(category: any = {}): any {
    this.selectedCategory = category;
  }

  public countAddedParties(): any {
    let count = 0;
    this.categories.forEach((category: any) => {
      count = count + category.selectedPartyList.length;
    });
    return count;
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
