import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { RouteSegment } from '../../../../enums/route-segment.enum';
import { SourceApp } from '../../../../enums/source-app.enum';
import { User } from '../../../../interfaces/user.interface';
import { AuthService } from '../../../../services/auth.service';
import { SOURCE_APP } from '../../../../tokens/source-app.token';
import { NavBrand } from '../../nav-brand.interface';
import { ProfileMenuItem } from '../../profile-menu-item.interface';
import { AppNavDrawerService } from '../app-nav-drawer/app-nav-drawer.service';
import { HelpMenuItem, NavigationItem } from '../navigation-item/navigation-item.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatMenuTrigger) public menuTrigger: MatMenuTrigger;

  @Input() public version: string;
  @Input() public logo: NavBrand;
  @Input() public settingsMenuItems: ProfileMenuItem[];
  @Input() public profileMenuItems: ProfileMenuItem[];
  @Input() public profileMenuForThirdPartyDashboardItems: ProfileMenuItem[];
  @Input() public logOutLink: ProfileMenuItem;
  @Input() public profileLink: ProfileMenuItem;
  // @Input() public menuItems: NavigationItem[];
  @Input() public helpMenuItem: HelpMenuItem;
  @Input() public thirdPartyFlag: boolean;

  public readonly dashboardLink = [RouteSegment.Dashboard];
  public readonly newQuoteLink = [RouteSegment.NewQuote];
  public menuItems: NavigationItem[] = [];
  public readonly isQuoteInProgress$: Observable<boolean>;
  public readonly user$: Observable<User>;
  public isMenuOpen = false;
  // public readonly version = VERSION;
  public quoteCreateFlag: boolean;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly cdr: ChangeDetectorRef,
    private readonly appNavDrawerService: AppNavDrawerService,
    @Inject(SOURCE_APP) public sourceApp: SourceApp
  ) {
    this.isQuoteInProgress$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      startWith(null),
      map(() =>
        this.router.isActive(this.router.createUrlTree(this.newQuoteLink), {
          paths: 'subset',
          queryParams: 'subset',
          fragment: 'ignored',
          matrixParams: 'ignored',
        })
      )
    );

    this.user$ = this.authService.getUser$();

    this.user$.subscribe((value) => {
      this.quoteCreateFlag = value?.ncpPermission?.split(';').includes('Quote Creation') ? false : true;

      // Add the condition for this functionality working with 'TecEx' project and remove from 'Zee' project :
      // eslint-disable-next-line prettier/prettier, unicorn/prefer-ternary
      if (sourceApp === 'TecEx') {
        this.menuItems = [
          {
            link: this.dashboardLink,
            exact: true,
            icon: 'dashboard',
            title: 'NAVIGATION.DASHBOARD',
          },
          {
            link: [RouteSegment.QuoteList],
            exact: false,
            icon: 'quote-new',
            title: 'NAVIGATION.QUOTES',
          },
          {
            link: [RouteSegment.ShipmentsList],
            exact: false,
            icon: 'list',
            title: 'NAVIGATION.SHIPMENTS',
          },
          {
            link: [RouteSegment.Tracking],
            exact: true,
            icon: 'plane-take-off',
            title: 'NAVIGATION.TRACKING',
          },
          {
            link: [RouteSegment.Insights],
            exact: true,
            icon: 'insights',
            title: 'NAVIGATION.INSIGHTS',
          },
          {
            link: [RouteSegment.Messages],
            exact: true,
            icon: 'messages',
            title: 'NAVIGATION.MESSAGES',
          },
        ];
        if (value?.ncpPermission?.split(';').includes('Invoice Access')) {
          const invoiceMenu = {
            link: [RouteSegment.Invoices],
            exact: true,
            icon: 'quotes',
            title: 'NAVIGATION.INVOICES',
          };
          const menuLen = this.menuItems.length;
          this.menuItems.splice(menuLen - 2, 0, invoiceMenu);
        }
        if (value.quoteGuidanceOptIn) {
          const quoteGuidance = {
            link: [RouteSegment.QuoteGuidance],
            exact: false,
            icon: 'quote-guidance',
            title: 'NAVIGATION.GUIDANCE',
          };
          this.menuItems.splice(1, 0, quoteGuidance);
        }
      } else {
        this.menuItems = [
          {
            link: this.dashboardLink,
            exact: true,
            icon: 'dashboard',
            title: 'NAVIGATION.DASHBOARD',
          },
          {
            link: [RouteSegment.QuoteList],
            exact: false,
            icon: 'quote-new',
            title: 'NAVIGATION.QUOTES',
          },
          {
            link: [RouteSegment.ShipmentsList],
            exact: false,
            icon: 'list',
            title: 'NAVIGATION.SHIPMENTS',
          },
          {
            link: [RouteSegment.Tracking],
            exact: true,
            icon: 'plane-take-off',
            title: 'NAVIGATION.TRACKING',
          },
          {
            link: [RouteSegment.Invoices],
            exact: true,
            icon: 'quotes',
            title: 'NAVIGATION.INVOICES',
          },
          {
            link: [RouteSegment.Insights],
            exact: true,
            icon: 'insights',
            title: 'NAVIGATION.INSIGHTS',
          },
          {
            link: [RouteSegment.Messages],
            exact: true,
            icon: 'messages',
            title: 'NAVIGATION.MESSAGES',
          },
        ];
      }
    });
  }

  public ngAfterViewInit(): void {
    // Without these the name is not hidden after closing the menu ¯\_(ツ)_/¯.
    this.menuTrigger.menuOpened.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.isMenuOpen = true;
      this.cdr.markForCheck();
    });
    this.menuTrigger.menuClosed.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.isMenuOpen = false;
      this.cdr.markForCheck();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onOpenSideNavClick = (): void => {
    this.appNavDrawerService.open({
      menuItems: this.menuItems,
      helpMenuItem: this.helpMenuItem,
      settingsMenuItems: this.settingsMenuItems,
      version: this.version,
      logOutLink: this.logOutLink,
      profileLink: this.profileLink,
    });
  };
}
