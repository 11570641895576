import { RobotStatus } from '@tecex-api/data';
import { TaskState } from '../enums/task-state.enum';

export const mapTaskStates = (state: RobotStatus): TaskState => {
  switch (state) {
    case RobotStatus.RED:
      return TaskState.Blocked;
    case RobotStatus.ORANGE:
      return TaskState.Inprogress;
    case RobotStatus.GREEN:
      return TaskState.Done;
    default:
      return TaskState.Blocked;
  }
};
