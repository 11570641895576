import { LineItem } from '@global/modules/common-quote/interfaces/line-item.interface';
import { createAction, props } from '@ngrx/store';

export const enter = createAction('[New Quote Line Items] Enter');

export const submit = createAction(
  '[New Quote Line Items] Submit',
  props<{ lineItems: LineItem[]; tracking: { addToShipmentCount: number; lineItemsIdentical: boolean } }>()
);

export const submitSuccess = createAction('[New Quote Line Items] SubmitSuccess', props<{ lineItems: LineItem[] }>());

export const submitError = createAction('[New Quote Line Items] SubmitError', props<{ error: string }>());

export const skip = createAction('[New Quote Line Items] Skip');

export const skipSuccess = createAction('[New Quote Line Items] SkipSuccess');

export const edit = createAction('[New Quote Line Items] Edit');

export const editSuccess = createAction('[New Quote Line Items] EditSuccess', props<{ lineItems: LineItem[] }>());

export const addToShipment = createAction(
  '[New Quote Line Items] addToShipment',
  props<{ lineItems: LineItem[]; shipmentOrderDetailBasedOnApplyForLineItems: any }>()
);

export const addToShipmentSuccess = createAction('[New Quote Line Items] addToShipmentSuccess', props<{ successOfShipments: any }>());

export const updateLineItems = createAction('[New Quote Line Items] updateLineItems', props<{ lineItems: LineItem[]; soId: string }>());

export const updateLineItemsSuccess = createAction('[New Quote Line Items] updateLineItemsSuccess', props<{ successResponse: any }>());

export const SOAllRelatedInfoFromSelectedSoId = createAction(
  '[New Quote Line Items] SOAllRelatedInfoFromSelectedSoId',
  props<{ SoId: string }>()
);

export const SOAllRelatedInfoFromSelectedSoIdSuccess = createAction(
  '[New Quote Line Items] SOAllRelatedInfoFromSelectedSoIdSuccess',
  props<{ RollOutDetail: any }>()
);

export const editError = createAction('[New Quote Line Items] EditError', props<{ error: string }>());
