import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-indicator',
  template: '<mat-spinner [diameter]="diameter"></mat-spinner>',
  styles: [':host { overflow: hidden }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingIndicatorComponent {
  @Input() public diameter = 50;
}
