import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { CommonAddressModule } from '@global/modules/common-address/common-address.module';
import { FormControlModule } from '@global/modules/form-control/form-control.module';
import { SvgIconsModule } from '@global/modules/svg-icons/svg-icons.module';
import { ADDRESS_SERVICE } from '@global/tokens/address-service.token';
import { SharedModule } from '@shared/shared.module';
import { AlertBarComponent } from './components/alert-bar/alert-bar.component';
import { SelectAddressDialogComponent } from './components/select-address-dialog/select-address-dialog.component';
import { SelectAddressComponent } from './components/select-address/select-address.component';
import { AddressService } from './services/address.service';

@NgModule({
  imports: [SharedModule, CommonAddressModule, FormControlModule, MatCheckboxModule, MatRadioModule, SvgIconsModule],
  declarations: [SelectAddressComponent, SelectAddressDialogComponent, AlertBarComponent],
  exports: [SelectAddressComponent, SelectAddressDialogComponent],
})
export class AddressModule {
  public static forRoot(): ModuleWithProviders<AddressModule> {
    return {
      ngModule: AddressModule,
      providers: [
        AddressService,
        { provide: ADDRESS_SERVICE, useFactory: (addressService: AddressService) => addressService, deps: [AddressService] },
      ],
    };
  }
}
