import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SendbirdService } from '@global/modules/message-thread/services/sendbird-message.service';
import { AuthService } from '@global/services/auth.service';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent implements OnInit {
  constructor(private readonly authService: AuthService, private readonly sendbirdService: SendbirdService) {}

  public ngOnInit(): void {
    this.authService.logout();
  }
}
