import isNil from 'lodash/isNil';

export class FileHelper {
  private static readonly dataUrlRegexp: RegExp = new RegExp(
    `^\\s*data:([a-z]+/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\\-._~:@/?%\\s]*)\\s*$`,
    'i'
  );
  public static createFileFromDataURL(dataURL: string): File | undefined {
    const dataUrlMatch: RegExpMatchArray | null = dataURL.match(FileHelper.dataUrlRegexp);
    if (isNil(dataURL)) {
      return undefined;
    }
    // eslint-disable-next-line unicorn/no-unreadable-array-destructuring
    const [, mimeType, , , data] = dataUrlMatch;
    if (isNil(data)) {
      return undefined;
    }
    return FileHelper.createFileFromFileData('', data, mimeType);
  }
  public static createFileFromFileData(fileName: string, fileData: string, mimeType: string = 'application/octet-stream'): File {
    const decodedFileData = atob(fileData);
    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(decodedFileData.length);
    // create a view into the buffer
    const ia = new Uint8Array(ab);
    // set the bytes of the buffer to the correct values
    for (let i = 0; i < decodedFileData.length; i++) {
      ia[i] = decodedFileData.codePointAt(i);
    }
    return new File([ab], fileName, { type: mimeType });
  }
}
