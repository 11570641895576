import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import { EditAddressDialogResultType } from '@global/enums/edit-address-dialog-result-type.enum';
import { SelectAddressDialogType } from '@global/enums/select-address-dialog-type.enum';
import { AddressCardAddressVM as AddressVM } from '@global/interfaces/address/address.vm';
import { SelectAddressDialogPayload } from '@global/interfaces/address/select-address-dialog-payload.interface';
import { SelectAddress } from '@global/interfaces/address/select-address.interface';
import isString from 'lodash/isString';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-select-address',
  templateUrl: './select-address.component.html',
  styleUrls: ['./select-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectAddressComponent implements SelectAddress, OnDestroy {
  public searchText = '';
  /**
   * List of all addresses
   */
  private fullAddressList: AddressVM[];
  /**
   * List of filtered addresses available for selection
   */
  public filteredAddressList: AddressVM[];
  /**
   * List of selected addresses (after filtering)
   */
  public selectedAddressList: AddressVM[] = [];

  private readonly destroyed$ = new Subject<void>();

  public SelectAddressDialogType = SelectAddressDialogType;

  @Input() public type: SelectAddressDialogType;
  @Input() public title: string;
  @Input() public set initialAddresses(initialAddresses: AddressVM[]) {
    this._initialAddresses = initialAddresses;
    this.updateLists(this.initialSelectedAddresses, this.initialAddresses);
  }
  public get initialAddresses(): AddressVM[] {
    return this._initialAddresses;
  }
  private _initialAddresses: AddressVM[] = [];
  @Input() public set initialSelectedAddresses(initialSelectedAddresses: AddressVM[]) {
    this._initialSelectedAddresses = initialSelectedAddresses;
    this.updateLists(this.initialSelectedAddresses, this.initialAddresses);
  }
  public get initialSelectedAddresses(): AddressVM[] {
    return this._initialSelectedAddresses;
  }
  private _initialSelectedAddresses: AddressVM[] = [];
  @Input() public onCreate: SelectAddressDialogPayload['onCreate'];
  @Input() public onEdit: SelectAddressDialogPayload['onEdit'];

  @Output() public selectedAddressesChange = new EventEmitter<AddressVM[]>();

  public isRollout = false;

  constructor(private readonly cdr: ChangeDetectorRef, private readonly router: Router) {
    if (this.router.url.includes('roll-out')) this.isRollout = true;
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private updateLists(selectedAddressList: AddressVM[], addressList = this.fullAddressList): void {
    this.fullAddressList = addressList;
    this.selectedAddressList = addressList.filter((address) =>
      selectedAddressList.some((selectedAddress) => address.id === selectedAddress.id)
    );
    this.filteredAddressList = this.getFilteredAddressList();

    this.selectedAddressesChange.emit(this.selectedAddressList);
  }

  public get showNumberOfSelectedAddresses(): boolean {
    return this.type === SelectAddressDialogType.LocationAddresses && this.selectedAddressList.length > 0;
  }

  public get hasAddresses(): boolean {
    return this.fullAddressList.length > 0;
  }

  public onSelectAddress(address: AddressVM): void {
    if (!this.selectedAddressList.includes(address)) {
      this.updateLists(this.selectedAddressList.concat(address));
    } else {
      this.updateLists(this.selectedAddressList.filter((item: AddressVM) => item !== address));
    }
  }

  public onToggleAddress(selectedAddress: AddressVM): void {
    this.updateLists([selectedAddress]);
  }

  public onEditAddressCard(address: AddressVM): void {
    this.onEdit(address)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((updatedAddresses) => {
        this.updateLists(this.selectedAddressList, updatedAddresses);
        this.cdr.markForCheck();
      });
  }

  public onDeleteAddressCard(): void {
    this.updateLists([]);
    this.cdr.markForCheck();
  }

  public onAddNewAddressClick(): void {
    this.onCreate()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(({ addresses, result }) => {
        let newSelectedAddressList = this.selectedAddressList;
        if (result?.type === EditAddressDialogResultType.Create) {
          const newAddress = addresses.find((address) => address.id === result.address.id);
          newSelectedAddressList =
            this.type === SelectAddressDialogType.LocationAddresses ? newSelectedAddressList.concat(newAddress) : [newAddress];
        }
        this.updateLists(newSelectedAddressList, addresses);

        this.cdr.markForCheck();
      });
  }

  public searchAddresses(searchText: string): void {
    this.searchText = searchText;
    this.filteredAddressList = this.getFilteredAddressList();
  }

  private getFilteredAddressList(): AddressVM[] {
    const selectableAddressList =
      this.selectedAddressList.length > 0
        ? this.fullAddressList.filter((address) => !this.selectedAddressList.find((selectedAddress) => selectedAddress.id === address.id))
        : this.fullAddressList;

    if (this.searchText === undefined || this.searchText === '') {
      return selectableAddressList;
    }
    return selectableAddressList.filter((address) =>
      Object.values(address)
        .filter((value): value is string => isString(value))
        .some((value) => value.toLowerCase().includes(this.searchText.trim().toLowerCase()))
    );
  }
}
