<app-breakpoint query="SmallDown">
  <app-bar
    [logo]="logo"
    [showNewQuoteButton]="(isQuoteInProgress$ | async) === false"
    [onOpenSideNavClick]="onOpenSideNavClick"
    [newQuoteLink]="newQuoteLink"
    [thirdPartyFlag]="thirdPartyFlag"
  ></app-bar>
</app-breakpoint>

<app-breakpoint query="MediumUp">
  <div class="header-container" [class.third-party-header]="thirdPartyFlag">
    <a class="logo" [routerLink]="logo.link">
      <img [src]="logo.logo" [alt]="logo.alt" height="32" />
    </a>

    <div class="links" *ngIf="!thirdPartyFlag">
      <app-navigation-item *ngFor="let menuItem of menuItems" [menuItem]="menuItem"></app-navigation-item>
    </div>

    <div class="d-flex justify-content-end">
      <div class="d-flex align-items-center profile" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger">
        <ng-container *ngIf="user$ | async as user">
          <div *ngIf="isMenuOpen" class="text-reduced-regular user-name mr-3">{{ user.name }}</div>
          <img class="picture" [src]="user.profilePicture | profilePicture" />
        </ng-container>
        <button mat-icon-button type="button" class="mx-2 arrow-button">
          <svg-icon key="arrow-down" class="icon" [class.open]="isMenuOpen"></svg-icon>
        </button>
      </div>
      <mat-menu class="peofile-menu" #menu="matMenu">
        <ng-container *ngIf="!thirdPartyFlag; else thirdPartyProfileMenuItems">
          <a *ngFor="let profileMenuItem of profileMenuItems" [routerLink]="profileMenuItem.link" class="menu-item-button">
            <button mat-menu-item>
              <div class="d-flex align-items-center menu-item">
                <div class="menu-icon mr-2">
                  <svg-icon [key]="profileMenuItem.icon"></svg-icon>
                </div>
                <div class="menu-title d-flex align-items-center">
                  <span class="text-tiny-regular">{{ profileMenuItem.title | translate }}</span>
                </div>
              </div>
            </button>
          </a>
          <div class="text-micro-regular color-text-tertiary px-3">{{ 'COMMON.VERSION' | translate }}: {{ version }}</div>
        </ng-container>
        <ng-template #thirdPartyProfileMenuItems>
          <a
            *ngFor="let profileMenuForThirdPartyDashboardMenuItem of profileMenuForThirdPartyDashboardItems"
            [routerLink]="profileMenuForThirdPartyDashboardMenuItem.link"
            class="menu-item-button"
          >
            <button mat-menu-item class="asd">
              <div class="d-flex align-items-center menu-item">
                <div class="menu-icon mr-2">
                  <svg-icon [key]="profileMenuForThirdPartyDashboardMenuItem.icon"></svg-icon>
                </div>
                <div class="menu-title d-flex align-items-center">
                  <span class="text-tiny-regular">{{ profileMenuForThirdPartyDashboardMenuItem.title | translate }}</span>
                </div>
              </div>
            </button>
          </a>
          <div class="text-micro-regular color-text-tertiary px-3">{{ 'COMMON.VERSION' | translate }}: {{ version }}</div>
        </ng-template>
      </mat-menu>

      <ng-container *ngIf="!thirdPartyFlag">
        <div *ngIf="isQuoteInProgress$ | async; else newQuote" class="d-flex align-items-center quote-in-progress">
          <svg-icon key="pending" class="mr-2"></svg-icon>
          {{ 'HEADER.QUOTE_IN_PROGRESS' | translate }}
        </div>
      </ng-container>
      <ng-template #newQuote>
        <!-- This functionality is only for zee application for now : -->
        <a [routerLink]="newQuoteLink" class="new-quote-zee" mat-ripple *ngIf="sourceApp !== 'TecEx'">
          {{ 'HEADER.NEW_QUOTE' | translate }}
        </a>
        <ng-container *ngIf="sourceApp === 'TecEx'">
          <a
            *ngIf="!quoteCreateFlag; else buttonWithTooltipForCreateQuote"
            [routerLink]="newQuoteLink"
            class="new-quote"
            mat-ripple
            [ngClass]="!quoteCreateFlag ? 'new-quote-selection' : ''"
          >
            {{ 'HEADER.NEW_QUOTE' | translate }}
          </a>
          <ng-template #buttonWithTooltipForCreateQuote>
            <button
              class="new-quote"
              mat-ripple
              [ngClass]="quoteCreateFlag ? 'new-quote-disabled' : ''"
              [appTooltip]="'DASHBOARD.DOES_NOT_HAVE_PERMISSION_TO_CREATE_QUOTE' | translate"
              appTooltipStrategy="hover"
            >
              {{ 'HEADER.NEW_QUOTE' | translate }}
            </button>
          </ng-template>
        </ng-container>
      </ng-template>
    </div>
  </div>
</app-breakpoint>

<app-announcment-bar></app-announcment-bar>
