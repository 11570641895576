import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { DEFAULT_CURRENCY_CODE, Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import isNil from 'lodash/isNil';

@Pipe({
  name: 'cost',
  pure: true,
})
export class CostPipe implements PipeTransform {
  constructor(
    @Inject(DEFAULT_CURRENCY_CODE) private readonly defaultCurrencyCode: string = 'USD',
    @Inject(LOCALE_ID) private readonly locale: string
  ) {}

  public transform(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    value: any,
    currencyCode?: string,
    display: 'code' | 'symbol' | 'symbol-narrow' | string | boolean = 'symbol',
    digitsInfo?: string,
    locale?: string
  ): string {
    if (isNil(value)) {
      return 'N/A';
    }

    const currency = getCurrencySymbol(
      currencyCode || this.defaultCurrencyCode,
      display === 'symbol' ? 'wide' : 'narrow',
      locale || this.locale
    );
    const formattedValue = formatCurrency(value, locale || this.locale, currency, digitsInfo);

    return `${formattedValue.slice(0, currency.length)} ${formattedValue.slice(currency.length)}`;
  }
}
