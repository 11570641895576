import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { TooltipColor } from './tooltip-color.type';

export class TooltipRef {
  // tslint:disable-next-line: variable-name
  private readonly _afterClosed$ = new Subject<void>();

  constructor(private readonly overlayRef: OverlayRef, public readonly color: TooltipColor) {}

  public afterClosed$(): Observable<void> {
    return this._afterClosed$.asObservable();
  }

  public close(): void {
    this.overlayRef.detach();
    this._afterClosed$.next();
    this._afterClosed$.complete();
  }
}
