import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray } from '@ngneat/reactive-forms';
import { asyncScheduler, Subject } from 'rxjs';
import { observeOn, takeUntil } from 'rxjs/operators';
import { BlockType } from '../../types/block-type.enum';
import { Block } from '../../types/block.type';

@Component({
  selector: 'app-task-blocks',
  templateUrl: './task-blocks.component.html',
  styleUrls: ['./task-blocks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskBlocksComponent implements OnInit {
  @Input() public blocks: Block[];
  @Output() public validityChange = new EventEmitter<boolean>();

  public readonly formArray = new FormArray([]);
  public readonly BlockType = BlockType;

  private readonly destroyed$ = new Subject<void>();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.formArray.statusChanges.pipe(observeOn(asyncScheduler), takeUntil(this.destroyed$)).subscribe(() => {
      this.cdr.markForCheck();
    });

    this.formArray.status$.subscribe(() => this.validityChange.next(this.formArray.valid));
  }

  public onRegister(index: number, control: AbstractControl): void {
    this.formArray.setControl(index, control);
    this.formArray.updateValueAndValidity();
  }

  public getValue(): any {
    return this.formArray.getRawValue();
  }
}
