<app-header
  [logo]="logo"
  [version]="version"
  [settingsMenuItems]="settingsMenuItems"
  [profileMenuItems]="profileMenuItems"
  [profileMenuForThirdPartyDashboardItems]="profileMenuForThirdPartyDashboardItems"
  [logOutLink]="logOutLink"
  [profileLink]="profileLink"
  [helpMenuItem]="helpMenuItem"
  [thirdPartyFlag]="isThirdParty"
></app-header>
<router-outlet></router-outlet>
<app-intercom-launcher [icon]="'question'"></app-intercom-launcher>
<!-- [menuItems]="menuItems" -->
