import { ActiveInactive, OrderType, ProfileAddress, UserDefaultsResponse } from '@tecex-api/data';
import isNil from 'lodash/isNil';
import { CurrencyCode } from '../../../enums/currency-code.enum';
import { mapLengthUnit } from '../../../helpers/map-length-unit.helper';
import { mapServiceType } from '../../../helpers/map-service-type.helper';
import { mapWeightUnitWithNull } from '../../../helpers/map-weight-unit.helper';
import { AddressCardAddressVM } from '../../../interfaces/address/address.vm';
import { QuoteDefaultValues } from '../../../interfaces/quote-default-values.interface';

export const mapQuoteDefaultsResponse = (userDefaults: UserDefaultsResponse): Partial<QuoteDefaultValues> => {
  const defaults = userDefaults.ClientDefaultvalues?.find((defaultValue) => defaultValue.OrderType === OrderType.FINAL_QUOTE);
  if (!defaults) {
    return {};
  }

  const defaultPickUpAddresses = mapDefaultPickUpAddresses(userDefaults.PickupAddress);

  return {
    id: defaults.Id,
    serviceType: mapServiceType(defaults.ServiceType),
    shipFromCountry: defaults.ShipFromCountry,
    weightUnit: mapWeightUnitWithNull(defaults.PackageWeightUnits),
    dimensionUnit: mapLengthUnit(defaults.PackageDimensions),
    currency: defaults.Currency as CurrencyCode,
    liionBatteries: defaults.LiionBatteries,
    secondhandparts: defaults.Secondhandparts,
    tecExToHandleFreight: defaults.TecExtohandlefreight,
    defaultPickUpAddresses,
  };
};

const mapDefaultPickUpAddresses = (addresses?: ProfileAddress[]) => {
  if (isNil(addresses)) {
    return {};
  }

  // eslint-disable-next-line unicorn/prefer-object-from-entries
  return addresses.reduce<Record<string, AddressCardAddressVM>>((accumulator, address) => {
    if (address.AddressStatus !== ActiveInactive.ACTIVE || !address.Default) {
      return accumulator;
    }

    return {
      ...accumulator,
      [address.Country]: {
        isActive: true,
        id: address.Id,
        tag: address.PickupaddressName,
        contactPerson: address.ContactFullName,
        email: address.ContactEmail,
        phone: address.ContactPhoneNumber,
        additionalPhone: address.AdditionalContactNumber,
        companyName: address.CompanyName,
        streetAddress: address.Address,
        additionalStreetAddress: address.Address2,
        city: address.City,
        state: address.Province,
        country: address.Country,
        zip: address.PostalCode,
        comment: address.Comments,
        isDefault: true,
        pickupPreference: address.PickupPreference,
      },
    };
  }, {});
};
