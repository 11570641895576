import { ConnectedPosition, FlexibleConnectedPositionStrategy, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, Injector, Input, OnChanges, OnDestroy, SimpleChanges, TemplateRef } from '@angular/core';
import isNil from 'lodash/isNil';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TooltipColor } from './tooltip-color.type';
import { ArrowPosition, TooltipData } from './tooltip-data.interface';
import { TooltipRef } from './tooltip-ref';
import { TooltipComponent } from './tooltip.component';
import { TOOLTIP_DATA } from './tooltip.tokens';

@Directive({ selector: '[appTooltip]' })
export class TooltipDirective implements OnChanges, OnDestroy {
  @Input('appTooltip') public tooltip: string | TemplateRef<any> = '';
  @Input() public verticalPosition: 'top' | 'bottom' = 'top';
  // tslint:disable-next-line:no-input-rename
  @Input('appTooltipColor') public color: TooltipColor;
  @Input('appTooltipStrategy') public strategy: 'click' | 'hover' = 'click';
  private readonly verticalOffset = 18;
  private readonly scrollStrategy = this.overlay.scrollStrategies.close();
  private positionStrategy = this.createPositionStrategy();
  private overlayRef: OverlayRef;
  private tooltipRef: TooltipRef;

  constructor(
    private readonly overlay: Overlay,
    private readonly overlayPositionBuilder: OverlayPositionBuilder,
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly injector: Injector
  ) {}

  public ngOnDestroy(): void {
    if (!isNil(this.tooltipRef)) {
      this.tooltipRef.close();
    }
  }

  @HostListener('click')
  public onClick(): void {
    if (this.strategy !== 'click') {
      return;
    }

    this.show();
  }

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    if (this.strategy !== 'hover') {
      return;
    }

    this.show();
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    if (this.strategy !== 'hover' || isNil(this.tooltipRef)) {
      return;
    }

    this.tooltipRef.close();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.verticalPosition) {
      this.updatePositionStrategy();
    }
  }

  private show(): void {
    if (!this.tooltip) {
      return;
    }

    if (this.overlayRef === undefined) {
      this.overlayRef = this.createOverlayRef();
    }

    this.tooltipRef = new TooltipRef(this.overlayRef, this.color);
    this.tooltipRef.afterClosed$().subscribe(() => delete this.tooltipRef);

    this.overlayRef.attach(new ComponentPortal(TooltipComponent, undefined, this.createInjector(this.tooltipRef)));
    this.overlayRef
      .backdropClick()
      .pipe(takeUntil(this.tooltipRef.afterClosed$()))
      .subscribe(() => this.tooltipRef.close());
  }

  private updatePositionStrategy(): void {
    this.positionStrategy = this.createPositionStrategy();
    if (this.overlayRef) {
      this.overlayRef.updatePositionStrategy(this.positionStrategy);
    }
  }

  private createPositionStrategy(): FlexibleConnectedPositionStrategy {
    return this.overlayPositionBuilder.flexibleConnectedTo(this.elementRef).withPositions(this.getPositions());
  }

  // tslint:disable-next-line: cyclomatic-complexity
  private getPositions(): ConnectedPosition[] {
    const mostDesiredPosition: ConnectedPosition = {
      originX: 'center',
      originY: this.verticalPosition,
      overlayX: 'start',
      overlayY: this.verticalPosition === 'top' ? 'bottom' : 'top',
      offsetX: -18,
      offsetY: this.verticalPosition === 'top' ? -this.verticalOffset : this.verticalOffset,
    };
    return [
      mostDesiredPosition,
      { ...mostDesiredPosition, offsetX: 0 },
      {
        ...mostDesiredPosition,
        originY: this.verticalPosition === 'top' ? 'bottom' : 'top',
        overlayY: this.verticalPosition,
        offsetY: this.verticalPosition === 'top' ? this.verticalOffset : -this.verticalOffset,
      },
      {
        ...mostDesiredPosition,
        originY: this.verticalPosition === 'top' ? 'bottom' : 'top',
        overlayY: this.verticalPosition,
        offsetX: 0,
        offsetY: this.verticalPosition === 'top' ? this.verticalOffset : -this.verticalOffset,
      },
      {
        ...mostDesiredPosition,
        overlayX: 'end',
        offsetX: 18,
      },
    ];
  }

  private createOverlayRef(): OverlayRef {
    return this.overlay.create({
      positionStrategy: this.positionStrategy,
      scrollStrategy: this.scrollStrategy,
      hasBackdrop: this.strategy === 'click',
      backdropClass: '',
    });
  }

  private getArrowPosition$(): Observable<ArrowPosition> {
    return this.positionStrategy.positionChanges.pipe(
      map((positionChange) => {
        const verticalPosition = positionChange.connectionPair.originY as 'top' | 'bottom';
        const horizontalPosition = positionChange.connectionPair.overlayX;

        return { verticalPosition, horizontalPosition };
      })
    );
  }

  private createInjector(tooltipRef: TooltipRef): Injector {
    const tooltipData: TooltipData = {
      tooltip: this.tooltip,
      arrowPosition$: this.getArrowPosition$(),
      closeable: this.strategy === 'click',
    };

    return Injector.create({
      providers: [
        {
          provide: TOOLTIP_DATA,
          useValue: tooltipData,
        },
        {
          provide: TooltipRef,
          useValue: tooltipRef,
        },
      ],
      parent: this.injector,
    });
  }
}
