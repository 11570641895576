import { LoadableStateReducerHelper } from '@global/helpers/loadable-state-reducer.helper';
import { Contact } from '@global/interfaces/contact.interface';
import { Loadable } from '@global/interfaces/loadable.interface';
import { createReducer, on } from '@ngrx/store';
import { QuoteType } from '@shared/enums/quote-type.enum';
import * as QuoteDetailsActions from '../actions/quote-details.actions';
import { QuoteDetailsQuote, QuoteDetailsRollOutQuote } from '../interfaces/quote.interface';

export const quoteDetailsKey = 'quoteDetails';

export interface State {
  quote: Loadable<QuoteDetailsQuote | QuoteDetailsRollOutQuote>;
  contacts: Loadable<Contact[]>;
  isRollOut: boolean;
  rollOutDetail: any;
}

export const initialState: State = {
  quote: {
    isLoading: true,
    data: undefined,
  },
  contacts: {
    isLoading: false,
    data: [],
  },
  isRollOut: false,
  rollOutDetail: undefined,
};

export const reducer = createReducer<State>(
  initialState,
  on(
    QuoteDetailsActions.loadNewQuote,
    (state, { isRollOut }): State => ({
      ...state,
      isRollOut,
    })
  ),

  on(QuoteDetailsActions.loadContacts, (state) => ({
    ...state,
    contacts: LoadableStateReducerHelper.startLoad(state.contacts),
  })),
  on(QuoteDetailsActions.loadContactsSuccess, (state, { contacts }) => ({
    ...state,
    contacts: LoadableStateReducerHelper.loadSuccess(contacts),
  })),
  on(QuoteDetailsActions.loadContactsError, (state, { error }) => ({
    ...state,
    contacts: LoadableStateReducerHelper.loadError(state.contacts, error),
  })),

  on(QuoteDetailsActions.loadSingleQuote, (state, { reload }) => ({
    ...state,
    quote: reload ? state.quote : LoadableStateReducerHelper.startLoad(state.quote),
  })),
  on(QuoteDetailsActions.loadSingleQuoteSuccess, (state, { quote }) => ({
    ...state,
    quote: LoadableStateReducerHelper.loadSuccess({ ...state.quote.data, ...quote }),
  })),
  on(QuoteDetailsActions.loadSingleQuoteError, (state, { error }) => ({
    ...state,
    quote: LoadableStateReducerHelper.loadError(state.quote, error),
  })),

  on(QuoteDetailsActions.loadRollOutQuote, (state, { reload }) => ({
    ...state,
    quote: reload ? state.quote : LoadableStateReducerHelper.startLoad(state.quote),
  })),
  on(QuoteDetailsActions.loadRollOutQuoteSuccess, (state, { quote }) => ({
    ...state,
    quote: LoadableStateReducerHelper.loadSuccess({ ...state.quote.data, ...quote }),
  })),
  on(QuoteDetailsActions.loadRollOutQuoteError, (state, { error }) => ({
    ...state,
    quote: LoadableStateReducerHelper.loadError(state.quote, error),
  })),

  on(QuoteDetailsActions.editLineItemsSuccess, (state, { lineItems }) => ({
    ...state,
    quote: LoadableStateReducerHelper.loadSuccess({ ...state.quote.data, lineItems }),
  })),
  on(QuoteDetailsActions.editLineItemsError, (state, { error }) => ({
    ...state,
    values: LoadableStateReducerHelper.loadError(state.quote, error),
  })),
  on(QuoteDetailsActions.saveNoteSuccess, (state, { note }) => ({
    ...state,
    quote: LoadableStateReducerHelper.loadSuccess({ ...state.quote.data, clientNote: note }),
  })),
  on(QuoteDetailsActions.saveNoteError, (state, { error }) => ({
    ...state,
    values: LoadableStateReducerHelper.loadError(state.quote, error),
  })),
  on(QuoteDetailsActions.updateFreightCosts, (state, { freightCosts }) => {
    if (state.quote.data.type === QuoteType.Rollout) {
      return {
        ...state,
        quote: {
          ...state.quote,
          data: {
            ...state.quote.data,
            costs: {
              ...state.quote.data.costs,
              totalIncludingEstimatedTaxAndDuty: state.quote.data.items.reduce(
                (sum, item) => sum + freightCosts[item.id].costs.totalIncludingEstimatedTaxAndDuty,
                0
              ),
            },
            items: state.quote.data.items.map((item) => ({
              ...item,
              freight: freightCosts[item.id].freight,
              costs: {
                ...item.costs,
                ...freightCosts[item.id].costs,
              },
            })),
          },
        },
      };
    }

    return {
      ...state,
      quote: {
        ...state.quote,
        data: {
          ...state.quote.data,
          freight: freightCosts[state.quote.data.id].freight,
          costs: {
            ...state.quote.data.costs,
            ...freightCosts[state.quote.data.id].costs,
          },
        },
      },
    };
  }),
  on(QuoteDetailsActions.rollOutDetailSuccess, (state, { existingRollOutDetail }) => ({
    ...state,
    rollOutDetail: LoadableStateReducerHelper.loadSuccess(existingRollOutDetail),
  }))
);
