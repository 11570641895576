import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { BatteryType } from '../../enums/battery-type.enum';
import { BatteryTableBlockPackage } from '../../types/battery-table-block-package.interface';
import { BatteryTableBlockPayload } from '../../types/battery-table-block-payload.interface';
import { BatteryTableBlockResult } from '../../types/battery-table-block-result.type';
import { BlockComponent } from '../block/block.component';

@Component({
  selector: 'app-battery-table-block',
  templateUrl: './battery-table-block.component.html',
  styleUrls: ['./battery-table-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatteryTableBlockComponent extends BlockComponent<BatteryTableBlockPayload, BatteryTableBlockResult> implements OnInit {
  public packages = new FormArray<BatteryTableBlockPackage>([]);

  public batteryTypes = [BatteryType.ION_PI966, BatteryType.ION_PI967, BatteryType.METAL_PI969, BatteryType.METAL_PI970];

  public getWeight(index: number): number {
    return this.payload.packages[index].weight;
  }

  public getPackageFormGroup(index: number): FormGroup<BatteryTableBlockPackage> {
    return this.packages.at(index) as FormGroup<BatteryTableBlockPackage>;
  }

  public getBatteriesFormGroup(index: number): FormGroup<Record<BatteryType, boolean>> {
    return this.getPackageFormGroup(index).controls.batteries as FormGroup<Record<BatteryType, boolean>>;
  }

  public ngOnInit(): void {
    this.payload.packages.forEach((item, index) => {
      const packageFormGroup = new FormGroup<BatteryTableBlockPackage>({
        id: new FormControl(item.id),
        hasBatteries: new FormControl(item.hasBatteries),
        batteries: new FormGroup<Record<BatteryType, boolean>>(
          {
            [BatteryType.ION_PI966]: new FormControl(false),
            [BatteryType.ION_PI967]: new FormControl(false),
            [BatteryType.METAL_PI969]: new FormControl(false),
            [BatteryType.METAL_PI970]: new FormControl(false),
          },
          (control) => {
            const packageGroup = this.packages.at(index);

            if (!packageGroup) {
              // tslint:disable-next-line: no-null-keyword
              return { required: true };
            }

            if (packageGroup.value.hasBatteries) {
              const isValid = Object.values(control.value).some((value) => value);

              // tslint:disable-next-line: no-null-keyword
              return isValid ? null : { required: true };
            }

            // tslint:disable-next-line: no-null-keyword
            return null;
          }
        ),
      });

      if (!packageFormGroup.value.hasBatteries) {
        packageFormGroup.controls.batteries.disable();
      }

      this.packages.push(packageFormGroup);
    });

    this.register.emit(this.packages);
  }

  public onHasBatteriesChange(value: boolean, index: number): void {
    Object.values(this.getBatteriesFormGroup(index).controls).forEach((batteryFormControl) => {
      if (value) {
        batteryFormControl.enable();
      } else {
        batteryFormControl.setValue(false);
        batteryFormControl.disable();
      }
    });
  }

  public originalOrder(): number {
    return 0;
  }
}
