import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IntercomService } from '../../services/intercom.service';

@Component({
  selector: 'app-intercom-launcher',
  templateUrl: './intercom-launcher.component.html',
  styleUrls: ['./intercom-launcher.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntercomLauncherComponent {
  @Input() public icon: string;
  constructor(public intercom: IntercomService) {}
}
