<div class="d-flex align-items-start">
  <div *ngIf="payload.text" class="text-reduced-regular color-text-secondary text" [innerHTML]="payload.text"></div>
  <div class="file-input-container">
    <app-file-input class="file-input" [multiple]="true" [descriptionEnabled]="false" [formControl]="formControl"></app-file-input>
    <ng-container *ngIf="invalidFiles.length; else showFileSizeHint">
      <div *ngFor="let file of invalidFiles" class="d-flex justify-content-end align-items-center text-micro-regular color-error mt-1">
        <ng-container [ngSwitch]="file.reason">
          <ng-container *ngSwitchCase="'fileSizeLimit'">
            <div
              [innerHTML]="'ERROR.FILE_SIZE_LIMIT_EXCEEDED' | translate : { fileName: file.fileName, maxFileSize: maxFileSizeInMb }"
            ></div>
            <button mat-icon-button type="button" class="text-reduced-bold" (click)="removeInvalidFileClick(file.fileName)">
              <svg-icon key="close"></svg-icon>
            </button>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #showFileSizeHint>
      <div class="text-micro-regular font-italic color-text-secondary text-right mt-1">
        {{ 'TASKS.FILE_SIZE_LIMIT_HINT' | translate : { maxFileSize: maxFileSizeInMb } }}
      </div>
    </ng-template>
    <div
      *ngFor="let file of files"
      class="card-container px-2 py-3 d-flex mt-2 color-secondary align-items-center justify-content-between"
      [class.ml-4]="payload.text"
    >
      <div class="d-flex align-items-center w-100">
        <svg-icon key="doc" class="flex-shrink-0"></svg-icon>
        <div class="ml-2 mr-2 text-tiny-bold flex-grow-1">{{ file.name }}</div>
        <button mat-icon-button type="button" class="text-normal-bold p-0" (click)="onRemoveFileIconClick(file)">
          <svg-icon key="close"></svg-icon>
        </button>
      </div>
    </div>
  </div>
</div>
