import { ComponentType } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, ComponentFactoryResolver, Inject, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { FormControl } from '@ngneat/reactive-forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SelectAddressDialogType } from '../../../../enums/select-address-dialog-type.enum';
import { AddressCardAddressVM } from '../../../../interfaces/address/address.vm';
import { SelectAddress } from '../../../../interfaces/address/select-address.interface';
import { SELECT_ADDRESS_COMPONENT_TYPE } from '../../tokens/select-address-component-type.token';
import { ShipToAddressBlockPayload } from '../../types/ship-to-address-block-payload.interface';
import { BlockComponent } from '../block/block.component';

@Component({
  selector: 'app-ship-to-address-block',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipToAddressBlockComponent
  extends BlockComponent<ShipToAddressBlockPayload, AddressCardAddressVM[]>
  implements OnInit, OnDestroy
{
  public readonly SelectAddressDialogType = SelectAddressDialogType;
  public readonly formControl = new FormControl<AddressCardAddressVM[]>();

  private readonly destroyed$ = new Subject<void>();

  constructor(
    @Inject(SELECT_ADDRESS_COMPONENT_TYPE) private readonly selectAddressComponentType: ComponentType<SelectAddress>,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly translateService: TranslateService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.register.emit(this.formControl);
    this.initSelectAddress();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private initSelectAddress(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.selectAddressComponentType);
    const componentRef = this.viewContainerRef.createComponent<SelectAddress>(componentFactory);
    componentRef.instance.type = SelectAddressDialogType.LocationAddresses;
    componentRef.instance.title = this.translateService.instant('TASKS.SHIP_TO_ADDRESS');
    componentRef.instance.initialAddresses = this.payload.addresses;
    componentRef.instance.initialSelectedAddresses = [];
    componentRef.instance.onCreate = this.payload.onCreate;
    componentRef.instance.onEdit = this.payload.onEdit;
    componentRef.instance.selectedAddressesChange.pipe(takeUntil(this.destroyed$)).subscribe((addresses) => {
      this.formControl.setValue(addresses);
    });
  }
}
