import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@ngneat/reactive-forms';
import { MultiSelectBlockPayload } from '../../types/multi-select-block-payload.interface';
import { BlockComponent } from '../block/block.component';

@Component({
  selector: 'app-multi-select-block',
  templateUrl: './multi-select-block.component.html',
  styleUrls: ['./multi-select-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectBlockComponent extends BlockComponent<MultiSelectBlockPayload, undefined> implements OnInit {
  public readonly formControl = new FormControl();

  public ngOnInit(): void {
    this.register.next(this.formControl);
  }
}
