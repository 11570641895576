import { Injectable, Injector } from '@angular/core';
import { DialogRef } from '../../../dialog/dialog-ref';
import { DialogService } from '../../../dialog/dialog.service';
import { DialogShowMode } from '../../../dialog/enums/dialog-show-mode.enum';
import { AppNavDrawerComponent } from './app-nav-drawer.component';
import { AppNavDrawerVM } from './app-nav-drawer.vm';

@Injectable()
export class AppNavDrawerService {
  constructor(private readonly dialogService: DialogService, private readonly injector: Injector) {}

  public open(appNavDrawerData: AppNavDrawerVM): DialogRef {
    return this.dialogService.open(AppNavDrawerComponent, appNavDrawerData, {
      showMode: DialogShowMode.AppNavDrawer,
      injector: this.injector,
      closeOnNavigation: true,
      closeButton: false,
    });
  }
}
