import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormControl } from '@ngneat/reactive-forms';
import isNil from 'lodash/isNil';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { bytesPerMB } from '../../../../constants/global.constants';
import { DIALOG_DATA } from '../../../dialog/dialog.tokens';
import { DialogData } from '../../../dialog/interfaces/dialog-data.interface';
import { AirWaybillCarrierType } from '../../enums/air-waybill-carrier-type.enum';
import { AirWaybillType } from '../../enums/air-waybill-type.enum';
import { AirWaybillConsignee } from '../../types/air-waybill-consignee.interface';
import { AirWaybillNotifyParty } from '../../types/air-waybill-notify-party.interface';
import { AirWaybillDialogPayload } from './air-waybill-dialog-payload.interface';
import { AirWaybillDialogResult } from './air-waybill-dialog-result.interface';

@Component({
  selector: 'app-air-waybill-dialog',
  templateUrl: './air-waybill-dialog.component.html',
  styleUrls: ['./air-waybill-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AirWaybillDialogComponent implements OnInit, OnDestroy {
  private readonly destroyed$ = new Subject<void>();

  public showSecondStep = false;
  public showFileSizeError = false;
  public invalidFileName: string;

  public readonly AirWaybillCarrierType = AirWaybillCarrierType;
  public readonly AirWaybillType = AirWaybillType;
  public readonly carrierControl = new FormControl<AirWaybillCarrierType>(undefined, [Validators.required]);
  public readonly fileInputControl = new FormControl<File>(undefined, [Validators.required]);
  public readonly maxFileSizeInMb = 4;

  constructor(@Inject(DIALOG_DATA) public readonly data: DialogData<AirWaybillDialogPayload, AirWaybillDialogResult>) {}

  public ngOnInit(): void {
    if (this.data.payload.isInactive) {
      this.fileInputControl.disable();
      this.carrierControl.disable();
    } else {
      this.fileInputControl.valueChanges
        .pipe(
          filter((value) => !isNil(value)),
          takeUntil(this.destroyed$)
        )
        .subscribe((file) => {
          if (file.size >= this.maxFileSizeInMb * bytesPerMB) {
            this.showFileSizeError = true;
            this.invalidFileName = file.name;
            this.fileInputControl.setValue(null, { emitEvent: false });
          } else {
            this.showFileSizeError = false;
            this.fileInputControl.setValue(file, { emitEvent: false });
          }
        });
    }
  }

  public get notifyPartyAddress(): AirWaybillNotifyParty {
    return this.carrierControl.value === AirWaybillCarrierType.Courier
      ? this.data.payload.courierNotifyParty
      : this.data.payload.freightForwarderNotifyParty;
  }

  public get consigneeAddress(): AirWaybillConsignee {
    return this.carrierControl.value === AirWaybillCarrierType.Courier
      ? this.data.payload.courierConsignee
      : this.data.payload.freightForwarderConsignee;
  }

  public createAddressLine(address: AirWaybillNotifyParty | AirWaybillConsignee): string {
    return [address.city, address.state, address.country, address.postalCode].filter((value) => !isNil(value)).join(', ');
  }

  public onMessageClick(): void {
    this.data.payload.onMsgClickHandler();
  }

  public onNextClick(): void {
    this.showSecondStep = true;
  }

  public onSubmitClick(): void {
    this.data.dialogRef.close({
      carrier: this.carrierControl.value,
      file: this.fileInputControl.value,
    });
  }

  public onCancelClick(): void {
    this.data.dialogRef.close();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public removeInvalidFileClick(): void {
    this.showFileSizeError = false;
  }
}
