import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import isNil from 'lodash/isNil';
import { AddressCardAddressVM as AddressVM } from '../../../../interfaces/address/address.vm';
import { CommonAddressConfig } from '../../interfaces/common-address-config.interface';
import { COMMON_ADDRESS_CONFIG } from '../../tokens/common-address-config.token';

@Component({
  selector: 'app-address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressCardComponent {
  @Input() public address: AddressVM;
  @Input() public isSelected = false;
  @Input() public isSelectable = true;
  @Input() public canEdit = true;
  @Input() public canDelete = false;
  @Input() public isMultipleChoiceEnabled = false;
  @Input() public layout: 'row' | 'column' = 'column';
  @Output() public selectAddressWithCheckbox = new EventEmitter<AddressVM>();
  @Output() public selectAddressWithRadio = new EventEmitter<AddressVM>();
  @Output() public editAddressCard = new EventEmitter<AddressVM>();
  @Output() public deleteAddressCard = new EventEmitter<AddressVM>();

  constructor(@Inject(COMMON_ADDRESS_CONFIG) private readonly commonAddressConfig: CommonAddressConfig) {}

  public get showPickupPreference(): boolean {
    return this.commonAddressConfig.hasPickupPreference && !isNil(this.address.pickupPreference);
  }

  public onCheckboxChange(): void {
    this.selectAddressWithCheckbox.emit(this.address);
  }

  public onRadioChange(): void {
    this.selectAddressWithRadio.emit(this.address);
  }

  public onEditClick(): void {
    this.editAddressCard.emit(this.address);
  }

  public onDeleteClick(): void {
    this.deleteAddressCard.emit();
  }
}
