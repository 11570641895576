import { Pipe, PipeTransform } from '@angular/core';
import { ProfilePictureService } from '../services/profile-picture.service';

@Pipe({ name: 'profilePicture' })
export class ProfilePicturePipe implements PipeTransform {
  constructor(private readonly profilePictureService: ProfilePictureService) {}

  public transform(relativePath?: string): string {
    return this.profilePictureService.getProfilePictureWithFallback(relativePath);
  }
}
