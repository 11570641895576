/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MyTeamPageContactVM } from '@global/modules/common-profile/interfaces/my-team-page-contact.vm';
import { DIALOG_DATA } from '@global/modules/dialog/dialog.tokens';
import { DialogData } from '@global/modules/dialog/interfaces/dialog-data.interface';
import { CaseMessageDialogService } from '@global/modules/message-dialog/services/case-message-dialog.service';
import { NewQuoteFinalCostsPageService } from '@modules/new-quote/pages/new-quote-final-costs-page/new-quote-final-costs-page.service';
import { Observable } from 'rxjs';
import { QuoteDetailsPageService } from '../../../quote-list/pages/quote-details-page/quote-details-page.service';

@Component({
  selector: 'app-request-approval-dialog',
  templateUrl: './request-approval-dialog.component.html',
  styleUrls: ['./request-approval-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NewQuoteFinalCostsPageService],
})
export class RequestApprovalDialogComponent implements OnInit {
  // eslint-disable-next-line rxjs/finnish
  public filteredOptions: Observable<string[]>;
  public contactList: any;
  public contactDetail: any;
  public quoteDetail: any;
  public vm$: any;

  // requestApprovalContact = new FormControl(undefined, [Validators.required]);

  constructor(
    @Inject(DIALOG_DATA) private readonly data: DialogData<MyTeamPageContactVM>,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    private readonly CaseMessageDialogService: CaseMessageDialogService,
    public quoteDetailsPageService: QuoteDetailsPageService,
    public newQuoteFinalCostsPageService: NewQuoteFinalCostsPageService,
    public router: Router
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {
    this.vm$ = this.router.url.includes('quotes') ? this.quoteDetailsPageService.getVM$() : this.newQuoteFinalCostsPageService.getVM$();

    this.vm$.subscribe((value: any) => {
      this.quoteDetail = value;
    });

    this.contactList = this.data.payload;
    this.contactList = this.contactList.filter((y: any) => y.ncpPermission?.includes('Quote Acceptance'));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  contactData(event: any) {
    this.contactDetail = this.contactList ? this.contactList.find((x: any) => x.contactId === event.target.value) : '';
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  createRequestForApproval() {
    const messageDetail = {
      id: this.contactDetail.userId,
      contactId: this.contactDetail.contactId,
      name: this.contactDetail.name,
      profilePicture: this.contactDetail.picture,
    };

    const shipment = {
      id: this.quoteDetail?.quote && this.quoteDetail?.quote?.id ? this.quoteDetail?.quote?.id : this.quoteDetail.shipmentOrderIds[0],
      reference:
        this.quoteDetail?.quote && this.quoteDetail?.quote?.projectReference1
          ? this.quoteDetail?.quote?.projectReference1
          : this.quoteDetail.quoteReference,
      title: this.quoteDetail?.quote?.name,
    };

    this.CaseMessageDialogService.open({
      messageTo: messageDetail,
      shipment,
      subject: 'Quote Approval Request',
      // preLoadedMessage: 'Please can you approve this quote at your earliest convenience ?',
    });
  }
}
