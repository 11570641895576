import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconsModule } from '../svg-icons/svg-icons.module';
import { AlertBarComponent } from './components/alert-bar/alert-bar.component';
import { AnnouncmentBarComponent } from './components/announcment-bar/announcment-bar.component';
import { MaintenanceDialogComponent } from './components/maintenance-dialog/maintenance-dialog.component';

@NgModule({
  declarations: [AnnouncmentBarComponent, MaintenanceDialogComponent, AlertBarComponent],
  imports: [CommonModule, TranslateModule, SvgIconsModule, MatButtonModule],
  exports: [AnnouncmentBarComponent, AlertBarComponent],
})
export class AnnouncmentModule {}
