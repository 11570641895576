import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private readonly authService: AuthService) {}

  public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const credentials = this.authService.getCredentials();

    if (!credentials) {
      // tslint:disable-next-line:no-console
      // eslint-disable-next-line no-console
      console.log('[AUTH-GUARD] no credentials found, blocking route');

      this.authService.login();
      return of(false);
    }

    return this.authService.loadProfile$();
  }
}
