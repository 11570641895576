import { Injectable, NgZone } from '@angular/core';
import { fromEvent, interval, merge } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';
import { DialogService } from '../../dialog/dialog.service';
import { SessionExpiredDialogComponent } from '../components/session-expired-dialog/session-expired-dialog.component';

/**
 * This service tries to refresh the user in the background to make sure
 * the login session in the background stays active. This is needed because of the
 * strange nature of the backend maintaining two different access tokens.
 * Each call will extend the Salesforce token in the backend, and replace the
 * other kind of acess token with a new one, which lasts longer.
 */

// tslint:disable-next-line: no-magic-numbers
const SESSION_REFRESH_INTERVAL = 30 * 60 * 1000;

@Injectable()
export class SessionService {
  constructor(private readonly ngZone: NgZone, private readonly authService: AuthService, private readonly dialogService: DialogService) {
    this.ngZone
      .runOutsideAngular(() =>
        this.authService.getUser$().pipe(
          switchMap(() =>
            merge(fromEvent(document, 'visibilitychange').pipe(filter(() => !document.hidden)), interval(SESSION_REFRESH_INTERVAL))
          ),
          switchMap(() => this.authService.updateProfile$()),
          catchError((error) => {
            // eslint-disable-next-line no-console
            console.error('Failed to load user profile', error);

            return this.ngZone.run(() =>
              this.dialogService
                .open(SessionExpiredDialogComponent)
                .afterClosed$()
                .pipe(tap(() => this.authService.logout()))
            );
          })
        )
      )
      .subscribe();
  }
}
