<mat-chip-list [disabled]="isDisabled" #chipList>
  <mat-chip class="chip" [class.error]="hasError" *ngFor="let option of selectedOptions$ | async" (removed)="onItemRemoved(option.value)">
    <ng-container [ngTemplateOutlet]="option.templateRef"></ng-container>
    <svg-icon key="close" matChipRemove></svg-icon>
  </mat-chip>
  <input
    #chipInput
    [formControl]="inputFormControl"
    [matAutocomplete]="autoComplete"
    [matChipInputFor]="chipList"
    (focus)="onFocus()"
    (blur)="onBlur()"
    data-hj-allow
  />
  <svg-icon key="arrow-down" class="arrow-icon" [class.disabled]="isDisabled"></svg-icon>
</mat-chip-list>
<mat-autocomplete #autoComplete="matAutocomplete" (optionSelected)="onOptionSelect($event)" class="app-multi-autocomplete">
  <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option.value">
    <ng-container [ngTemplateOutlet]="option.templateRef"></ng-container>
  </mat-option>
</mat-autocomplete>
