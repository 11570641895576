import { Pipe, PipeTransform } from '@angular/core';
import isEmpty from 'lodash/isEmpty';

@Pipe({
  name: 'messageThreadParticipants',
})
export class MessageThreadParticipantsPipe implements PipeTransform {
  public transform(participants: { firstName: string; lastName: string }[]): string {
    if (participants.length === 0) {
      return '';
    }

    let result = this.formatName(participants[0]);
    if (participants.length > 1) {
      result += `, ${this.formatName(participants[1])}`;
    }
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if (participants.length > 2) {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      result += ` + ${participants.length - 2}`;
    }

    return result;
  }

  private formatName(participant: { firstName: string; lastName: string }): string {
    if (isEmpty(participant.lastName)) {
      return participant.firstName;
    }

    return `${participant.firstName} ${participant.lastName.slice(0, 1)}.`;
  }
}
