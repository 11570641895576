import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input } from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import { SvgIconRegistry } from '@ngneat/svg-icon';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'svg-icon',
  template: '',
  styleUrls: ['./svg-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconComponent {
  @Input()
  public set key(name: string) {
    if (this.registry.get(name)) {
      this.element.setAttribute('aria-label', `${name}-icon`);
      this.element.classList.remove(`svg-icon-${this.lastKey}`);
      this.lastKey = name;
      this.element.classList.add(`svg-icon-${name}`);
      this.element.innerHTML = this.registry.get(name);
    }
  }

  @Input() public set width(value: number | string) {
    this.element.style.width = coerceCssPixelValue(value);
  }

  @Input() public set height(value: number | string) {
    this.element.style.height = coerceCssPixelValue(value);
  }

  @Input()
  public set fontSize(value: number | string) {
    this.element.style.fontSize = coerceCssPixelValue(value);
  }

  @Input()
  public set color(color: string) {
    this.element.style.color = color;
  }

  @HostBinding('attr.role') public get roleAttribute(): string {
    return 'img';
  }

  @HostBinding('attr.aria-hidden') public get ariaHiddenAttribute(): string {
    return 'true';
  }

  private lastKey!: string;

  constructor(private readonly host: ElementRef, private readonly registry: SvgIconRegistry) {}

  public get element(): HTMLElement {
    return this.host.nativeElement;
  }
}

const coerceCssPixelValue = (value: any): string => {
  if (value == null) {
    return '';
  }

  return typeof value === 'string' ? value : `${value}px`;
};
