import { Attachment } from '@tecex-api/data';
import isNil from 'lodash/isNil';
import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';

export class FileHelper {
  private static readonly dataUrlRegexp: RegExp = new RegExp(
    `^\\s*data:([a-z]+/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\\-._~:@/?%\\s]*)\\s*$`,
    'i'
  );

  /**
   * Triggers a file download
   *
   * @param blob
   * @param fileName
   */
  public static downloadFile(blob: Blob, fileName: string): void {
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = fileName;
    document.body.append(downloadLink);
    downloadLink.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    downloadLink.remove();
    window.URL.revokeObjectURL(downloadLink.href);
  }

  /**
   * Triggers a file download using fileDownload object sent by the backend
   *
   * @param fileDownload
   */
  public static downloadDataFile(attachment: Attachment): void {
    // application/octet-stream here basically means that we have no idea what exactly the file is
    const blob = FileHelper.createFileFromFileData(
      attachment.AttachmentName,
      attachment.AttachmentBody,
      attachment.AttachmentType || 'application/octet-stream'
    );
    FileHelper.downloadFile(blob, attachment.AttachmentName);
  }

  public static createFileFromDataURL(dataURL: string): File | undefined {
    const dataUrlMatch: RegExpMatchArray | null = dataURL.match(FileHelper.dataUrlRegexp);
    if (isNil(dataURL)) {
      return undefined;
    }
    // eslint-disable-next-line unicorn/no-unreadable-array-destructuring
    const [, mimeType, , , data] = dataUrlMatch;
    if (isNil(data)) {
      return undefined;
    }
    return FileHelper.createFileFromFileData('', data, mimeType);
  }

  public static createFileFromFileData(fileName: string, fileData: string, mimeType: string = 'application/octet-stream'): File {
    const decodedFileData = atob(fileData);
    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(decodedFileData.length);
    // create a view into the buffer
    const ia = new Uint8Array(ab);
    // set the bytes of the buffer to the correct values
    for (let i = 0; i < decodedFileData.length; i++) {
      ia[i] = decodedFileData.codePointAt(i);
    }
    return new File([ab], fileName, { type: mimeType });
  }

  public static readFileAsText$(file: File): Observable<string> {
    return FileHelper.readFile$<string>((fileReader: FileReader) => fileReader.readAsText(file));
  }

  public static readFileAsDataURL$(file: File): Observable<string> {
    return FileHelper.readFile$<string>((fileReader: FileReader) => fileReader.readAsDataURL(file));
  }

  public static readFileAsBase64$(file: File): Observable<string> {
    return FileHelper.readFileAsDataURL$(file).pipe(map((dataUrl) => dataUrl.replace(/^data:(.*,)?/, '')));
  }

  private static readFile$<T extends string | ArrayBuffer>(readAs: (fileReader: FileReader) => void): Observable<T> {
    return new Observable((observer: Observer<T>): void => {
      const fileReader: FileReader = new FileReader();
      fileReader.addEventListener('load', (): void => {
        observer.next(fileReader.result as T);
        observer.complete();
      });
      fileReader.addEventListener('error', (): void => {
        observer.error(fileReader.error);
        observer.complete();
      });
      fileReader.addEventListener('abort', (): void => observer.complete());
      readAs(fileReader);
    });
  }
}
