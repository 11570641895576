import { Injectable } from '@angular/core';
import { MixpanelEvent } from '@global/enums/mixpanel-event.enum';
import { MyTeamPageContactVM } from '@global/modules/common-profile/interfaces/my-team-page-contact.vm';
import { DialogService } from '@global/modules/dialog/dialog.service';
import { DialogShowMode } from '@global/modules/dialog/enums/dialog-show-mode.enum';
import { AuthService } from '@global/services/auth.service';
import { MixpanelService } from '@global/services/mixpanel.service';
import * as fromCountry from '@modules/country/reducers';
import { QuoteDetailsQuote } from '@modules/quote-list/interfaces/quote.interface';
import { RequestApprovalDialogComponent } from '@modules/quote/components/request-approval-dialog/request-approval-dialog.component';
import { Store } from '@ngrx/store';
import * as fromCountryValidation from '@shared/modules/country-validation/reducers';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import * as fromQuoteDetails from '../../reducers';
import { QuoteDetailsPageVM } from './quote-details-page.vm';

@Injectable()
export class QuoteDetailsPageService {
  constructor(
    private readonly store$: Store<fromQuoteDetails.AppState & fromCountry.AppState & fromCountryValidation.AppState>,
    private readonly authService: AuthService,
    private readonly dialogService: DialogService,
    private readonly mixpanelService: MixpanelService
  ) {}

  public getVM$(): Observable<QuoteDetailsPageVM> {
    return combineLatest([
      this.store$.select(fromQuoteDetails.selectQuoteDetailsLoading),
      this.store$.select(fromQuoteDetails.selectQuoteDetailsQuote),
      this.store$.select(fromQuoteDetails.selectQuoteDetailsContacts),
      this.store$.select(fromQuoteDetails.selectQuoteOwner),
      this.authService.getUser$(),
    ]).pipe(
      map(([isLoading, quote, contacts, owner, user]) => ({
        isLoading,
        quote: quote as QuoteDetailsQuote,
        contacts: contacts.map((contact) => ({ value: contact.id, viewValue: contact.name })),
        owner,
        user,
      })),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public open(dialogData?: MyTeamPageContactVM) {
    return this.dialogService.open<MyTeamPageContactVM>(RequestApprovalDialogComponent, dialogData, { showMode: DialogShowMode.Default });
  }

  public acceptLater(): void {
    this.mixpanelService.track(MixpanelEvent.UserAction, {
      button_name: 'Accept later',
      screen_name: '/quotes/shipment-order',
    });
  }
}
