import { SortingDirection } from '@global/enums/sorting-direction.enum';
import { sortByDate } from '@global/helpers/sort-by-date.helper';
import { LinkedQuoteVM } from '@modules/quote-list/interfaces/linked-quote.vm';
import { QuoteTableSorting } from '@modules/quote-list/interfaces/quote-table-sorting.interface';
import { QuoteType } from '@shared/enums/quote-type.enum';
import { QuoteListSortableColumns } from '../enums/quote-list-sortable-columns.enum';
import { RolloutQuoteVM } from '../interfaces/rollout-quote.vm';
import { TecexQuoteVM } from '../interfaces/tecex-quote.vm';

export const handleQuoteSorting = (
  quotes: (TecexQuoteVM | RolloutQuoteVM | LinkedQuoteVM)[],
  quoteTableSorting?: QuoteTableSorting
): (TecexQuoteVM | RolloutQuoteVM | LinkedQuoteVM)[] => {
  const { col, direction } = quoteTableSorting || {};
  if (col && direction !== undefined) {
    switch (quoteTableSorting.col) {
      case QuoteListSortableColumns.ExpiryDate:
        return quotes.sort((a, b) => sortByDate(b.expiryDate, a.expiryDate, quoteTableSorting.direction === SortingDirection.Ascending));
      case QuoteListSortableColumns.FixTotalCost:
        return quotes.sort((a, b) => sortByCost(a, b, quoteTableSorting.direction));
      case QuoteListSortableColumns.TimelineTotal:
        return quotes.sort((a, b) => sortByTimelineTotal(a, b, quoteTableSorting.direction === SortingDirection.Descending));
    }
  } else {
    return quotes.sort((a, b) => sortByDate(b.createdDate, a.createdDate));
  }
};

const sortByTimelineTotal = (
  quoteA: TecexQuoteVM | RolloutQuoteVM | LinkedQuoteVM,
  quoteB: TecexQuoteVM | RolloutQuoteVM | LinkedQuoteVM,
  descending: boolean = false
): number => {
  const quoteATimelineTotal = getTimelineTotalNumber(quoteA);
  const quoteBTimelineTotal = getTimelineTotalNumber(quoteB);

  return descending ? quoteBTimelineTotal - quoteATimelineTotal : quoteATimelineTotal - quoteBTimelineTotal;
};

const getTimelineTotalNumber = (quote: TecexQuoteVM | RolloutQuoteVM | LinkedQuoteVM) => {
  if (quote.type === QuoteType.Rollout && (quote as RolloutQuoteVM).isCostCalculating) {
    return 0;
  }
  const match = quote.timelineTotal?.match(/(?:(?:(?:\d+-(\d+))|(\d+)) days)|(?:(\d+) business days)/);
  if (!match) {
    return 0;
  }
  return +match[1] || +match[2] || +match[3];
};

const sortByCost = (
  quoteA: TecexQuoteVM | RolloutQuoteVM | LinkedQuoteVM,
  quoteB: TecexQuoteVM | RolloutQuoteVM | LinkedQuoteVM,
  direction: SortingDirection
): number => {
  const quoteACost = getCost(quoteA, direction);
  const quoteBCost = getCost(quoteB, direction);

  return direction === SortingDirection.Descending ? quoteBCost - quoteACost : quoteACost - quoteBCost;
};

const getCost = (quote: TecexQuoteVM | RolloutQuoteVM | LinkedQuoteVM, direction: SortingDirection): number => {
  if (quote.type === QuoteType.Rollout && (quote as RolloutQuoteVM).isCostCalculating) {
    return 0;
  }
  if (quote.estimatedTotalCost.min === quote.estimatedTotalCost.max) {
    return quote.fixTotalCost;
  }
  return direction === SortingDirection.Ascending ? quote.estimatedTotalCost.min : quote.estimatedTotalCost.max;
};
