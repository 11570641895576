/* eslint-disable unicorn/no-useless-undefined */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { EMPTY, from, Observable, of } from 'rxjs';
import { catchError, first, map, mapTo, switchMap } from 'rxjs/operators';
import { MessageThreadType } from '../../../enums/message-thread-type.enum';
import { createMessageOrigin } from '../../../helpers/create-message-origin.helper';
import { MessagePayload } from '../../../interfaces/messages/message-payload.interface';
import { MessageThreadDetails } from '../../../interfaces/messages/message-thread-details.interface';
import { Participant } from '../../../interfaces/participant.interface';
import { TeamMemberLists } from '../../../interfaces/team-member-lists.interface';
import { ErrorNotificationService } from '../../../services/error-notification.service';
import { ProfilePictureService } from '../../../services/profile-picture.service';
import { TeamMemberService } from '../../../services/team-member.service';
import * as commonMessageAction from '../../message-thread/actions/common-messages.actions';
import { TeamMemberListType } from '../../message-thread/enums/team-member-list-type.enum';
import * as commonMessageReducer from '../../message-thread/reducers';
import { CaseMessageService } from '../../message-thread/services/case-message.service';
import { MessagingAWSService } from '../../message-thread/services/messaging-aws.service';
import { ChannelAndMessage, SendbirdService } from '../../message-thread/services/sendbird-message.service';
import { MessageDialogPayload } from '../types/message-dialog-payload.interface';
import { MessageDialogDataService } from './message-dialog-data.service';

@Injectable()
export class CaseMessageDialogDataService extends MessageDialogDataService {
  constructor(
    private readonly profilePictureService: ProfilePictureService,
    private readonly errorNotificationService: ErrorNotificationService,
    private readonly caseMessageService: CaseMessageService,
    private readonly teamMemberService: TeamMemberService,
    private readonly sendbirdService: SendbirdService,
    private readonly messagingAWSService: MessagingAWSService,
    public router: Router,
    private readonly store$: Store<commonMessageReducer.AppState>
  ) {
    super();
  }

  public getInitialMessageThread$(payload: MessageDialogPayload): Observable<MessageThreadDetails> {
    if (!isNil(payload.messageTo)) {
      return of({
        type: MessageThreadType.Case,
        messages: [],
        participants: [{ id: payload.messageTo.id, firstName: payload.messageTo.name, lastName: '' }],
        photoUrl: this.profilePictureService.getProfilePictureWithFallback(payload.messageTo.profilePicture),
        subject: payload.subject,
        shipment: payload.shipment,
        origin: isEmpty(payload.origin) ? undefined : createMessageOrigin(payload.origin),
        source: payload.source,
      });
    }
    return this.teamMemberService.getDefaultTeamMember$(payload.shipment?.id, payload.teamMemberListType).pipe(
      first(),
      map(
        (teamMember): MessageThreadDetails => ({
          type: MessageThreadType.Case,
          messages: [],
          participants: [{ id: teamMember.id, firstName: teamMember.name, lastName: '' }],
          photoUrl: this.profilePictureService.getProfilePictureWithFallback(teamMember.profilePicture),
          subject: payload.subject,
          shipment: payload.shipment,
          source: payload.source,
        })
      )
    );
  }

  public getMessageThread$(id: string, initial = false): Observable<MessageThreadDetails> {
    return this.caseMessageService.getThread$(id, initial).pipe(
      catchError((error) => {
        this.errorNotificationService.notifyAboutError(error, 'ERROR.FAILED_TO_LOAD_MESSAGE_THREAD');
        return EMPTY;
      })
    );
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public getMessageThreadSendbird$(id: string, payloadData: MessageDialogPayload, initial = false): Observable<ChannelAndMessage> {
    return id && id.split('-').length === 1
      ? this.messagingAWSService.getListOfChannels$('', id).pipe(
          switchMap((channels) => {
            if (!channels || channels.length === 0) {
              return of({ channel: null, messages: null });
            }
            const sendbirdId = channels[0].id;
            if (sendbirdId) {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              (this.router.url.includes('dashboard') && !this.router.url.includes('task')) ||
              this.router.url.includes('basics') ||
              (this.router.url.includes('invoices') && !payloadData.id && !payloadData.invoiceName && !payloadData.shipment?.id) ||
              (this.router.url.includes('messages') && payloadData && payloadData.nextButtonClickFromMessageFlag)
                ? this.store$.dispatch(commonMessageAction.loadAccountParticipants())
                : this.store$.dispatch(
                    commonMessageAction.loadShipmentOrderParticipants({
                      SOID: payloadData && payloadData.shipment?.id ? payloadData.shipment?.id : channels[0].id,
                    })
                  );

              return from(this.sendbirdService.getMessagesFromChannelUrlPromise(sendbirdId)).pipe(
                catchError((error) => {
                  return EMPTY;
                })
              );
            } else {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              (this.router.url.includes('dashboard') && !this.router.url.includes('task')) ||
              this.router.url.includes('basics') ||
              (this.router.url.includes('invoices') && !payloadData.id && !payloadData.invoiceName && !payloadData.shipment?.id) ||
              (this.router.url.includes('messages') && payloadData && payloadData.nextButtonClickFromMessageFlag)
                ? this.store$.dispatch(commonMessageAction.loadAccountParticipants())
                : this.store$.dispatch(
                    commonMessageAction.loadShipmentOrderParticipants({
                      SOID: payloadData && payloadData.shipment?.id ? payloadData.shipment?.id : channels[0].id,
                    })
                  );
              return EMPTY;
            }
          })
        )
      : // eslint-disable-next-line unicorn/no-nested-ternary
      id && id.split('-').length === 2
      ? from(this.sendbirdService.getMessagesFromChannelUrlPromise(id)).pipe(
          catchError((error) => {
            return EMPTY;
          })
        )
      : this.listOfParticipantsFromAccountOrShipment$(payloadData, null);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public listOfParticipantsFromAccountOrShipment$(payloadData: any, channels: any): Observable<ChannelAndMessage> {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    (this.router.url.includes('dashboard') && !this.router.url.includes('task')) ||
    this.router.url.includes('basics') ||
    (this.router.url.includes('invoices') && !payloadData.id && !payloadData.invoiceName && !payloadData.shipment?.id) ||
    (this.router.url.includes('messages') && payloadData && payloadData.nextButtonClickFromMessageFlag) ||
    this.router.url.includes('product-catalog')
      ? this.store$.dispatch(commonMessageAction.loadAccountParticipants())
      : this.store$.dispatch(
          commonMessageAction.loadShipmentOrderParticipants({
            SOID: payloadData && payloadData.shipment?.id ? payloadData.shipment?.id : channels[0].id,
            SOAccountId: payloadData && payloadData.shipment?.accountIdShipmentWise ? payloadData.shipment?.accountIdShipmentWise : '',
          })
        );
    return of({ channel: null, messages: null });
  }

  public pollMessageThread$(messageThread: MessageThreadDetails): Observable<MessageThreadDetails> {
    return this.caseMessageService.pollThread$(messageThread).pipe(
      catchError((error) => {
        this.errorNotificationService.notifyAboutError(error, 'ERROR.FAILED_TO_LOAD_MESSAGES');
        return EMPTY;
      })
    );
  }

  public createMessageThread$(subject: string, messageThread: MessageThreadDetails): Observable<MessageThreadDetails> {
    return this.caseMessageService.createThread$({ ...messageThread, subject }).pipe(
      catchError((error) => {
        this.errorNotificationService.notifyAboutError(error, 'ERROR.FAILED_TO_CREATE_CASE');
        return EMPTY;
      })
    );
  }

  public getTeamMembers$(shipmentOrderId?: string, teamMemberListType?: TeamMemberListType): Observable<TeamMemberLists> {
    return this.caseMessageService.getTeamMembers$(shipmentOrderId, teamMemberListType).pipe(
      catchError((error) => {
        this.errorNotificationService.notifyAboutError(error, 'ERROR.FAILED_TO_LOAD_TEAM_MEMBERS');
        return of({
          taggable: [],
          mentionable: [],
        });
      })
    );
  }

  public sendMessage$(messageThreadDetails: MessageThreadDetails, teamMembers: Participant[], message: MessagePayload): Observable<void> {
    return this.caseMessageService.sendMessage$(messageThreadDetails, teamMembers, message).pipe(
      catchError((error) => {
        this.errorNotificationService.notifyAboutError(error, 'ERROR.FAILED_TO_SEND_MESSAGE');
        return EMPTY;
      })
    );
  }

  public addParticipant$(messageThreadDetails: MessageThreadDetails, participant: Participant): Observable<void> {
    return this.caseMessageService.addParticipants$(messageThreadDetails.id, [participant]).pipe(
      mapTo(undefined),
      catchError((error) => {
        this.errorNotificationService.notifyAboutError(error, 'ERROR.FAILED_TO_ADD_PARTICIPANT');
        return EMPTY;
      })
    );
  }
}
