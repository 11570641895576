import { DimensionWithNull, ShortDimension } from '@tecex-api/data';
import { LengthUnit } from '../enums/length-unit.enum';

export const mapLengthUnit = (dimensionUnit: DimensionWithNull): LengthUnit => {
  switch (dimensionUnit) {
    case DimensionWithNull.CM:
      return LengthUnit.Cm;
    case DimensionWithNull.INCH:
      return LengthUnit.Inch;
    default:
      return LengthUnit.Cm;
  }
};

export const mapShortLengthUnit = (dimensionUnit: ShortDimension): LengthUnit => {
  switch (dimensionUnit) {
    case ShortDimension.CMS:
      return LengthUnit.Cm;
    case ShortDimension.INS:
      return LengthUnit.Inch;
    default:
      return LengthUnit.Cm;
  }
};
