import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { StateIndicatorCircleState } from '../state-indicator-circle/state.enum';
import { MessageButtonUserVM as UserVM } from './user.vm';

@Component({
  selector: 'app-message-button',
  templateUrl: './message-button.component.html',
  styleUrls: ['./message-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageButtonComponent {
  @Input() public user: UserVM;
  @Output() public buttonClick = new EventEmitter();

  public state = StateIndicatorCircleState;

  public onClick(): void {
    this.buttonClick.emit();
  }
}
