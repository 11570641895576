import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalModule } from '../../global.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { FormControlModule } from '../form-control/form-control.module';
import { GoogleMapAutocompleteModule } from '../google-map-autocomplete/google-map-autocomplete.module';
import { LoadingIndicatorModule } from '../loading-indicator/loading-indicator.module';
import { SelectModule } from '../select/select.module';
import { SvgIconsModule } from '../svg-icons/svg-icons.module';
import { AddressCardComponent } from './components/address-card/address-card.component';
import { EditAddressDialogComponent } from './components/edit-address-dialog/edit-address-dialog.component';
import { EditAddressDialogService } from './components/edit-address-dialog/edit-address-dialog.service';
import { CommonAddressConfig } from './interfaces/common-address-config.interface';
import { PickupPreferenceTranslationKeyPipe } from './pipes/pickup-preference-translation-key.pipe';
import { COMMON_ADDRESS_CONFIG } from './tokens/common-address-config.token';

@NgModule({
  imports: [
    GlobalModule,
    FormsModule,
    TranslateModule,
    MatCheckboxModule,
    MatRadioModule,
    SvgIconsModule,
    MatButtonModule,
    FormControlModule,
    GoogleMapAutocompleteModule,
    ReactiveFormsModule,
    DropdownModule,
    SelectModule,
    LoadingIndicatorModule,
  ],
  declarations: [PickupPreferenceTranslationKeyPipe, AddressCardComponent, EditAddressDialogComponent],
  exports: [PickupPreferenceTranslationKeyPipe, AddressCardComponent, EditAddressDialogComponent],
})
export class CommonAddressModule {
  public static forRoot(config: CommonAddressConfig): ModuleWithProviders<CommonAddressModule> {
    return { ngModule: CommonAddressModule, providers: [EditAddressDialogService, { provide: COMMON_ADDRESS_CONFIG, useValue: config }] };
  }
}
