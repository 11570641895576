import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import isNil from 'lodash/isNil';
import { ToastMessageRef } from '../modules/toast-message/toast-message-ref';
import { ToastMessageType } from '../modules/toast-message/toast-message-type.enum';
import { ToastMessageService } from '../modules/toast-message/toast-message.service';

@Injectable({ providedIn: 'root' })
export class ErrorNotificationService {
  constructor(private readonly toastMessageService: ToastMessageService, private readonly translateService: TranslateService) {}

  /**
   * Shows toast notification about error, resolves a promise after the toast was closed
   *
   * @param errorTranslationKey - translation key to load error message for
   */
  public async showErrorNotification(errorTranslationKey: string): Promise<void> {
    const error = await this.translateService.get(errorTranslationKey).toPromise();
    await this.displayErrorToast(error, ToastMessageType.Error);
  }

  /**
   * Shows toast notification about warning, resolves a promise after the toast was closed
   *
   * @param errorTranslationKey - translation key to load error message for
   */
  public async showWarningNotification(errorTranslationKey: string): Promise<void> {
    const error = await this.translateService.get(errorTranslationKey).toPromise();
    await this.displayErrorToast(error, ToastMessageType.Warning);
  }

  /**
   * Shows toast notification about error with a clickable action button
   *
   * @param errorTranslationKey - translation key to load error message for
   * @param callToActionLabel - translation key for CTA label
   */
  public showErrorNotificationWithAction(errorTranslationKey: string, callToActionLabel: string): ToastMessageRef {
    const error = this.translateService.instant(errorTranslationKey);
    const actionLabel = this.translateService.instant(callToActionLabel);
    return this.toastMessageService.open(error, { type: ToastMessageType.Error }, actionLabel);
  }

  /**
   * Notifies user about an error by a putting an error message into console
   * and showing a translated message on the UI
   * resolves a promise when message is closed
   *
   * @param error - object of the error that was thrown
   * @param errorTranslationKey - translation key to load error message for
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async notifyAboutError(error: any, toastMessageTranslationKey: string): Promise<void> {
    // eslint-disable-next-line no-console
    console.error(error);
    const beErrorMessage = error?.error?.Error?.Message;
    await (isNil(beErrorMessage)
      ? this.showErrorNotification(toastMessageTranslationKey)
      : this.displayErrorToast(beErrorMessage, ToastMessageType.Error));
  }

  private async displayErrorToast(error: string, type: ToastMessageType): Promise<void> {
    this.toastMessageService.open(error, { type }).afterClosed$().toPromise();
  }
}
