import { Directive, HostBinding, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormControlService } from '../components/form-control/form-control.service';

@Directive({
  selector: '[appFormControlInput]',
})
export class FormControlInputDirective {
  // tslint:disable-next-line:variable-name
  private readonly _isFocused$ = new BehaviorSubject(false);
  public readonly isFocused$ = this._isFocused$.asObservable();

  constructor(private readonly formControlService: FormControlService) {}

  @HostBinding('attr.id') public id = this.formControlService.id;

  @HostBinding('class.has-error') public get hasErrorClass(): boolean {
    return this.formControlService.hasError;
  }

  @HostListener('focus') public onFocus(): void {
    this._isFocused$.next(true);
  }

  @HostListener('blur') public onBlur(): void {
    this._isFocused$.next(false);
  }
}
