import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastMessageType } from '../modules/toast-message/toast-message-type.enum';
import { ToastMessageService } from '../modules/toast-message/toast-message.service';

@Injectable({
  providedIn: 'root',
})
export class FileExtensionService {
  constructor(private readonly translateService: TranslateService, private readonly toastMessageService: ToastMessageService) {}

  public handleDisallowedExtensions(
    value: File[] | File,
    allowedExtensions = [
      'png',
      'jpg',
      'jpeg',
      'gif',
      'pdf',
      'docx',
      'doc',
      'xls',
      'xlsx',
      'csv',
      'txt',
      'rtf',
      'html',
      'zip',
      'mp3',
      'wma',
      'mpg',
    ]
  ): File[] {
    if (Array.isArray(value)) {
      const filesWithDisallowedExtension = value.filter((file) => !allowedExtensions.includes(this.getExtension(file)));

      if (filesWithDisallowedExtension.length === 0) {
        return value;
      }

      this.toastMessageService.open(
        this.translateService.instant('ERROR.DISALLOWED_FILE_EXTENSION', {
          fileExtensions: filesWithDisallowedExtension.map((file) => this.getExtension(file)),
        }),
        {
          type: ToastMessageType.Error,
        }
      );

      return value.filter((file) => !filesWithDisallowedExtension.includes(file));
    }

    const extension = this.getExtension(value);
    if (!allowedExtensions.includes(extension)) {
      this.toastMessageService.open(
        this.translateService.instant('ERROR.DISALLOWED_FILE_EXTENSION', {
          fileExtensions: extension,
        }),
        {
          type: ToastMessageType.Error,
        }
      );

      // tslint:disable-next-line: no-null-keyword
      return null;
    }

    return [value];
  }

  private getExtension(file: File): string {
    return file.name.split('.').pop();
  }
}
