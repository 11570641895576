import { NgModule } from '@angular/core';
import { GlobalModule } from '../../global.module';
import { BreakpointComponent } from './breakpoint.component';

@NgModule({
  declarations: [BreakpointComponent],
  imports: [GlobalModule],
  exports: [BreakpointComponent],
})
export class BreakpointModule {}
