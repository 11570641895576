import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Injector } from '@angular/core';
import { hotjarInitializer, IHotjarSettings, NgxHotjarService, NGX_HOTJAR_SETTINGS_TOKEN } from 'ngx-hotjar';
import { CONFIG_TOKEN } from '../config/config.token';
import { GlobalConfig } from '../config/global-config.interface';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class HotjarService {
  constructor(
    @Inject(CONFIG_TOKEN) private readonly config: GlobalConfig,
    // Settings are actually provided in AppModule because of how Ngx Hotjar works.
    // Check this thread for more details: https://github.com/maxandriani/ngx-hotjar/issues/28
    @Inject(NGX_HOTJAR_SETTINGS_TOKEN) private readonly hotjarSettings: IHotjarSettings,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly authService: AuthService,
    private readonly injector: Injector
  ) {
    if (!this.config.hotjarSiteId) {
      return;
    }

    (async () => {
      await hotjarInitializer(this.hotjarSettings, this.document, window)();

      const hotjarService = this.injector.get(NgxHotjarService);

      this.authService.getUser$().subscribe((user) => {
        hotjarService.hj('identify', user.id, {
          email: user.email,
          user_id: user.id,
        });
      });
    })();
  }
}
