import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { DIALOG_DATA } from '../../../dialog/dialog.tokens';
import { DialogData } from '../../../dialog/interfaces/dialog-data.interface';
import { PreInspectionFacilitator } from '../../enums/pre-inspection-facilitator.enum';
import { PreInspectionSteps } from '../../enums/pre-inspection-steps.enum';
import { PreInspectionDialogPayload } from './pre-inspection-dialog-payload.interface';

enum Step {
  ChooseFacilitator,
  TecExConfirmation,
  ClientConfirmation,
}

@Component({
  selector: 'app-pre-inspection',
  templateUrl: './pre-inspection-dialog.component.html',
  styleUrls: ['./pre-inspection-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreInspectionDialogComponent implements OnInit {
  public currentStep: PreInspectionSteps = PreInspectionSteps.First;
  public step = Step.ChooseFacilitator;

  public readonly Step = Step;
  public readonly PreInspectionFacilitator = PreInspectionFacilitator;

  public formGroup = new FormGroup({
    preInscpectionFacilitator: new FormControl(undefined, [Validators.required]),
    clientCompleted: new FormControl(false),
  });

  public get preInscpectionFacilitatorControl(): FormControl {
    return this.formGroup.controls.preInscpectionFacilitator as FormControl;
  }

  public get clientCompletedControl(): FormControl {
    return this.formGroup.controls.clientCompleted as FormControl;
  }

  constructor(@Inject(DIALOG_DATA) public readonly data: DialogData<PreInspectionDialogPayload>) {}

  public ngOnInit(): void {
    if (this.data.payload.isInactive) {
      this.formGroup.disable();
    }
  }

  public onMessageClick(): void {
    this.data.payload.onMsgClickHandler();
  }

  public onNextClick(): void {
    if (this.preInscpectionFacilitatorControl.value === PreInspectionFacilitator.Tecex) {
      this.step = Step.TecExConfirmation;
    } else {
      this.step = Step.ClientConfirmation;
      this.clientCompletedControl.setValidators(Validators.requiredTrue);
    }
  }

  public onSubmitClick(): void {
    this.data.dialogRef.close(this.preInscpectionFacilitatorControl.value);
  }

  public onCancelClick(): void {
    this.data.dialogRef.close();
  }
}
