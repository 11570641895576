import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalModule } from '../../global.module';
import { SvgIconsModule } from '../svg-icons/svg-icons.module';
import { FileInputComponent } from './file-input.component';

@NgModule({
  imports: [GlobalModule, SvgIconsModule, TranslateModule],
  declarations: [FileInputComponent],
  exports: [FileInputComponent],
})
export class FileInputModule {}
