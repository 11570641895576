<div class="third-parties d-flex flex-column">
  <div class="header">
    <span class="title">
      {{ 'QUOTE.THIRD_PARTY_DIALOG.SELECT_THIRD_PARTIES' | translate }}
    </span>
    <span class="sub-title ml-md-3">{{ 'COMMON.N_ADDED' | translate : { n: countAddedParties() } }}</span>
  </div>

  <div class="container mt-3 filter">
    <div class="row align-items-end">
      <div class="col-6 search-input d-flex">
        <svg-icon key="search" class="mr-2"></svg-icon>
        <input
          [ngModel]="searchText"
          type="text"
          [placeholder]="'COMMON.SEARCH' | translate"
          class="form-control-input"
          (ngModelChange)="searchParties($event)"
        />
      </div>
    </div>
  </div>

  <div class="scroll-content">
    <mat-tab-group class="container">
      <mat-tab *ngFor="let category of categories">
        <ng-template mat-tab-label>
          <div (click)="clickCategory(category)">
            {{ category.title }} <strong class="ml-1"></strong>
            <span class="count">({{ category.count }})</span>
          </div>
        </ng-template>
        <div class="party-list" *ngIf="category.filteredPartyList.length > 0 || category.selectedPartyList.length > 0; else noParties">
          <div>
            <app-third-party-list
              *ngFor="let party of category.selectedPartyList"
              (selectPartyWithRadio)="onToggleParty($event, category)"
              [canDelete]="true"
              (deleteParty)="onDeleteParty([], category)"
              [party]="party"
              [isSelected]="true"
              [isSelectable]="false"
            ></app-third-party-list>
          </div>
          <div>
            <app-third-party-list
              *ngFor="let party of category.filteredPartyList"
              (selectPartyWithRadio)="onToggleParty($event, category)"
              [party]="party"
            ></app-third-party-list>
          </div>
        </div>
        <ng-template #noParties>
          <div class="no-parties">
            {{ 'QUOTE.THIRD_PARTY_DIALOG.NO_THIRD_PARTIES' | translate }}
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div class="dialog-footer">
    <div class="cross"></div>
    <div class="d-flex justify-content-center align-items-center button-container">
      <button mat-button class="primary-button save-button" type="button" (click)="onAddPartiesClick()">
        {{ 'QUOTE.THIRD_PARTY_DIALOG.ALLOCATE_THIRD_PARTIES' | translate }}
      </button>
    </div>
  </div>
  <div class="gradient"></div>
</div>
