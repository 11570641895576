import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { CONFIG_TOKEN } from '../../config/config.token';
import { GlobalConfig } from '../../config/global-config.interface';
import { redirectUrlSessionStorageKey } from '../../constants/global.constants';
import { RouteSegment } from '../../enums/route-segment.enum';
import { GlobalEnvironment } from '../../interfaces/global-environment.interface';
import { AnnouncmentService } from '../../modules/announcment/services/announcment.service';
import { AuthService } from '../../services/auth.service';
import { SERVE_PORT } from '../../tokens/app-serve-port.token';
import { ENVIRONMENT } from '../../tokens/environment.token';

@Component({
  selector: 'app-login-callback',
  templateUrl: './login-callback.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginCallbackComponent implements OnInit {
  public readonly loginRedirectUrl = this.config.loginRedirectUrl;

  public showUi: boolean;

  public sfAccessToken: string;
  public userId: string;
  public accountId: string;
  public ncpAccessToken: string;

  public readonly dashboardLink = [RouteSegment.Dashboard];

  public devHandle = '';

  constructor(
    @Inject(CONFIG_TOKEN) private readonly config: GlobalConfig,
    @Inject(ENVIRONMENT) private readonly environment: GlobalEnvironment,
    @Inject(SERVE_PORT) private readonly servePort: number,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly announcmentService: AnnouncmentService
  ) {
    this.showUi = this.environment.debug && this.config.debugAuth;
  }

  public ngOnInit(): void {
    this.sfAccessToken = this.route.snapshot.queryParamMap.get('Actoken');
    this.userId = this.route.snapshot.queryParamMap.get('UserID');
    this.accountId = this.route.snapshot.queryParamMap.get('AccountID');
    this.ncpAccessToken = atob(this.route.snapshot.queryParamMap.get('AccessToken'));

    const autoRedirect = this.route.snapshot.queryParamMap.has('redirect');

    if (!this.showUi || autoRedirect) {
      this.continue();
    }
  }

  public onContinueClick(): void {
    this.continue();
  }

  public onContinueToDevContainerClick(): void {
    const queryString = this.route.snapshot.queryParamMap.keys
      .map((key) => `${key}=${this.route.snapshot.queryParamMap.get(key)}`)
      .join('&');

    window.location.href =
      'https://' + `${this.devHandle}` + '.app.tecexlabs.dev/' + `${RouteSegment.LoginCallback}?${queryString}&redirect`;
  }

  public onContinueToLocalhostClick(): void {
    const queryString = this.route.snapshot.queryParamMap.keys
      .map((key) => `${key}=${this.route.snapshot.queryParamMap.get(key)}`)
      .join('&');

    window.location.href = `http://localhost:${this.servePort}/${RouteSegment.LoginCallback}?${queryString}&redirect`;
  }

  private continue(): void {
    this.announcmentService
      .isUnderMaintenance$()
      .pipe(first())
      .subscribe((isUnderMaintenance) => {
        this.authService.setCredentials({ accessToken: this.sfAccessToken, userId: this.userId }, isUnderMaintenance);

        const redirectUrl = sessionStorage.getItem(redirectUrlSessionStorageKey);
        if (redirectUrl) {
          sessionStorage.removeItem(redirectUrlSessionStorageKey);
          this.router.navigateByUrl(redirectUrl);
        } else {
          this.router.navigate([RouteSegment.Root]);
        }
      });
  }
}
