import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginCallbackComponent } from '@global/components/login-callback/login-callback.component';
import { RouteSegment } from '@global/enums/route-segment.enum';
import { AuthGuard } from '@global/guards/auth.guard';
import { UnAuthGuard } from '@global/guards/un-auth.guard';
import { MaintenanceGuard } from '@global/modules/announcment/guards/maintenance.guard';
import { RouteData } from '@shared/enums/route-data.enum';
import { LogoutComponent } from './components/logout/logout.component';
import { NoMobileViewComponent } from './components/no-mobile-view/no-mobile-view.component';
import { PageLayoutComponent } from './components/page-layout/page-layout.component';
import { ThirdPartyGuard } from './guards/third-party.guard';

const routes: Routes = [
  {
    path: RouteSegment.Root,
    pathMatch: 'full',
    redirectTo: RouteSegment.Dashboard,
  },
  {
    path: '',
    component: PageLayoutComponent,
    canActivate: [AuthGuard, MaintenanceGuard],
    children: [
      {
        path: '',
        canActivateChild: [ThirdPartyGuard],
        children: [
          {
            path: RouteSegment.NewQuote,
            loadChildren: () => import('@modules/new-quote/new-quote.module').then((m) => m.NewQuoteModule),
            data: {
              [RouteData.HasBackgroundPattern]: true,
              [RouteData.HasWhiteBackground]: true,
            },
          },
          {
            path: RouteSegment.Dashboard,
            loadChildren: () => import('@modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
          },
          {
            path: RouteSegment.QuoteList,
            loadChildren: () => import('@modules/quote-list/quote-list.module').then((m) => m.QuoteListModule),
          },
          {
            path: RouteSegment.Invoices,
            loadChildren: () => import('@modules/invoices/invoices.module').then((m) => m.InvoicesModule),
            data: {
              [RouteData.HasBackgroundPattern]: true,
            },
          },
          {
            path: RouteSegment.Messages,
            loadChildren: () => import('@modules/messages/messages.module').then((m) => m.MessagesModule),
          },
          {
            path: RouteSegment.ShipmentsList,
            loadChildren: () => import('@modules/shipments/shipments.module').then((m) => m.ShipmentsModule),
          },
          {
            path: RouteSegment.Tracking,
            loadChildren: () => import('@modules/tracking/tracking.module').then((m) => m.TrackingModule),
          },
          {
            path: RouteSegment.Insights,
            loadChildren: () => import('./modules/insights/insights.module').then((m) => m.InsightsModule),
          },
          {
            path: RouteSegment.QuoteGuidance,
            loadChildren: () => import('./modules/quote-guidance/quote-guidance.module').then((m) => m.QuoteGuidanceModule),
          },
        ],
      },
      {
        path: RouteSegment.Profile,
        loadChildren: () => import('@modules/profile/profile.module').then((m) => m.ProfileModule),
        data: {
          [RouteData.HasBackgroundPattern]: true,
        },
      },
      {
        path: RouteSegment.ThirdParty,
        loadChildren: () => import('@modules/third-party/third-party.module').then((m) => m.ThirdPartyModule),
      },
      {
        path: RouteSegment.Logout,
        component: LogoutComponent,
      },
    ],
  },
  {
    path: RouteSegment.OnBoarding,
    loadChildren: () => import('@modules/onboarding/onboarding.module').then((m) => m.OnboardingModule),
    canLoad: [UnAuthGuard],
    canActivate: [UnAuthGuard],
    data: {
      [RouteData.HasWhiteBackground]: true,
    },
  },
  {
    path: RouteSegment.Registration,
    canLoad: [UnAuthGuard],
    canActivate: [UnAuthGuard],
    loadChildren: () => import('@modules/registration/registration.module').then((m) => m.RegistrationModule),
    data: {
      [RouteData.HasWhiteBackground]: true,
    },
  },
  {
    path: RouteSegment.NoMobileView,
    component: NoMobileViewComponent,
  },
  {
    path: RouteSegment.LoginCallback,
    component: LoginCallbackComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
