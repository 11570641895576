import { Injectable, Injector } from '@angular/core';
import { EditAddressDialogResult } from '../../../../interfaces/address/edit-address-dialog-result.type';
import { DialogRef } from '../../../dialog/dialog-ref';
import { DialogService } from '../../../dialog/dialog.service';
import { DialogConfig } from '../../../dialog/interfaces/dialog-config.interface';
import { EditAddressDialogPayload } from '../../interfaces/edit-address-dialog-payload.type';
import { EditAddressDialogComponent } from './edit-address-dialog.component';

@Injectable()
export class EditAddressDialogService {
  constructor(private readonly dialogService: DialogService, private readonly injector: Injector) {}

  public open(payload: EditAddressDialogPayload, config?: DialogConfig): DialogRef<EditAddressDialogResult> {
    return this.dialogService.open(EditAddressDialogComponent, payload, {
      ...config,
      closeButton: false,
      // this makes the google maps autocomplete position itself properly
      preventScrollOffsetSaving: true,
      injector: this.injector,
    });
  }
}
