import { TaskComponentName, TaskDetails } from '@tecex-api/data';
import { MessageButtonUserVM } from '../../message-button/user.vm';
import { AirWaybillDialogPayload } from '../components/air-waybill/air-waybill-dialog-payload.interface';
import { AirWaybillType } from '../enums/air-waybill-type.enum';
import { mapTaskShipmentOrder } from './map-task-shipment-order.helper';

export const mapAirWaybillResponse = (
  taskDetails: TaskDetails,
  defaultTeamMember: MessageButtonUserVM,
  showShipmentOrderBox: boolean,
  onMsgClickHandler: () => void
): AirWaybillDialogPayload => ({
  id: taskDetails.task.Id,
  title: taskDetails.template.taskTitle,
  description: taskDetails.template.taskDescription,
  type: taskDetails.template.blocks?.find(
    (block) => block.cmpName === TaskComponentName.CARRIER_CHOICE_AND_AWB_INSTRUCTION__NO_NOTIFY_PARTY
  )
    ? AirWaybillType.NoNotifyParty
    : AirWaybillType.WithNotifyParty,
  courierConsignee: {
    company: taskDetails.freight?.Con_Company__c,
    addressLine1: taskDetails.freight?.Con_AddressLine1__c,
    addressLine2: taskDetails.freight?.Con_AddressLine2__c,
    city: taskDetails.freight?.Con_City__c,
    state: taskDetails.freight?.Con_State__c,
    country: taskDetails.freight?.Con_Country__c,
    postalCode: taskDetails.freight?.Con_Postal_Code__c,
    name: taskDetails.freight?.Con_Name__c,
    email: taskDetails.freight?.Con_Email__c,
    phone: taskDetails.freight?.Con_Phone__c,
  },
  freightForwarderConsignee: {
    company: taskDetails.freight?.FF_Con_Company__c,
    addressLine1: taskDetails.freight?.FF_Con_AddressLine1__c,
    addressLine2: taskDetails.freight?.FF_Con_AddressLine2__c,
    city: taskDetails.freight?.FF_Con_City__c,
    state: taskDetails.freight?.FF_Con_State__c,
    country: taskDetails.freight?.FF_Con_Country__c,
    postalCode: taskDetails.freight?.FF_Con_Postal_Code__c,
    name: taskDetails.freight?.FF_Con_Name__c,
    email: taskDetails.freight?.FF_Con_Email__c,
    phone: taskDetails.freight?.FF_Con_Phone__c,
  },
  courierNotifyParty: {
    type: taskDetails.freight?.Notify_Type_RE__c,
    taxId: taskDetails.freight?.Notify_TaxID_RE__c,
    taxIdType: taskDetails.freight?.Notify_TaxId_Type_RE__c,
    company: taskDetails.freight?.Notify_Company_RE__c,
    addressLine1: taskDetails.freight?.Notify_AddressLine1_RE__c,
    addressLine2: taskDetails.freight?.Notify_AddressLine2_RE__c,
    city: taskDetails.freight?.Notify_City_RE__c,
    state: taskDetails.freight?.Notify_State_RE__c,
    country: taskDetails.freight?.Notify_Country_RE__c,
    postalCode: taskDetails.freight?.Notify_Postal_Code_RE__c,
    name: taskDetails.freight?.Notify_Name_RE__c,
    email: taskDetails.freight?.Notify_Email_RE__c,
    phone: taskDetails.freight?.Notify_Phone_RE__c,
  },
  freightForwarderNotifyParty: {
    type: taskDetails.freight?.FF_Notify_Type_RE__c,
    taxId: taskDetails.freight?.FF_Notify_TaxID_RE__c,
    taxIdType: taskDetails.freight?.FF_Notify_TaxId_Type_RE__c,
    company: taskDetails.freight?.FF_Notify_Company_RE__c,
    addressLine1: taskDetails.freight?.FF_Notify_AddressLine1_RE__c,
    addressLine2: taskDetails.freight?.FF_Notify_AddressLine2_RE__c,
    city: taskDetails.freight?.FF_Notify_City_RE__c,
    state: taskDetails.freight?.FF_Notify_State_RE__c,
    country: taskDetails.freight?.FF_Notify_Country_RE__c,
    postalCode: taskDetails.freight?.FF_Notify_Postal_Code_RE__c,
    name: taskDetails.freight?.FF_Notify_Name_RE__c,
    email: taskDetails.freight?.FF_Notify_Email_RE__c,
    phone: taskDetails.freight?.FF_Notify_Phone_RE__c,
  },
  defaultTeamMember,
  shipmentOrder: mapTaskShipmentOrder(taskDetails),
  showShipmentOrderBox,
  isInactive: taskDetails.task.Inactive__c,
  courierAWBIncoterm: taskDetails.SOInfo?.[0]?.CourierAWBIncoterm,
  freightForwarderAWBIncoterm: taskDetails.SOInfo?.[0]?.FFAWBIncoterm,
  onMsgClickHandler,
});
