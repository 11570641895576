import { ChangeDetectionStrategy, Component, ContentChild, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControlHintDirective } from '../../directives/form-control-hint.directive';
import { FormControlIconDirective } from '../../directives/form-control-icon.directive';
import { FormControlInputSuffixDirective } from '../../directives/form-control-input-suffix.directive';
import { FormControlInputDirective } from '../../directives/form-control-input.directive';
import { FormControlLabelDirective } from '../../directives/form-control-label.directive';
import { FormControlReadonlyValueDirective } from '../../directives/form-control-readonly-value.directive';
import { FormControlTooltipDirective } from '../../directives/form-control-tooltip.directive';
import { FormControlService } from './form-control.service';

@Component({
  selector: 'app-form-control',
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormControlService],
})
export class FormControlComponent {
  // tslint:disable-next-line:variable-name
  private _hasError = false;
  // tslint:disable-next-line: no-null-keyword variable-name
  private _error: string | null = null;
  @Input() public set error(error: string | null) {
    this._hasError = error !== null;
    this.formControlService.hasError = this._hasError;
    this._error = error;
  }
  public get error(): string | null {
    return this._error;
  }
  public get hasError(): boolean {
    return this._hasError;
  }

  @ContentChild(FormControlLabelDirective) public label: FormControlLabelDirective;
  @ContentChild(FormControlTooltipDirective) public tooltip: FormControlTooltipDirective;
  @ContentChild(FormControlIconDirective) public icon: FormControlIconDirective;
  @ContentChild(FormControlInputDirective) public set input(input: FormControlInputDirective) {
    this.isFocused$ = input.isFocused$;
  }
  @ContentChild(FormControlReadonlyValueDirective) public readonlyValue: FormControlReadonlyValueDirective;
  @ContentChild(FormControlHintDirective) public hint: FormControlHintDirective;
  @ContentChild(FormControlInputSuffixDirective) public inputSuffix: FormControlInputSuffixDirective;

  public readonly id = this.formControlService.id;
  public isFocused$: Observable<boolean>;

  constructor(private readonly formControlService: FormControlService) {}
}
