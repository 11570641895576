import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TextFieldModule } from '@angular/cdk/text-field';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ContenteditableValueAccessorModule } from '@tinkoff/angular-contenteditable-accessor';
import { GlobalModule } from '../../global.module';
import { CommonMessagesService } from '../common-messages/services/common-messages.service';
import { FormControlModule } from '../form-control/form-control.module';
import { LoadingIndicatorModule } from '../loading-indicator/loading-indicator.module';
import { MessageCardModule } from '../message-card/message-card.module';
import { SearchFieldModule } from '../search-field/search-field.module';
import { SvgIconsModule } from '../svg-icons/svg-icons.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { MessageParticipantItemComponent } from './components/message-participant-item/message-participant-item.component';
import { MessageParticipantsListComponent } from './components/message-participants-list/message-participants-list.component';
import { MessageThreadComponent } from './components/message-thread/message-thread.component';
import { MessageComponent } from './components/message/message.component';
import { SendbirdMessageParticipantItemComponent } from './components/sendbird-message-participant-item/sendbird-message-participant-item.component';
import { SendbirdMessageParticipantListComponent } from './components/sendbird-message-participant-list/sendbird-message-participant-list.component';
import { SendbirdMessageThreadComponent } from './components/sendbird-message-thread/sendbird-message-thread.component';
import { SendbirdMessageComponent } from './components/sendbird-message/sendbird-message.component';
import { SendbirdSubjectComponent } from './components/sendbird-subject/sendbird-subject.component';
import { SubjectComponent } from './components/subject/subject.component';
import { CommonMessageEffect } from './effects/common-messages.effects';
import * as commonReducerFromMessage from './reducers';
import { CaseMessageService } from './services/case-message.service';
import { MessagingAWSService } from './services/messaging-aws.service';
import { TaskMessageService } from './services/task-message.service';

@NgModule({
  declarations: [
    MessageThreadComponent,
    MessageComponent,
    MessageParticipantItemComponent,
    MessageParticipantsListComponent,
    SubjectComponent,
    SendbirdMessageThreadComponent,
    SendbirdMessageComponent,
    SendbirdMessageParticipantItemComponent,
    SendbirdMessageParticipantListComponent,
    SendbirdSubjectComponent,
  ],
  imports: [
    GlobalModule,
    TranslateModule,
    TextFieldModule,
    RouterModule,
    MatMenuModule,
    LoadingIndicatorModule,
    ReactiveFormsModule,
    ContenteditableValueAccessorModule,
    OverlayModule,
    FormControlModule,
    MatRippleModule,
    ScrollingModule,
    FormControlModule,
    MessageCardModule,
    SvgIconsModule,
    MatButtonModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    SearchFieldModule,
    FormsModule,
    CKEditorModule,
    StoreModule.forFeature(commonReducerFromMessage.messagesFeatureKey, commonReducerFromMessage.reducers),
    EffectsModule.forFeature([CommonMessageEffect]),
    TooltipModule,
    MatDialogModule,
  ],
  exports: [MessageThreadComponent, SendbirdMessageThreadComponent],
  providers: [CaseMessageService, TaskMessageService, CommonMessagesService, MessagingAWSService],
})
export class MessageThreadModule {}
