import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormControl } from '@ngneat/reactive-forms';
import { emailWithTldValidator } from '../../../../validators/email-with-tld.validator';
import { numberValidator } from '../../../../validators/number.validator';
import { InputBlockPayload } from '../../types/input-block-payload.interface';
import { InputType } from '../../types/input-type.enum';
import { BlockComponent } from '../block/block.component';

const SHORT_TEXT_MAX_LENGTH = 100;
const LONG_TEXT_MAX_LENGTH = 300;

@Component({
  selector: 'app-input-block',
  templateUrl: './input-block.component.html',
  styleUrls: ['./input-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputBlockComponent extends BlockComponent<InputBlockPayload, undefined> implements OnInit {
  public readonly InputType = InputType;
  public formControl = new FormControl('');

  public ngOnInit(): void {
    switch (this.payload.type) {
      case InputType.ShortText:
        this.formControl.setValidators([Validators.maxLength(SHORT_TEXT_MAX_LENGTH)]);
        break;
      case InputType.LongText:
        this.formControl.setValidators([Validators.maxLength(LONG_TEXT_MAX_LENGTH)]);
        break;
      case InputType.Email:
        this.formControl.setValidators([emailWithTldValidator]);
        break;
      case InputType.Numeric:
        this.formControl.setValidators([numberValidator()]);
        break;
    }

    this.register.next(this.formControl);
  }
}
