import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { SvgIconsModule } from '../svg-icons/svg-icons.module';
import { ToastMessageIconDirective } from './toast-message-icon.directive';
import { ToastMessageWrapperComponent } from './toast-message-wrapper.component';
import { ToastMessageComponent } from './toast-message.component';
import { ToastMessageService } from './toast-message.service';

@NgModule({
  declarations: [ToastMessageComponent, ToastMessageIconDirective, ToastMessageWrapperComponent],
  imports: [CommonModule, SvgIconsModule, MatButtonModule, OverlayModule, PortalModule],
  exports: [ToastMessageComponent, ToastMessageIconDirective],
})
export class ToastMessageModule {
  public static forRoot(): ModuleWithProviders<ToastMessageModule> {
    return { ngModule: ToastMessageModule, providers: [ToastMessageService] };
  }
}
