import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@env/environment';
import { CONFIG_TOKEN } from '@global/config/config.token';
import { GlobalConfig } from '@global/config/global-config.interface';
import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', async (): Promise<void> => {
  const response = await fetch('assets/config/config.json');
  const config: GlobalConfig = await response.json();

  platformBrowserDynamic([
    {
      provide: CONFIG_TOKEN,
      useValue: config,
    },
  ])
    .bootstrapModule(AppModule)
    // eslint-disable-next-line no-console
    .catch((error: Error): void => console.error(error));
});
