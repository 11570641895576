import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Intercom } from 'ng-intercom';
import { asyncScheduler, BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, first, map, observeOn, shareReplay } from 'rxjs/operators';
import { CONFIG_TOKEN } from '../config/config.token';
import { GlobalConfig } from '../config/global-config.interface';
import { RouteSegment } from '../enums/route-segment.enum';
import { DialogService } from '../modules/dialog/dialog.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  private readonly showLauncherSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public showLauncherObservable$: Observable<boolean> = this.showLauncherSubject$.asObservable();

  constructor(
    @Inject(CONFIG_TOKEN) private readonly config: GlobalConfig,
    private readonly intercom: Intercom,
    private readonly authService: AuthService,
    private readonly dialogService: DialogService,
    private readonly router: Router
  ) {
    //TODO Edit this to not show when third party
    if (!this.config.intercomId) {
      this.showLauncherSubject$.next(false);
      return;
    }

    const visible$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event) => !(event as NavigationEnd).url.startsWith(`/${RouteSegment.Messages}`)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.authService.getUser$().subscribe((user) => {
      if (!this.authService.thirdPartyFlag()) {
        // eslint-disable-next-line rxjs/no-nested-subscribe
        visible$.pipe(filter(Boolean), first()).subscribe(() => {
          this.intercom.boot({
            app_id: this.config.intercomId,
            name: user.name,
            email: user.email,
            user_id: user.id,
            hide_default_launcher: true,
            custom_launcher_selector: '.intercom-launcher',
          });
          this.intercom.onHide(() => {
            this.showLauncherSubject$.next(true);
          });
          this.intercom.onShow(() => {
            this.showLauncherSubject$.next(false);
          });
        });

        combineLatest([this.dialogService.hasOpenDialog$(), visible$])
          .pipe(
            map(([hasOpenDialog, visible]) => !hasOpenDialog && visible),
            distinctUntilChanged(),
            observeOn(asyncScheduler)
          )
          // eslint-disable-next-line rxjs/no-nested-subscribe
          .subscribe((show) => {
            this.showLauncherSubject$.next(show);
            this.hideDefaultLauncher();
          });
      } else {
        this.showLauncherSubject$.next(false);
      }
    });
  }

  private hideDefaultLauncher(): void {
    this.intercom.update({
      hide_default_launcher: true,
    });
  }

  public launchIntercom(): void {
    this.intercom.show();
  }
}
