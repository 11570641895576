import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@ngneat/reactive-forms';
import { SelectBlockPayload } from '../../types/select-block-payload.interface';
import { BlockComponent } from '../block/block.component';

@Component({
  selector: 'app-select-block',
  templateUrl: './select-block.component.html',
  styleUrls: ['./select-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectBlockComponent extends BlockComponent<SelectBlockPayload, undefined> implements OnInit {
  public readonly formControl = new FormControl();

  public ngOnInit(): void {
    this.register.next(this.formControl);
  }
}
