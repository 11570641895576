import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalModule } from '../../global.module';
import { StateIndicatorCircleModule } from '../state-indicator-circle/state-indicator-circle.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { ShipmentStateIndicatorCirclesComponent } from './shipment-state-indicator-circles.component';

@NgModule({
  imports: [GlobalModule, TranslateModule, StateIndicatorCircleModule, TooltipModule],
  declarations: [ShipmentStateIndicatorCirclesComponent],
  exports: [ShipmentStateIndicatorCirclesComponent],
})
export class ShipmentStateIndicatorCirclesModule {}
