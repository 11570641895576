import * as CryptoJS from 'crypto-js';

const sigV4Client = { newClient: undefined };
// eslint-disable-next-line sonarjs/cognitive-complexity
sigV4Client.newClient = (config) => {
  const AWS_SHA_256 = 'AWS4-HMAC-SHA256';
  const AWS4_REQUEST = 'aws4_request';
  const AWS4 = 'AWS4';
  const X_AMZ_DATE = 'x-amz-date';
  const X_AMZ_SECURITY_TOKEN = 'x-amz-security-token';
  const HOST = 'host';
  const AUTHORIZATION = 'Authorization';

  const hash = (value) => {
    // eslint-disable-next-line unicorn/no-abusive-eslint-disable
    return CryptoJS.SHA256(value);
  };

  const hexEncode = (value) => {
    return value.toString(CryptoJS.encHex);
  };

  const hmac = (secret, value) => {
    // eslint-disable-next-line unicorn/no-abusive-eslint-disable
    return CryptoJS.HmacSHA256(value, secret, { asBytes: true });
  };

  const buildCanonicalRequest = (method, path, queryParams, headers, payload) => {
    return `${method}\n${buildCanonicalUri(path)}\n${buildCanonicalQueryString(queryParams)}\n${buildCanonicalHeaders(
      headers
    )}\n${buildCanonicalSignedHeaders(headers)}\n${hexEncode(hash(payload))}`;
  };

  const hashCanonicalRequest = (request) => {
    return hexEncode(hash(request));
  };

  const buildCanonicalUri = (uri) => {
    return encodeURI(uri);
  };

  const buildCanonicalQueryString = (queryParams) => {
    if (Object.keys(queryParams).length === 0) {
      return '';
    }

    const sortedQueryParams = [];
    for (const property in queryParams) {
      if (queryParams.hasOwnProperty(property)) {
        sortedQueryParams.push(property);
      }
    }
    sortedQueryParams.sort();

    let canonicalQueryString = '';
    for (const sortedQueryParam of sortedQueryParams) {
      canonicalQueryString += `${sortedQueryParam}=${encodeURIComponent(queryParams[sortedQueryParam])}&`;
    }
    return canonicalQueryString.slice(0, Math.max(0, canonicalQueryString.length - 1));
  };

  const buildCanonicalHeaders = (headers) => {
    let canonicalHeaders = '';
    const sortedKeys = [];
    for (const property in headers) {
      if (headers.hasOwnProperty(property)) {
        sortedKeys.push(property);
      }
    }
    sortedKeys.sort();

    for (const sortedKey of sortedKeys) {
      canonicalHeaders += `${sortedKey.toLowerCase()}:${headers[sortedKey]}\n`;
    }
    return canonicalHeaders;
  };

  const buildCanonicalSignedHeaders = (headers) => {
    const sortedKeys = [];
    for (const property in headers) {
      if (headers.hasOwnProperty(property)) {
        sortedKeys.push(property.toLowerCase());
      }
    }
    sortedKeys.sort();

    return sortedKeys.join(';');
  };

  const buildStringToSign = (datetime, credentialScope, hashedCanonicalRequest) => {
    return `${AWS_SHA_256}\n${datetime}\n${credentialScope}\n${hashedCanonicalRequest}`;
  };

  const buildCredentialScope = (datetime, region, service) => {
    return `${datetime.slice(0, 8)}/${region}/${service}/${AWS4_REQUEST}`;
  };

  const calculateSigningKey = (secretKey, datetime, region, service) => {
    return hmac(hmac(hmac(hmac(AWS4 + secretKey, datetime.slice(0, 8)), region), service), AWS4_REQUEST);
  };

  const calculateSignature = (key, stringToSign) => {
    return hexEncode(hmac(key, stringToSign));
  };

  const extractHostname = (url) => {
    let hostname;

    hostname = url.includes('://') ? url.split('/')[2] : url.split('/')[0];

    hostname = hostname.split(':')[0];
    hostname = hostname.split('?')[0];

    return hostname;
  };

  const buildAuthorizationHeader = (accessKey, credentialScope, headers, signature) => {
    return `${AWS_SHA_256} Credential=${accessKey}/${credentialScope}, SignedHeaders=${buildCanonicalSignedHeaders(
      headers
    )}, Signature=${signature}`;
  };

  const awsSigV4Client = {
    accessKey: undefined,
    secretKey: undefined,
    sessionToken: undefined,
    serviceName: undefined,
    region: undefined,
    defaultAcceptType: undefined,
    defaultContentType: undefined,
    endpoint: undefined,
    pathComponent: undefined,
    signRequest: undefined,
  };
  if (config.accessKey === undefined || config.secretKey === undefined) {
    return awsSigV4Client;
  }
  awsSigV4Client.accessKey = config.accessKey;
  awsSigV4Client.secretKey = config.secretKey;
  awsSigV4Client.sessionToken = config.sessionToken;
  awsSigV4Client.serviceName = config.serviceName || 'execute-api';
  awsSigV4Client.region = config.region || 'us-east-1';
  awsSigV4Client.defaultAcceptType = config.defaultAcceptType || 'application/json';
  awsSigV4Client.defaultContentType = config.defaultContentType || 'application/json';

  const invokeUrl = config.endpoint;
  const endpoint = /(^https?:\/\/[^/]+)/g.exec(invokeUrl)[1];
  const pathComponent = invokeUrl.slice(endpoint.length);

  awsSigV4Client.endpoint = endpoint;
  awsSigV4Client.pathComponent = pathComponent;

  awsSigV4Client.signRequest = (request) => {
    const verb = request.method.toUpperCase();
    const path = awsSigV4Client.pathComponent + request.path;
    const queryParams = { ...request.queryParams };
    const headers = { ...request.headers };

    // If the user has not specified an override for Content type the use default
    if (headers['Content-Type'] === undefined) {
      headers['Content-Type'] = awsSigV4Client.defaultContentType;
    }

    // If the user has not specified an override for Accept type the use default
    if (headers['Accept'] === undefined) {
      headers['Accept'] = awsSigV4Client.defaultAcceptType;
    }

    let body = { ...request.body };
    // override request body and set to empty when signing GET requests
    body = request.body === undefined || verb === 'GET' ? '' : JSON.stringify(body);

    // If there is no body remove the content-type header so it is not
    // included in SigV4 calculation
    if (body === '' || body === undefined || body === null) {
      delete headers['Content-Type'];
    }

    const datetime = new Date(Date.now() + config.serverTimeOffset)
      .toISOString()
      .replace(/\.\d{3}Z$/, 'Z')
      .replace(/[:-]|\.\d{3}/g, '');
    headers[X_AMZ_DATE] = datetime;
    headers[HOST] = extractHostname(awsSigV4Client.endpoint);

    const canonicalRequest = buildCanonicalRequest(verb, path, queryParams, headers, body);
    const hashedCanonicalRequest = hashCanonicalRequest(canonicalRequest);
    const credentialScope = buildCredentialScope(datetime, awsSigV4Client.region, awsSigV4Client.serviceName);
    const stringToSign = buildStringToSign(datetime, credentialScope, hashedCanonicalRequest);
    const signingKey = calculateSigningKey(awsSigV4Client.secretKey, datetime, awsSigV4Client.region, awsSigV4Client.serviceName);
    const signature = calculateSignature(signingKey, stringToSign);
    headers[AUTHORIZATION] = buildAuthorizationHeader(awsSigV4Client.accessKey, credentialScope, headers, signature);
    if (awsSigV4Client.sessionToken !== undefined && awsSigV4Client.sessionToken !== '') {
      headers[X_AMZ_SECURITY_TOKEN] = awsSigV4Client.sessionToken;
    }
    delete headers[HOST];

    let url = awsSigV4Client.endpoint + path;
    const queryString = buildCanonicalQueryString(queryParams);
    if (queryString !== '') {
      url += `?${queryString}`;
    }

    // Need to re-attach Content-Type if it is not specified at this point
    if (headers['Content-Type'] === undefined) {
      headers['Content-Type'] = awsSigV4Client.defaultContentType;
    }

    return {
      headers,
      url,
    };
  };
  return awsSigV4Client;
};

export default sigV4Client;
