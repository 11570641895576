import { Contact } from '@global/interfaces/contact.interface';
import { FreightCosts } from '@global/interfaces/freight-costs.interface';
import { NewRollOutDetailDefaults } from '@global/interfaces/new-quote-defaults.interface';
import { LineItem } from '@global/modules/common-quote/interfaces/line-item.interface';
import { createAction, props } from '@ngrx/store';
import { QuoteDetailsQuote, QuoteDetailsRollOutQuote } from '../interfaces/quote.interface';

export const enter = createAction('[Quote Details] enter');
export const loadQuote = createAction('[Quote Details] LoadQuote', props<{ quoteId: string; reload?: boolean }>());
export const loadNewQuote = createAction('[Quote Details] LoadNewQuote', props<{ quoteId: string; isRollOut: boolean }>());

export const loadSingleQuote = createAction('[Quote Details] LoadSingleQuote', props<{ quoteId: string; reload?: boolean }>());
export const loadSingleQuoteSuccess = createAction('[Quote Details] LoadSingleQuoteSuccess', props<{ quote: QuoteDetailsQuote }>());
export const loadSingleQuoteError = createAction('[Quote Details] LoadSingleQuoteError', props<{ error: string }>());

export const loadContacts = createAction('[Quote Details] LoadContacts');
export const loadContactsSuccess = createAction('[Quote Details] LoadContactsSuccess', props<{ contacts: Contact[] }>());
export const loadContactsError = createAction('[Quote Details] LoadContactsError', props<{ error: string }>());

export const loadRollOutQuote = createAction('[Quote Details] LoadRollOutQuote', props<{ quoteId: string; reload?: boolean }>());
export const loadRollOutQuoteSuccess = createAction(
  '[Quote Details] LoadRollOutQuoteSuccess',
  props<{ quote: QuoteDetailsRollOutQuote; rollOutDetail?: any }>()
);
export const loadRollOutQuoteError = createAction('[Quote Details] LoadRollOutQuoteError', props<{ error: string }>());

export const editBasics = createAction('[Quote Details] EditBasics');

export const editBasicsRO = createAction('[Quote Details] EditBasicsRO', props<{ quote: QuoteDetailsQuote; rolloutId: string }>());

export const editLineItems = createAction('[Quote Details] EditLineItems');
export const editLineItemsSuccess = createAction('[Quote Details] EditLineItemsSuccess', props<{ lineItems: LineItem[] }>());
export const editLineItemsError = createAction('[Quote Details] EditLineItemsError', props<{ error: string }>());

export const addLineItems = createAction('[Quote Details] addLineItems', props<{ quoteId: string; rolloutId?: string }>());

export const addThirdParties = createAction('[Quote Details] AddThirdParties');

export const editPackages = createAction('[Quote Details] EditPackages', props<{ quote: QuoteDetailsQuote }>());

export const editPackagesRO = createAction(
  '[Quote Details] EditPackagesRO',
  props<{ quote: QuoteDetailsQuote; rollout: QuoteDetailsRollOutQuote }>()
);

export const editPickupAddress = createAction('[Quote Details] EditPickupAddress');

export const deletePickupAddress = createAction('[Quote Details] deletePickupAddress');

export const editShipToLocations = createAction('[Quote Details] EditShipToLocations');

export const reuseQuote = createAction('[Quote Details] ReuseQuote', props<{ id: string }>());
export const reuseRollout = createAction('[Quote Details] ReuseRollout', props<{ id: string }>());
export const addNewShipmentsToRollout = createAction('[Quote Details] AddNewShipmentsToRollout', props<{ id: string }>());

export const cancelQuote = createAction('[Quote Details] CancelQuote', props<{ id: string }>());

export const acceptQuote = createAction('[Quote Details] AcceptQuote', props<{ quoteId: string }>());

export const downloadQuote = createAction('[Quote Details] DownloadQuote', props<{ quoteId: string }>());
export const downloadExcel = createAction('[Quote Details] DownloadExcel', props<{ quoteId: string }>());

export const sendQuoteEmail = createAction('[Quote Details] SendQuoteEmail', props<{ quoteId: string }>());

export const changeShipmentProvider = createAction(
  '[Quote Details] ChangeShipmentProvider',
  props<{ quoteId: string; freightId: string }>()
);

export const toggleTecexShippingService = createAction(
  '[Quote Details] ToggleTecexShippingService',
  props<{ isEnabled: boolean; quoteId: string }>()
);

export const toggleLiabilityCoverFee = createAction(
  '[Quote Details] ToggleLiabilityCoverFee',
  props<{ isEnabled: boolean; quoteId: string }>()
);

export const updateShippingFees = createAction(
  '[Quote Details] UpdateShippingFees',
  props<{ quoteId: string; tecExShippingServiceFeeEnabled: boolean; liabilityCoverFeeEnabled: boolean }>()
);

export const changeOwner = createAction('[Quote Details] ChangeOwner', props<{ owner: string }>());

export const saveNote = createAction('[Quote Details] SaveNote', props<{ note: string; id: string }>());
export const saveNoteSuccess = createAction('[Quote Details] SaveNoteSuccess', props<{ note: string }>());
export const saveNoteError = createAction('[Quote Details] SaveNoteError', props<{ error: string }>());
export const createMessage = createAction('[Quote Details] CreateMessage');
export const openMessage = createAction('[Quote Details] OpenMessage', props<{ messageId: string }>());

export const updateFreightCosts = createAction(
  '[Quote Details] UpdateFreightCosts',
  props<{ freightCosts: Record<string, FreightCosts> }>()
);

export const rollOutDetailSuccess = createAction(
  '[Quote Details] rollOutDetailSuccess',
  props<{ existingRollOutDetail: Partial<NewRollOutDetailDefaults> }>()
);

export const leave = createAction('[Quote Details] Leave');

export const duplicateSOInRollout = createAction('[Quote Details] DuplicateSOInRollout', props<{ quoteId: string; rolloutId: string }>());
