import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { SvgIconsModule } from '../svg-icons/svg-icons.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { FormControlComponent } from './components/form-control/form-control.component';
import { FormControlHintDirective } from './directives/form-control-hint.directive';
import { FormControlIconDirective } from './directives/form-control-icon.directive';
import { FormControlInputSuffixDirective } from './directives/form-control-input-suffix.directive';
import { FormControlInputDirective } from './directives/form-control-input.directive';
import { FormControlLabelDirective } from './directives/form-control-label.directive';
import { FormControlReadonlyValueDirective } from './directives/form-control-readonly-value.directive';
import { FormControlTooltipDirective } from './directives/form-control-tooltip.directive';

@NgModule({
  declarations: [
    FormControlComponent,
    FormControlIconDirective,
    FormControlInputDirective,
    FormControlLabelDirective,
    FormControlTooltipDirective,
    FormControlHintDirective,
    FormControlInputSuffixDirective,
    FormControlReadonlyValueDirective,
  ],
  imports: [CommonModule, SvgIconsModule, TooltipModule, MatButtonModule],
  exports: [
    FormControlComponent,
    FormControlIconDirective,
    FormControlInputDirective,
    FormControlLabelDirective,
    FormControlTooltipDirective,
    FormControlHintDirective,
    FormControlInputSuffixDirective,
    FormControlReadonlyValueDirective,
  ],
})
export class FormControlModule {}
