import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalModule } from '../../global.module';
import { SvgIconsModule } from '../svg-icons/svg-icons.module';
import { PackageClosedContentCardComponent } from './package-closed-content-card.component';

@NgModule({
  declarations: [PackageClosedContentCardComponent],
  imports: [GlobalModule, SvgIconsModule, TranslateModule, MatButtonModule],
  exports: [PackageClosedContentCardComponent],
})
export class PackageClosedContentCardModule {}
