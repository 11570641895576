import { Directive, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import isEmpty from 'lodash/isEmpty';

@Directive({
  selector: '[appTextHighlight]',
})
export class TextHighlightDirective implements OnChanges {
  @Input('appTextHighlight') public value: string;
  @Input() public highlightText = '';
  @HostBinding('innerHtml') public content = '';

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.value || changes.highlightText) {
      this.content = this.createContent();
    }
  }

  private createContent(): string {
    if (isEmpty(this.highlightText)) {
      return this.value;
    }
    const pattern = this.highlightText
      .replace(/\+/g, '\\+')
      .split(' ')
      .filter((t) => t.length > 0)
      .join('|');
    const regex = new RegExp(pattern, 'gi');

    return isEmpty(this.highlightText) ? this.value : this.value.replace(regex, (match) => `<b>${match}</b>`);
  }
}
