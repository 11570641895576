import { LineItemsState } from '@global/modules/common-quote/enums/line-items-state.enum';
import { LineItem } from '@global/modules/common-quote/interfaces/line-item.interface';
import { createReducer, on } from '@ngrx/store';
import * as NewQuoteLineItemsActions from '../actions/new-quote-line-items.actions';
import * as NewQuoteActions from '../actions/new-quote.actions';

export const newQuoteLineItemsFeatureKey = 'newQuoteLineItems';

export interface State {
  state: LineItemsState;
  lineItems: LineItem[];
  isLoading: boolean;
  RollOutDetail: any;
  shipmentOrderIdsWhichAdded: any;
}

export const initialState: State = {
  state: LineItemsState.NotCompleted,
  lineItems: [],
  isLoading: false,
  RollOutDetail: [],
  shipmentOrderIdsWhichAdded: [],
};

export const reducer = createReducer<State>(
  initialState,

  on(NewQuoteLineItemsActions.enter, (state): State => ({ ...state, state: LineItemsState.InProgress })),

  on(NewQuoteActions.loadExistingQuoteSuccess, (state, { lineItems }): State => ({ ...state, lineItems })),

  on(NewQuoteLineItemsActions.submit, (state): State => ({ ...state, isLoading: true })),

  on(
    NewQuoteLineItemsActions.submitSuccess,
    (state, { lineItems }): State => ({
      ...state,
      state: LineItemsState.Completed,
      lineItems,
      isLoading: false,
    })
  ),

  on(NewQuoteLineItemsActions.submitError, (state): State => ({ ...state, isLoading: false })),

  on(NewQuoteLineItemsActions.skipSuccess, (state): State => ({ ...state, lineItems: [], state: LineItemsState.Skipped })),

  on(NewQuoteLineItemsActions.editSuccess, (state, { lineItems }): State => ({ ...state, state: LineItemsState.Completed, lineItems })),

  on(NewQuoteLineItemsActions.SOAllRelatedInfoFromSelectedSoIdSuccess, (state, { RollOutDetail }): State => ({ ...state, RollOutDetail })),

  on(
    NewQuoteLineItemsActions.addToShipmentSuccess,
    (state, { successOfShipments }): State => ({ ...state, shipmentOrderIdsWhichAdded: successOfShipments })
  ),

  on(NewQuoteActions.leave, NewQuoteActions.startNew, NewQuoteActions.reuseQuote, (): State => initialState)
);
