import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PageContainerHeaderDirective } from './page-container-header.directive';
import { PageContainerLeftSidebarDirective } from './page-container-left-sidebar.directive';
import { PageContainerRightSidebarDirective } from './page-container-right-sidebar.directive';
import { PageContainerTitleDirective } from './page-container-title.directive';
import { PageContainerComponent } from './page-container.component';

@NgModule({
  declarations: [
    PageContainerComponent,
    PageContainerLeftSidebarDirective,
    PageContainerRightSidebarDirective,
    PageContainerHeaderDirective,
    PageContainerTitleDirective,
  ],
  imports: [CommonModule],
  exports: [
    PageContainerComponent,
    PageContainerLeftSidebarDirective,
    PageContainerRightSidebarDirective,
    PageContainerHeaderDirective,
    PageContainerTitleDirective,
  ],
})
export class PageContainerModule {}
