import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalModule } from '../../global.module';
import { TrackerComponent } from './tracker.component';

@NgModule({
  declarations: [TrackerComponent],
  imports: [GlobalModule, TranslateModule],
  exports: [TrackerComponent],
})
export class TrackerModule {}
