import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { CommonMessagesService } from '../../common-messages/services/common-messages.service';
import * as commonFromMessagesAction from '../actions/common-messages.actions';
import { MessagingAWSService } from '../services/messaging-aws.service';
import { SendbirdService } from '../services/sendbird-message.service';

@Injectable()
export class CommonMessageEffect {
  // Get the list of shipment participants :

  public listShipmentOrderParticipant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonFromMessagesAction.loadShipmentOrderParticipants),
      mergeMap(({ SOID, SOAccountId }) => {
        return SOAccountId
          ? this.commonMessagesService
              .listShipmentOrderParticipantsThirdParty$(SOID, SOAccountId)
              .pipe(
                map((response) => commonFromMessagesAction.loadShipmentOrderParticipantsSuccess({ shipmentOrderParticipant: response }))
              )
          : this.commonMessagesService
              .listShipmentOrderParticipants$(SOID)
              .pipe(
                map((response) => commonFromMessagesAction.loadShipmentOrderParticipantsSuccess({ shipmentOrderParticipant: response }))
              );
      })
    );
  });

  // Get the list of account participants :

  public listAccountParticipant$ = createEffect((): any => {
    return this.actions$.pipe(
      ofType(commonFromMessagesAction.loadAccountParticipants),
      mergeMap(() => {
        return this.commonMessagesService
          .listAccountParticipants$()
          .pipe(map((response) => commonFromMessagesAction.loadAccountParticipantsSuccess({ accountParticipant: response })));
      })
    );
  });

  // Post for create message channel creation :

  public postCreateMessageChannelCreation$ = createEffect((): any => {
    return this.actions$.pipe(
      ofType(commonFromMessagesAction.createMessageChannelCase),
      switchMap(({ InvoiceID, TaskID, SOID }) => {
        return this.commonMessagesService.postCreateMessageChannelCreations$(InvoiceID, TaskID, SOID).pipe(
          map((response) => {
            return commonFromMessagesAction.createMessageChannelCaseSuccess({ messageChannelCreation: response });
          })
        );
      })
    );
  });

  // Update for current channel case detail and send a 1st message :

  public postUpdateMessageCase$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonFromMessagesAction.updateMessageCases),
      switchMap(({ CaseID, Subject, Description, orgId }) => {
        return this.commonMessagesService.postUpdateMessageCaseData$(CaseID, Subject, Description).pipe(
          map((response) => {
            return commonFromMessagesAction.updateMessageCaseSuccess({ updateMessageCase: { CaseID, Subject, Description, orgId } });
          })
        );
      })
    );
  });

  // Delete for current channel case detail :

  public postDeleteMessageCase$ = createEffect((): any => {
    return this.actions$.pipe(
      ofType(commonFromMessagesAction.deleteMessageCases),
      switchMap(({ CaseID, sendbirdChannelFullUrl }) => {
        return this.commonMessagesService.postDeleteMessageCaseData$(CaseID).pipe(
          map((response) => {
            return commonFromMessagesAction.deleteMessageCasesSuccess({ deleteMessageCase: sendbirdChannelFullUrl });
          })
        );
      })
    );
  });

  // For AWS delete channel API call after the success response :

  public postDeleteChannlCase$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonFromMessagesAction.deleteMessageCasesSuccess),
      switchMap(({ deleteMessageCase }) => {
        return this.messagingAwsService.deleteChannel(deleteMessageCase).pipe(
          map(() => {
            return commonFromMessagesAction.deleteChannelCaseSuccess();
          })
        );
      })
    );
  });

  public postUpdateMessageCaseName$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonFromMessagesAction.updateMessageCaseSuccess),
      switchMap(({ updateMessageCase }) => {
        return this.messagingAwsService
          .updateChannelName(
            {
              sendbirdChannelUrl: `${updateMessageCase.orgId}-${updateMessageCase.CaseID}`,
              channelName: updateMessageCase.Subject,
            },
            updateMessageCase.orgId
          )
          .pipe(
            map(() => {
              return commonFromMessagesAction.updateChannelNameSuccess();
            })
          );
      })
    );
  });

  // For channel info to getting participants list :

  public postChannelInfoDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonFromMessagesAction.channelInfoForParticipantList),
      switchMap(({ sendBirdChannelUrl }) => {
        return this.sendbirdServices.channelInfo$(sendBirdChannelUrl).pipe(
          map((detail) => {
            return commonFromMessagesAction.channelInfoForParticipantListSuccess({ updatedParticipantList: detail });
          })
        );
      })
    );
  });

  constructor(
    private readonly commonMessagesService: CommonMessagesService,
    private readonly actions$: Actions,
    private readonly messagingAwsService: MessagingAWSService,
    private readonly sendbirdServices: SendbirdService
  ) {}
}
