import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, Type } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@ngneat/reactive-forms';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef((): Type<SearchFieldComponent> => SearchFieldComponent), multi: true }],
})
export class SearchFieldComponent extends ControlValueAccessor {
  @Input() public placeholder?: string;

  constructor(private readonly cdr: ChangeDetectorRef) {
    super();
  }

  public value: string;
  public isDisabled = false;

  public onChangeFn?: (value?: string) => void;
  public onTouchedFn?: () => void;

  public registerOnChange(fn: (value: string) => void): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouchedFn = fn;
  }

  public writeValue(value: string): void {
    this.value = value;
    this.cdr.markForCheck();
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public onValueChange(value: string): void {
    this.value = value;
    this.onChangeFn(value);
  }
}
