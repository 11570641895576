import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouteSegment } from '../../../../enums/route-segment.enum';
import { ShipmentStatus } from '../../../../enums/shipment-status.enum';
import { TaskVM } from '../../types/task.vm';

@Component({
  selector: 'app-shipment-summary',
  templateUrl: './shipment-summary.component.html',
  styleUrls: ['./shipment-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentSummaryComponent {
  @Input() public shipmentOrder: TaskVM['shipmentOrder'];

  public readonly ShipmentStatus = ShipmentStatus;

  public get shipmentLink(): string[] {
    return [RouteSegment.Root, RouteSegment.ShipmentsList, this.shipmentOrder.id];
  }
}
