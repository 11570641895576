import { Injectable } from '@angular/core';
import { FormArray, FormControl } from '@ngneat/reactive-forms';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ValidatorHelperService {
  constructor(private readonly translateService: TranslateService) {}

  public getError$(
    formControl: FormControl | FormArray,
    customMessage: Record<string, (data: any) => Observable<string>> = {}
  ): Observable<null | string> {
    return combineLatest([formControl.touch$, formControl.errors$]).pipe(
      switchMap(([touched, errors]) => {
        if (!touched || !errors) {
          // tslint:disable-next-line:no-null-keyword
          return of(null);
        }

        const messages = Object.entries(errors).map(([key, data]) => {
          if (customMessage[key]) {
            return customMessage[key](data);
          }

          return this.getErrorMessage$(key, data);
        });

        return combineLatest(messages).pipe(
          map((translatedErrors) => translatedErrors.filter((translatedError) => translatedError !== null).join('. '))
        );
      })
    );
  }

  // tslint:disable-next-line: cyclomatic-complexity
  private getErrorMessage$(key: string, data: any): Observable<string> {
    switch (key) {
      case 'required':
        return this.translateService.get('ERRORS.REQUIRED');
      case 'min':
        return this.translateService.get('ERRORS.MIN', { min: data.min });
      case 'max':
        return this.translateService.get('ERRORS.MAX', { max: data.max });
      case 'number':
        return this.translateService.get('ERRORS.NUMBER');
      case 'maxFractionalDigits':
        return this.translateService.get('ERRORS.MAX_FRACTIONAL_DIGITS', { max: data });
      case 'integer':
        return this.translateService.get('ERRORS.INTEGER');
      case 'minlength':
        return this.translateService.get('ERRORS.MIN_LENGTH', { min: data.requiredLength });
      case 'maxlength':
        return this.translateService.get('ERRORS.MAX_LENGTH', { max: data.requiredLength });
      case 'email':
        return this.translateService.get('ERRORS.EMAIL');
      case 'phoneFormat':
      case 'phoneNumber':
        return this.translateService.get('ERRORS.PHONE');
      case 'phoneNumbersOnly':
        return this.translateService.get('ERRORS.INVALID_PHONE');
      case 'greaterThan':
        return this.translateService.get('ERRORS.GREATER_THAN', { min: data.min, actual: data.actual });
      case 'destinationLimit':
        return this.translateService.get('ERRORS.UNVETTED_DESTINATION_LIMIT');
      case 'vatRegistrationExists':
        return this.translateService.get('ERRORS.VAT_REGISTRATION_ALREADY_EXISTS');
      case 'invalidName':
        return this.translateService.get('ERRORS.INVALID_NAME');
      default:
        // tslint:disable-next-line:no-null-keyword
        return of(null);
    }
  }
}
