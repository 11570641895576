import { ServiceType as ServiceTypePayload, ServiceTypeWithNull } from '@tecex-api/data';
import { ServiceType } from '../enums/service-type.enum';

export const mapServiceType = (serviceType: ServiceTypeWithNull | ServiceTypePayload): ServiceType => {
  switch (serviceType) {
    case ServiceTypeWithNull.IOR:
    case ServiceTypePayload.IOR:
      return ServiceType.IOR;
    case ServiceTypeWithNull.EOR:
    case ServiceTypePayload.EOR:
      return ServiceType.EOR;
    default:
      return ServiceType.IOR;
  }
};
