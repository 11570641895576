import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { SvgIconsModule } from '../svg-icons/svg-icons.module';
import { TooltipComponent } from './tooltip.component';
import { TooltipDirective } from './tooltip.directive';

@NgModule({
  imports: [CommonModule, OverlayModule, SvgIconsModule, MatButtonModule],
  declarations: [TooltipComponent, TooltipDirective],
  exports: [TooltipDirective],
})
export class TooltipModule {}
