import { RouteSegment } from '@global/enums/route-segment.enum';

export const profilePictureFallback = 'assets/images/profile-picture-fallback.png';
export const demoVideoLinks = {
  [RouteSegment.NewQuote]: 'https://intercom.help/tecex-app/en/articles/5355423-quote-creation-tutorial',
  [RouteSegment.ShipmentsList]: 'https://intercom.help/tecex-app/en/articles/5291008-tasks-compliance-pending-tutorial',
};
export const withCCD = ['0011v00002WukpoAAB', '0011v00002hPlNkAAK', '0010Y00000Vl2nCQAR', '0011v00002D6mv3AAB'];
export const navMenuItems = [
  {
    link: [RouteSegment.Dashboard],
    exact: true,
    icon: 'dashboard',
    title: 'NAVIGATION.DASHBOARD',
  },
  {
    link: [RouteSegment.QuoteList],
    exact: false,
    icon: 'quote-new',
    title: 'NAVIGATION.QUOTES',
  },
  {
    link: [RouteSegment.ShipmentsList],
    exact: false,
    icon: 'list',
    title: 'NAVIGATION.SHIPMENTS',
  },
  {
    link: [RouteSegment.Tracking],
    exact: true,
    icon: 'plane-take-off',
    title: 'NAVIGATION.TRACKING',
  },
  {
    link: [RouteSegment.Invoices],
    exact: true,
    icon: 'quotes',
    title: 'NAVIGATION.INVOICES',
  },
  {
    link: [RouteSegment.Insights],
    exact: true,
    icon: 'insights',
    title: 'NAVIGATION.INSIGHTS',
  },
  {
    link: [RouteSegment.Messages],
    exact: true,
    icon: 'messages',
    title: 'NAVIGATION.MESSAGES',
  },
];
