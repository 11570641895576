import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GlobalModule } from '../../global.module';
import { SvgIconsModule } from '../svg-icons/svg-icons.module';
import { SearchFieldComponent } from './search-field.component';

@NgModule({
  declarations: [SearchFieldComponent],
  imports: [GlobalModule, SvgIconsModule, FormsModule],
  exports: [SearchFieldComponent],
})
export class SearchFieldModule {}
