import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@ngneat/reactive-forms';
import { SwitchBlockPayload } from '../../types/switch-block-payload.interface';
import { BlockComponent } from '../block/block.component';

@Component({
  selector: 'app-switch-block',
  templateUrl: './switch-block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchBlockComponent extends BlockComponent<SwitchBlockPayload, undefined> implements OnInit {
  public readonly formControl = new FormControl();

  public ngOnInit(): void {
    this.register.next(this.formControl);
  }
}
