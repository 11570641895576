import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { CancelQuoteReasons } from '@tecex-api/data';
import { InputDataVM } from '../../interfaces/input-data.vm';
import { DIALOG_DATA } from '../dialog/dialog.tokens';
import { DialogData } from '../dialog/interfaces/dialog-data.interface';
import { CancelQuoteDialogConfig } from './interfaces/cancel-quote-dialog-config.interface';
import { CancelQuoteDialogResult } from './interfaces/cancel-quote-dialog-result.interface';
import { CANCEL_QUOTE_DIALOG_CONFIG } from './tokens/cancel-quote-dialog-config.token';

@Component({
  selector: 'app-cancel-quote-dialog',
  templateUrl: './cancel-quote-dialog.component.html',
  styleUrls: ['./cancel-quote-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancelQuoteDialogComponent {
  private readonly cancelReasonTranslationKeyMap: ReadonlyMap<CancelQuoteReasons, string> = new Map<CancelQuoteReasons, string>([
    [CancelQuoteReasons.OUR_CLIENT_HAS_OPTED_OUT_FROM_THE_TRANSACTION, 'QUOTE_DETAILS.CANCEL_QUOTE.OPT_1'],
    [CancelQuoteReasons.FOUND_A_BETTER_ALTERNATIVE_OPTION, 'QUOTE_DETAILS.CANCEL_QUOTE.OPT_2'],
    [CancelQuoteReasons.THE_COSTS_WERE_TOO_HIGH, 'QUOTE_DETAILS.CANCEL_QUOTE.OPT_3'],
  ]);
  public closingReason: CancelQuoteReasons;
  public otherReason = '';
  public otherReasonTypeValue = CancelQuoteReasons.OTHER;
  public radioOptions: InputDataVM<string, string>[] = this.cancelQuoteDialogConfig.cancelReasons
    .filter((reason) => reason !== CancelQuoteReasons.OTHER)
    .map((reason) => ({
      value: reason,
      viewValue: this.cancelReasonTranslationKeyMap.get(reason),
    }));

  constructor(
    @Inject(CANCEL_QUOTE_DIALOG_CONFIG) private readonly cancelQuoteDialogConfig: CancelQuoteDialogConfig,
    @Inject(DIALOG_DATA)
    private readonly data: DialogData<undefined, CancelQuoteDialogResult>
  ) {}

  public get shouldShowOtherReasonOption(): boolean {
    return this.cancelQuoteDialogConfig.cancelReasons.includes(CancelQuoteReasons.OTHER);
  }

  public onRadioChange(event: MatRadioChange): void {
    if (event.value) {
      this.closingReason = event.value;
    }
  }

  public onCancelQuoteClick(): void {
    if (this.closingReason === this.otherReasonTypeValue && this.otherReason) {
      this.data.dialogRef.close({ reasonType: this.closingReason, reason: this.otherReason });
    }
    this.data.dialogRef.close({ reasonType: this.closingReason });
  }

  public onOtherFocus(): void {
    this.closingReason = this.otherReasonTypeValue;
  }
}
