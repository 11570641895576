import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalModule } from '../../global.module';
import { AnnouncmentModule } from '../announcment/announcment.module';
import { BreakpointModule } from '../breakpoint/breakpoint.module';
import { SvgIconsModule } from '../svg-icons/svg-icons.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { AppBarComponent } from './components/app-bar/app-bar.component';
import { AppNavDrawerComponent } from './components/app-nav-drawer/app-nav-drawer.component';
import { AppNavDrawerService } from './components/app-nav-drawer/app-nav-drawer.service';
import { HeaderComponent } from './components/header/header.component';
import { NavigationItemComponent } from './components/navigation-item/navigation-item.component';

@NgModule({
  imports: [
    AnnouncmentModule,
    GlobalModule,
    MatButtonModule,
    MatListModule,
    MatMenuModule,
    RouterModule,
    SvgIconsModule,
    TranslateModule,
    BreakpointModule,
    TooltipModule,
  ],
  declarations: [AppNavDrawerComponent, NavigationItemComponent, AppBarComponent, HeaderComponent],
  exports: [AppNavDrawerComponent, NavigationItemComponent, AppBarComponent, HeaderComponent],
})
export class AppNavModule {
  public static forRoot(): ModuleWithProviders<AppNavModule> {
    return {
      ngModule: AppNavModule,
      providers: [AppNavDrawerService],
    };
  }
}
