import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalModule } from '../../global.module';
import { LoadingIndicatorModule } from '../loading-indicator/loading-indicator.module';
import { StateIndicatorCircleModule } from '../state-indicator-circle/state-indicator-circle.module';
import { MessageButtonComponent } from './message-button.component';

@NgModule({
  imports: [GlobalModule, TranslateModule, StateIndicatorCircleModule, LoadingIndicatorModule],
  declarations: [MessageButtonComponent],
  exports: [MessageButtonComponent],
})
export class MessageButtonModule {}
