import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { DialogService } from '@global/modules/dialog/dialog.service';
import { SendbirdService } from '@global/modules/message-thread/services/sendbird-message.service';
import { RouteData } from '@shared/enums/route-data.enum';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  // Add the host listener for send-bird connection on tab change : Start
  @HostListener('document:visibilitychange', ['$event'])
  public onVisibilityChange(): void {
    if (document.hidden) {
      this.sendBirdServices.disconnect();
    } else {
      this.sendBirdServices.connect();
    }
  }
  // Add the host listener for send-bird connection on tab change : End

  public readonly hasOpenDialog$: Observable<boolean> = this.dialogService.hasOpenDialog$();
  public hasBackgroundPattern$: Observable<boolean>;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly dialogService: DialogService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly renderer: Renderer2,
    private readonly sendBirdServices: SendbirdService
  ) {}

  public ngOnInit(): void {
    this.hasBackgroundPattern$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.hasData(RouteData.HasBackgroundPattern, this.activatedRoute.snapshot))
    );

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.hasData(RouteData.HasWhiteBackground, this.activatedRoute.snapshot)),
        takeUntil(this.destroy$)
      )
      .subscribe((hasWhiteBackground) => {
        if (hasWhiteBackground) {
          this.renderer.addClass(this.document.documentElement, 'bg-white');
        } else {
          this.renderer.removeClass(this.document.documentElement, 'bg-white');
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private hasData(key: RouteData, activatedRouteSnapshot: ActivatedRouteSnapshot): boolean {
    if (activatedRouteSnapshot.data[key]) {
      return true;
    }

    return activatedRouteSnapshot.children.some((item) => this.hasData(key, item));
  }
}
