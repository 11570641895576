import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { PackageGroupPackageContentType } from '../../enums/package-group-package-content-type.enum';
import { GlobalModule } from '../../global.module';
import { FormControlModule } from '../form-control/form-control.module';
import { PackageClosedContentCardModule } from '../package-closed-content-card/package-closed-content-card.module';
import { SelectionModule } from '../selection/selection.module';
import { SvgIconsModule } from '../svg-icons/svg-icons.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { PackageGroupsComponent } from './components/package-groups/package-groups.component';
import { PackageService } from './package.service';
import { PACKAGE_GROUP_PACKAGE_CONTENT_TYPE } from './tokens/package-group-package-content-type.token';

@NgModule({
  declarations: [PackageGroupsComponent],
  imports: [
    GlobalModule,
    SvgIconsModule,
    PackageClosedContentCardModule,
    FormControlModule,
    SelectionModule,
    MatCheckboxModule,
    TooltipModule,
    TranslateModule,
    ReactiveFormsModule,
    MatButtonModule,
  ],
  exports: [PackageGroupsComponent],
  providers: [PackageService],
})
export class PackagesModule {
  public static forRoot(config: { packageContentType: PackageGroupPackageContentType }): ModuleWithProviders<PackagesModule> {
    return {
      ngModule: PackagesModule,
      providers: [PackageService, { provide: PACKAGE_GROUP_PACKAGE_CONTENT_TYPE, useValue: config.packageContentType }],
    };
  }
}
