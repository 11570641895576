import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { GlobalModule } from '@global/global.module';
import { DialogModule } from '@global/modules/dialog/dialog.module';
import { PageContainerModule } from '@global/modules/page-container/page-container.module';
import { SvgIconsModule } from '@global/modules/svg-icons/svg-icons.module';
import { ToastMessageModule } from '@global/modules/toast-message/toast-message.module';
import { ReactiveComponentModule } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [GlobalModule],
  exports: [
    GlobalModule,
    TranslateModule,
    SvgIconsModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    ReactiveComponentModule,
    PageContainerModule,
    MatRippleModule,
    MatButtonModule,
    ToastMessageModule,
    RouterModule,
  ],
})
export class SharedModule {}
