import { CurrencyCode } from '@global/enums/currency-code.enum';
import { LengthUnit } from '@global/enums/length-unit.enum';
import { QuoteTypeFromNewQuote } from '@global/enums/quote-type.enum';
import { ServiceType } from '@global/enums/service-type.enum';
import { WeightUnit } from '@global/enums/weight-unit.enum';
import { LoadableStateReducerHelper } from '@global/helpers/loadable-state-reducer.helper';
import { Loadable } from '@global/interfaces/loadable.interface';
import { NewQuoteDefaults } from '@global/interfaces/new-quote-defaults.interface';
import { createReducer, on } from '@ngrx/store';
import { QuoteBasicsForm } from '../../quote/interfaces/quote-basics-form.interface';
import * as NewQuoteBasicsActions from '../actions/new-quote-basics.actions';
import * as NewQuoteFinalCostsActions from '../actions/new-quote-final-costs.actions';
import * as NewQuoteActions from '../actions/new-quote.actions';
import * as ProfileApiActions from '../actions/profile-api.actions';

export const newQuoteBasicsFeatureKey = 'newQuoteBasics';

export interface State {
  state: 'in-progress' | 'completed';
  id?: string;
  /**
   * Id of freight request related to quote
   * It is only relevant for single quotes
   */
  freightId?: string;
  /**
   * This is list of all shipment order ids related to this flow.
   * In case of rollout this contains at least 2 elements, otherwise only 1.
   */
  shipmentOrderIds?: string[];
  quoteReference?: string;
  rolloutId: any;
  defaults: Loadable<NewQuoteDefaults>;
  values: Loadable<QuoteBasicsForm>;
  clientContact?: string;
  userWithRODetail: any;
  rollOutDetailForNewQuote: any;
  loadExistingRoDetailForSummary: any;
}

export const initialState: State = {
  state: 'in-progress',
  defaults: {
    isLoading: false,
    data: undefined,
  },
  rolloutId: undefined,
  values: {
    data: {
      quoteType: QuoteTypeFromNewQuote.ShipmentOrder,
      serviceType: ServiceType.IOR,
      from: undefined,
      pickUpAddress: [],
      to: [],
      typeOfGoods: undefined,
      shipmentValue: undefined,
      shipmentValueCurrency: CurrencyCode.USD,
      estimatedWeight: undefined,
      estimatedWeightUnit: WeightUnit.Kg,
      lengthUnit: LengthUnit.Cm,
      packages: [],
      numberOfLocations: undefined,
      locationAddresses: [],
      nameOfAddressedEntity: undefined,
      projectReferences: [''],
      newQuote: true,
      clientReferenceValues: undefined,
      shipmentProjectReference: '',
    },
    isLoading: false,
  },
  userWithRODetail: undefined,
  rollOutDetailForNewQuote: undefined,
  loadExistingRoDetailForSummary: {
    isLoading: false,
    data: undefined,
  },
};

export const reducer = createReducer<State>(
  initialState,
  on(
    ProfileApiActions.loadNewQuoteDefaults,
    NewQuoteActions.loadExistingQuote,
    NewQuoteActions.loadExistingRollout,
    NewQuoteActions.loadExistingRolloutToAddNewShipments,
    (state) => ({
      ...state,
      defaults: LoadableStateReducerHelper.startLoad(state.defaults),
    })
  ),
  on(
    NewQuoteActions.loadExistingRolloutToAddNewShipments,
    (state): State => ({
      ...state,
      values: {
        ...state.values,
        data: {
          ...state.values.data,
          newQuote: false,
        },
      },
    })
  ),
  on(ProfileApiActions.loadNewQuoteDefaultsSuccess, (state, { defaults }) => {
    const { tecExToHandleFreight, ...values } = defaults;

    return {
      ...state,
      defaults: LoadableStateReducerHelper.loadSuccess({ ...state.defaults.data, ...defaults }),
      values: LoadableStateReducerHelper.loadSuccess({ ...state.values.data, ...values }),
    };
  }),
  on(NewQuoteActions.loadExistingQuoteSuccess, (state, { basics, defaults }) => ({
    ...state,
    values: LoadableStateReducerHelper.loadSuccess({ ...state.values.data, ...basics }),
    defaults: LoadableStateReducerHelper.loadSuccess({ ...state.defaults.data, ...defaults }),
  })),
  // eslint-disable-next-line sonarjs/no-identical-functions
  on(NewQuoteActions.loadExistingRolloutToAddNewShipmentsSuccess, (state, { basics, defaults }) => ({
    ...state,
    values: LoadableStateReducerHelper.loadSuccess({ ...state.values.data, ...basics }),
    defaults: LoadableStateReducerHelper.loadSuccess({ ...state.defaults.data, ...defaults }),
  })),
  on(ProfileApiActions.loadNewQuoteDefaultsError, (state, { error }) => ({
    ...state,
    defaults: LoadableStateReducerHelper.loadError(state.defaults, error),
  })),
  on(NewQuoteActions.loadExistingQuoteError, (state, { error }) => ({
    ...state,
    values: LoadableStateReducerHelper.loadError(state.values, error),
  })),

  on(NewQuoteBasicsActions.submit, (state, { values }) => ({
    ...state,
    values: LoadableStateReducerHelper.startLoad({ data: values, isLoading: state.values.isLoading }),
  })),
  on(
    NewQuoteBasicsActions.submitSuccess,
    (state, { id, freightId, shipmentOrderIds, quoteReference, rolloutId, values, clientContact }) => ({
      ...state,
      state: 'completed',
      id,
      freightId,
      shipmentOrderIds,
      quoteReference,
      rolloutId,
      clientContact,
      values: LoadableStateReducerHelper.loadSuccess(values),
    })
  ),
  on(NewQuoteBasicsActions.submitError, (state, { error }) => ({
    ...state,
    values: LoadableStateReducerHelper.loadError(state.values, error),
  })),

  on(NewQuoteBasicsActions.edit, (state) => ({
    ...state,
    values: LoadableStateReducerHelper.startLoad(state.values),
  })),
  on(NewQuoteBasicsActions.editSuccess, NewQuoteBasicsActions.update, (state, { values }) => ({
    ...state,
    values: LoadableStateReducerHelper.loadSuccess({ ...state.values.data, ...values }),
  })),
  on(NewQuoteBasicsActions.editError, (state, { error }) => ({
    ...state,
    values: LoadableStateReducerHelper.loadError(state.values, error),
  })),

  on(NewQuoteBasicsActions.rollOutDetailIdSuccess, (state, { detailsOfUserWithRollout }) => ({
    ...state,
    userWithRODetail: LoadableStateReducerHelper.loadSuccess(detailsOfUserWithRollout),
  })),

  on(NewQuoteActions.loadExistingRollOutDetailSuccess, (state, { existingRollOutDetail }) => ({
    ...state,
    rollOutDetailForNewQuote: LoadableStateReducerHelper.loadSuccess(existingRollOutDetail),
  })),

  on(NewQuoteActions.loadExistingRODetailFullSuccess, (state, { loadExistingRoDetail }) => ({
    ...state,
    loadExistingRoDetailForSummary: LoadableStateReducerHelper.loadSuccess(loadExistingRoDetail),
  })),

  on(NewQuoteFinalCostsActions.addPackagesSuccess, (state, { packages }) => ({
    ...state,
    values: {
      ...state.values,
      data: {
        ...state.values.data,
        packages,
        estimatedWeight: packages.reduce((totalWeight, packageValue) => (totalWeight += packageValue.totalWeight), 0),
      },
    },
  })),

  on(NewQuoteActions.leave, NewQuoteActions.startNew, NewQuoteActions.reuseQuote, (): State => initialState)
);
